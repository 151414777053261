/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum, MeanLevelRow } from '../components';

export function Grade2SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [readingComprehension, setReadingComprehension] = useState(null);
   const [oralLanguage, setOralLanguage] = useState(null);
   const [bookAndPrintKnowledge, setBookAndPrintKnowledge] = useState(null);
   const [alphabetKnowledge, setAlphabetKnowledge] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [numberSense, setNumberSense] = useState(null);
   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _oralLanguage = {};
      var _bookAndPrintKnowledge = {};
      var _alphabetKnowledge = {};
      var _grammar = {};
      var _vocabulary = {};
      var _total = {};
      var _proficiency = '';

      //-- Reading Comprehension (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 +
            pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8.32) / 2.48) > 1)
            _competent++;
         else if (((_sumVal - 8.32) / 2.48) > -0.51)
            _basic++;
         else if (((_sumVal - 8.32) / 2.48) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 +
               post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8.32) / 2.48) > 1)
                  _competent++;
               else if (((_sumVal - 8.32) / 2.48) > -0.51)
                  _basic++;
               else if (((_sumVal - 8.32) / 2.48) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.07 ? 'Beginner' : (_postAve >= 7.08 && _postAve <= 10.8 ? 'Basic' : (_postAve >= 10.81 && _postAve <= 13 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.07 ? 'Beginner' : (_preAve >= 7.08 && _preAve <= 10.8 ? 'Basic' : (_preAve >= 10.81 && _preAve <= 13 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Oral Language (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s14 + pre_data[i].s15;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.64) / 0.73) > 1)
            _competent++;
         else if (((_sumVal - 1.64) / 0.73) > -0.51)
            _basic++;
         else if (((_sumVal - 1.64) / 0.73) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s14 + post_data[i].s15;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.64) / 0.73) > 1)
                  _competent++;
               else if (((_sumVal - 1.64) / 0.73) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.64) / 0.73) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.27 ? 'Beginner' : (_postAve >= 1.28 && _postAve <= 1.80 ? 'Basic' : (_postAve >= 1.81 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.27 ? 'Beginner' : (_preAve >= 1.28 && _preAve <= 1.80 ? 'Basic' : (_preAve >= 1.81 && _preAve <= 2 ? 'Competent' : ''));
      }

      _oralLanguage = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Oral Language (end)

      //-- Book and Print Knowledge (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s16 + pre_data[i].s17;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.39) / 0.79) > 1)
            _competent++;
         else if (((_sumVal - 1.39) / 0.79) > -0.51)
            _basic++;
         else if (((_sumVal - 1.39) / 0.79) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s16 + post_data[i].s17;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.39) / 0.79) > 1)
                  _competent++;
               else if (((_sumVal - 1.39) / 0.79) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.39) / 0.79) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.99 ? 'Beginner' : (_postAve >= 1 && _postAve <= 1.50 ? 'Basic' : (_postAve >= 1.51 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.99 ? 'Beginner' : (_preAve >= 1 && _preAve <= 1.50 ? 'Basic' : (_preAve >= 1.51 && _preAve <= 2 ? 'Competent' : ''));
      }

      _bookAndPrintKnowledge = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Book and Print Knowledge (end)

      //-- Alphabet Knowledge (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s18;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 0.89) / 0.1) > 1)
            _competent++;
         else if (((_sumVal - 0.89) / 0.1) > -0.51)
            _basic++;
         else if (((_sumVal - 0.89) / 0.1) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s18;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 0.89) / 0.1) > 1)
                  _competent++;
               else if (((_sumVal - 0.89) / 0.1) > -0.51)
                  _basic++;
               else if (((_sumVal - 0.89) / 0.1 > -10))
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.5 ? 'Beginner' : (_postAve >= 0.51 && _postAve <= 0.70 ? 'Basic' : (_postAve >= 0.71 && _postAve <= 1 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.5 ? 'Beginner' : (_preAve >= 0.51 && _preAve <= 0.70 ? 'Basic' : (_preAve >= 0.71 && _preAve <= 1 ? 'Competent' : ''));
      }

      _alphabetKnowledge = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Alphabet Knowledge (end)

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10.75) / 0.97) > 1)
            _competent++;
         else if (((_sumVal - 10.75) / 0.97) > -0.51)
            _basic++;
         else if (((_sumVal - 10.75) / 0.97) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10.75) / 0.97) > 1)
                  _competent++;
               else if (((_sumVal - 10.75) / 0.97) > -0.51)
                  _basic++;
               else if (((_sumVal - 10.75) / 0.97) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 10.3 ? 'Beginner' : (_postAve >= 10.31 && _postAve <= 11.72 ? 'Basic' : (_postAve >= 11.73 && _postAve <= 16 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 10.3 ? 'Beginner' : (_preAve >= 10.31 && _preAve <= 11.72 ? 'Basic' : (_preAve >= 11.73 && _preAve <= 16 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 +
            pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.82) / 1.96) > 1)
            _competent++;
         else if (((_sumVal - 7.82) / 1.96) > -0.51)
            _basic++;
         else if (((_sumVal - 7.82) / 1.96) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 +
               post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.82) / 1.96) > 1)
                  _competent++;
               else if (((_sumVal - 7.82) / 1.96) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.82) / 1.96) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.83 ? 'Beginner' : (_postAve >= 6.84 && _postAve <= 9.78 ? 'Basic' : (_postAve >= 9.79 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.83 ? 'Beginner' : (_preAve >= 6.84 && _preAve <= 9.78 ? 'Basic' : (_preAve >= 9.79 && _preAve <= 11 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      _total = {
         vn: pre_data.length,
         prem: _alphabetKnowledge.prem + _bookAndPrintKnowledge.prem + _oralLanguage.prem + _readingComprehension.prem + _grammar.prem + _vocabulary.prem,
         postm: _alphabetKnowledge.postm + _bookAndPrintKnowledge.postm + _oralLanguage.postm + _readingComprehension.postm + _grammar.postm + _vocabulary.postm,
         min: _alphabetKnowledge.min + _bookAndPrintKnowledge.min + _oralLanguage.min + _readingComprehension.min + _grammar.min + _vocabulary.min,
         max: _alphabetKnowledge.max + _bookAndPrintKnowledge.max + _oralLanguage.max + _readingComprehension.max + _grammar.max + _vocabulary.max,
         sd: _alphabetKnowledge.sd + _bookAndPrintKnowledge.sd + _oralLanguage.sd + _readingComprehension.sd + _grammar.sd + _vocabulary.sd,
         proficiency: _proficiency,
         beginner: _alphabetKnowledge.beginner + _bookAndPrintKnowledge.beginner + _oralLanguage.beginner + _readingComprehension.beginner + _grammar.beginner + _vocabulary.beginner,
         basic: _alphabetKnowledge.basic + _bookAndPrintKnowledge.basic + _oralLanguage.basic + _readingComprehension.basic + _grammar.basic + _vocabulary.basic,
         competent: _alphabetKnowledge.competent + _bookAndPrintKnowledge.competent + _oralLanguage.competent + _readingComprehension.competent + _grammar.competent + _vocabulary.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 16.91 ? 'Beginner' : (_total.postm >= 16.92 && _total.postm <= 25.7 ? 'Basic' : (_total.postm >= 25.71 && _total.postm <= 45 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 16.91 ? 'Beginner' : (_total.prem >= 16.92 && _total.prem <= 25.7 ? 'Basic' : (_total.prem >= 25.71 && _total.prem <= 45 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setOralLanguage(_oralLanguage);
      setReadingComprehension(_readingComprehension);
      setBookAndPrintKnowledge(_bookAndPrintKnowledge);
      setAlphabetKnowledge(_alphabetKnowledge);
      setGrammar(_grammar);
      setVocabulary(_vocabulary);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _numbersense = {};
      var _geometry = {};
      var _algebra = {};
      var _measurement = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Number Sense (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 +
            pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 13.61) / 4.9) > 1)
            _competent++;
         else if (((_sumVal - 13.61) / 4.9) > -0.51)
            _basic++;
         else if (((_sumVal - 13.61) / 4.9) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 +
               post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 13.61) / 4.9) > 1)
                  _competent++;
               else if (((_sumVal - 13.61) / 4.9) > -0.51)
                  _basic++;
               else if (((_sumVal - 13.61) / 4.9) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 11.20 ? 'Beginner' : (_postAve >= 11.21 && _postAve <= 18.51 ? 'Basic' : (_postAve >= 18.52 && _postAve <= 29 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 11.20 ? 'Beginner' : (_preAve >= 11.21 && _preAve <= 18.51 ? 'Basic' : (_preAve >= 18.52 && _preAve <= 29 ? 'Competent' : ''));
      }

      _numbersense = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Number Sense (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.18) / 0.98) > 1)
            _competent++;
         else if (((_sumVal - 2.18) / 0.98) > -0.51)
            _basic++;
         else if (((_sumVal - 2.18) / 0.98) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.18) / 0.98) > 1)
                  _competent++;
               else if (((_sumVal - 2.18) / 0.98) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.18) / 0.98) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.68 ? 'Beginner' : (_postAve >= 1.69 && _postAve <= 3.16 ? 'Basic' : (_postAve >= 3.17 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.68 ? 'Beginner' : (_preAve >= 1.69 && _preAve <= 3.16 ? 'Basic' : (_preAve >= 3.17 && _preAve <= 4 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Patterns and Algebra (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s34 + pre_data[i].s35;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.25) / 0.59) > 1)
            _competent++;
         else if (((_sumVal - 1.25) / 0.59) > -0.51)
            _basic++;
         else if (((_sumVal - 1.25) / 0.59) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s34 + post_data[i].s35;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.25) / 0.59) > 1)
                  _competent++;
               else if (((_sumVal - 1.25) / 0.59) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.25) / 0.59) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.95 ? 'Beginner' : (_postAve >= 0.96 && _postAve <= 1.84 ? 'Basic' : (_postAve >= 1.85 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.95 ? 'Beginner' : (_preAve >= 0.96 && _preAve <= 1.84 ? 'Basic' : (_preAve >= 1.85 && _preAve <= 2 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Patterns and Algebra (end)

      //-- Measurement (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.82) / 1.39) > 1)
            _competent++;
         else if (((_sumVal - 4.82) / 1.39) > -0.51)
            _basic++;
         else if (((_sumVal - 4.82) / 1.39) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.82) / 1.39) > 1)
                  _competent++;
               else if (((_sumVal - 4.82) / 1.39) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.82) / 1.39) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.12 ? 'Beginner' : (_postAve >= 4.13 && _postAve <= 6.21 ? 'Basic' : (_postAve >= 6.22 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.12 ? 'Beginner' : (_preAve >= 4.13 && _preAve <= 6.21 ? 'Basic' : (_preAve >= 6.22 && _preAve <= 10 ? 'Competent' : ''));
      }

      _measurement = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Statistics and Probability (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.79) / 1.37) > 1)
            _competent++;
         else if (((_sumVal - 2.79) / 1.37) > -0.51)
            _basic++;
         else if (((_sumVal - 2.79) / 1.37) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.79) / 1.37) > 1)
                  _competent++;
               else if (((_sumVal - 2.79) / 1.37) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.79) / 1.37) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.11 ? 'Beginner' : (_postAve >= 2.12 && _postAve <= 4.16 ? 'Basic' : (_postAve >= 4.17 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.11 ? 'Beginner' : (_preAve >= 2.12 && _preAve <= 4.16 ? 'Basic' : (_preAve >= 4.17 && _preAve <= 5 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics and Probability (end)

      _total = {
         vn: pre_data.length,
         prem: _numbersense.prem + _geometry.prem + _algebra.prem + _measurement.prem + _statistics.prem,
         postm: _numbersense.postm + _geometry.postm + _algebra.postm + _measurement.postm + _statistics.postm,
         min: _numbersense.min + _geometry.min + _algebra.min + _measurement.min + _statistics.min,
         max: _numbersense.max + _geometry.max + _algebra.max + _measurement.max + _statistics.max,
         sd: _numbersense.sd + _geometry.sd + _algebra.sd + _measurement.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _numbersense.beginner + _geometry.beginner + _algebra.beginner + _measurement.beginner + _statistics.beginner,
         basic: _numbersense.basic + _geometry.basic + _algebra.basic + _measurement.basic + _statistics.basic,
         competent: _numbersense.competent + _geometry.competent + _algebra.competent + _measurement.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 18.6 ? 'Beginner' : (_total.postm >= 18.61 && _total.postm <= 27.75 ? 'Basic' : (_total.postm >= 27.76 && _total.postm <= 50 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 18.6 ? 'Beginner' : (_total.prem >= 18.61 && _total.prem <= 27.75 ? 'Basic' : (_total.prem >= 27.76 && _total.prem <= 50 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setNumberSense(_numbersense);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setMeasurement(_measurement);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _matter = {};
      var _livingthings = {};
      var _forceandmotion = {};
      var _earthandspace = {};
      var _proficiency = '';
      var _total = 0;

      //-- Matter (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.4) / 0.6) > 1)
            _competent++;
         else if (((_sumVal - 3.4) / 0.6) > -0.51)
            _basic++;
         else if (((_sumVal - 3.4) / 0.6) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.4) / 0.6) > 1)
                  _competent++;
               else if (((_sumVal - 3.4) / 0.6) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.4) / 0.6) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.09 ? 'Beginner' : (_postAve >= 3.10 && _postAve <= 4 ? 'Basic' : (_postAve >= 4.01 && _postAve <= 7 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.09 ? 'Beginner' : (_preAve >= 3.10 && _preAve <= 4 ? 'Basic' : (_preAve >= 4.01 && _preAve <= 7 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living Things and their Environment (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.3) / 2.58) > 1)
            _competent++;
         else if (((_sumVal - 9.3) / 2.58) > -0.51)
            _basic++;
         else if (((_sumVal - 9.3) / 2.58) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.3) / 2.58) > 1)
                  _competent++;
               else if (((_sumVal - 9.3) / 2.58) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.3) / 2.58) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8 ? 'Beginner' : (_postAve >= 8.01 && _postAve <= 11.88 ? 'Basic' : (_postAve >= 11.89 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8 ? 'Beginner' : (_preAve >= 8.01 && _preAve <= 11.88 ? 'Basic' : (_preAve >= 11.89 && _preAve <= 19 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.75) / 1.21) > 1)
            _competent++;
         else if (((_sumVal - 4.75) / 1.21) > -0.51)
            _basic++;
         else if (((_sumVal - 4.75) / 1.21) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.75) / 1.21) > 1)
                  _competent++;
               else if (((_sumVal - 4.75) / 1.21) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.75) / 1.21) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.14 ? 'Beginner' : (_postAve >= 4.15 && _postAve <= 5.96 ? 'Basic' : (_postAve >= 5.97 && _postAve <= 7 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.14 ? 'Beginner' : (_preAve >= 4.15 && _preAve <= 5.96 ? 'Basic' : (_preAve >= 5.97 && _preAve <= 7 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.1) / 1.07) > 1)
            _competent++;
         else if (((_sumVal - 4.1) / 1.07) > -0.51)
            _basic++;
         else if (((_sumVal - 4.1) / 1.07) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.1) / 1.07) > 1)
                  _competent++;
               else if (((_sumVal - 4.1) / 1.07) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.1) / 1.07) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.56 ? 'Beginner' : (_postAve >= 3.57 && _postAve <= 5.17 ? 'Basic' : (_postAve >= 5.18 && _postAve <= 7 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.56 ? 'Beginner' : (_preAve >= 3.57 && _preAve <= 5.17 ? 'Basic' : (_preAve >= 5.18 && _preAve <= 7 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 20.11 ? 'Beginner' : (_total.postm >= 20.12 && _total.postm <= 24.5 ? 'Basic' : (_total.postm >= 24.51 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 20.11 ? 'Beginner' : (_total.prem >= 20.12 && _total.prem <= 24.5 ? 'Basic' : (_total.prem >= 24.51 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   // const generateMeanLevelRow = (label, data, istotal) => {
   //    return (
   //       <tr>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
   //             {
   //                istotal
   //                   ?
   //                   <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
   //                   :
   //                   <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
   //             }

   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
   //          </td>
   //          <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
   //             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
   //          </td>
   //       </tr>
   //    );
   // }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '31.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '4.8%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>N</span></strong></p>
                                    </td>
                                    <td style={{ width: '12.52%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRE TEST MEAN</span></strong></p>
                                    </td>
                                    <td style={{ width: '13.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST MEAN</span></strong></p>
                                    </td>
                                    <td style={{ width: '7.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>MIN</span></strong></p>
                                    </td>
                                    <td style={{ width: '8.92%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>MAX</span></strong></p>
                                    </td>
                                    <td style={{ width: '6.52%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>SD</span></strong></p>
                                    </td>
                                    <td style={{ width: '14.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PROFICIENCY LEVEL</span></strong></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <MeanLevelRow label="Reading Comprehension" data={readingComprehension} />
                                       <MeanLevelRow label="Oral Language" data={oralLanguage} />
                                       <MeanLevelRow label="Book and Print Knowledge" data={bookAndPrintKnowledge} />
                                       <MeanLevelRow label="Alphabet Knowledge" data={alphabetKnowledge} />
                                       <MeanLevelRow label="Grammar" data={grammar} />
                                       <MeanLevelRow label="Vocabulary" data={vocabulary} />
                                       <MeanLevelRow label="ENGLISH" data={englishTotal} />
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <MeanLevelRow label="Number Sense" data={numberSense} />
                                       <MeanLevelRow label="Geometry" data={geometry} />
                                       <MeanLevelRow label="Algebra" data={patternsAndAlgebra} />
                                       <MeanLevelRow label="Measurement" data={measurement} />
                                       <MeanLevelRow label="Statistics and Probability" data={statisticsAndProbability} />
                                       <MeanLevelRow label="MATH" data={mathTotal} />
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <MeanLevelRow label="Matter" data={matter} />
                                       <MeanLevelRow label="Living Things and their Environment" data={livingThings} />
                                       <MeanLevelRow label="Force and Motion" data={forceAndMotion} />
                                       <MeanLevelRow label="Earth and Space" data={earthAndSpace} />
                                       <MeanLevelRow label="SCIENCE" data={scienceTotal} />
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'justify' }}><strong>INTERPRETATION</strong></p>
                           <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>BEGINNER</span></strong></p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>BASIC</span></strong></p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>COMPETENT</span></strong></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Reading Comprehension</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7.07</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7.08</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.8</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.81</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>13</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Oral Language</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.27</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.28</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.80</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.81</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Book and Print Knowledge</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.99</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.50</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.51</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Alphabet Knowledge</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.50</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.51</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.70</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.71</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Grammar</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.3</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.31</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.72</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.73</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Vocabulary</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.83</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.84</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>9.78</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>9.79</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>ENGLISH</strong></p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16.91</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16.92</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>25.7</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>25.67</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>45</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td colSpan={12} style={{ borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Number Sense</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.20</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.21</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.51</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.52</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>29</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Geometry</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.68</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.69</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.16</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.17</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Algebra</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.95</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.96</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.84</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.85</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Measurement</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.12</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.13</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.21</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.22</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Statistics</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2.11</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2.12</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.16</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.17</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>MATH</strong></p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.6</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.60</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>27.75</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>27.76</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>50</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td colSpan={12} style={{ borderStyle: 'solid', borderWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Matter</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.09</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.1</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.00</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.01</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Living Things and their Environment</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>8</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>8.01</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.88</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.89</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>19</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Force and Motion</p>
                                          </td>
                                          <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.14</p>
                                          </td>
                                          <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.15</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.96</p>
                                          </td>
                                          <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.97</p>
                                          </td>
                                          <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Earth and Space</p>
                                          </td>
                                          <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.56</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.57</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.17</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.18</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>SCIENCE</strong></p>
                                          </td>
                                          <td style={{ width: '4.06%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                          </td>
                                          <td style={{ width: '3.46%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>20.11</p>
                                          </td>
                                          <td style={{ width: '2.72%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>20.12</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>24.5</p>
                                          </td>
                                          <td style={{ width: '2.74%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '8.84%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>24.54</p>
                                          </td>
                                          <td style={{ width: '3.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                          </td>
                                          <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>40</p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>
                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Oral Language', oralLanguage, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Print and Book Knowledge', bookAndPrintKnowledge, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Alphabet Knowledge', alphabetKnowledge, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 6)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 7)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Number Sense', numberSense, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 10)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Measurement', measurement, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 13)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 15)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 16)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 17)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 18)}
                              </>
                           }


                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for English</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Reading Comprehension</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Note important details pertaining to.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Note important details pertaining to.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Note important details pertaining to.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Give the correct sequence of three events.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Infer the character feelings and traits.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Predict possible ending of a story read.', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Identify the speaker in the story or poem.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Identify cause and/or effect of events.', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Identify the problem and solution.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Classify/Categorize sounds heard (animals, mechanical, objects, musical instruments, environment, speech).', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Distinguish rhyming words from non-rhyming words.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Distinguish rhyming words from non-rhyming words.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Supply words that rhyme with given words.', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Oral Language</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Use appropriate expressions in common situations (polite expressions, greetings, seeking directions, apologizing, asking help, query and clarification).', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Use appropriate expressions in common situations (polite expressions, greetings, seeking directions, apologizing, asking help, query and clarification).', englishPCData.pre_s15, englishPCData.post_s15, 15)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Book and Print Knowledge</strong></p>
                                          </td>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Recognize the common terms in English relating to part of book (e.g. cover, title page, etc.) book orientation.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Recognize the common terms in English relating to part of book (e.g. cover, title page, etc.) book orientation.', englishPCData.pre_s17, englishPCData.post_s17, 17)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Alphabet Knowledge</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify letters in English that are not present in Mother Tongue/Filipino and vice-versa.', englishPCData.pre_s18, englishPCData.post_s18, 18)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Grammar</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize sentences and non-sentences', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize simple sentences', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize different kinds of sentences (declarative, interrogative)', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize different kinds of sentences (declarative, interrogative)', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize nouns in simple sentences', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize nouns in simple sentences', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize nouns in simple sentences', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize the use of a/an + noun', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize common action words in retelling, conversation, etc.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Grammar Awareness: Recognize descriptions of people, objects, things and places (color, shape, size, height, weight, length, distance, etc.)', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Use common nouns in simple sentences.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Use common nouns in simple sentences.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Use personal pronouns (e.g. I, you, he, she, it, we, they) in dialogues.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Use demonstrative pronouns (this/that, these/ those).', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Use the most frequently occurring preposition (e.g. on, over, under, to, from, above, etc.).', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Describe people, objects, things and places using simple adjectives (color, shape, size, height, weight, length, distance, etc.).', englishPCData.pre_s34, englishPCData.post_s34, 34)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Vocabulary</strong></p>
                                          </td>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Vocabulary Development: Identify the English equivalent of words in the Mother Tongue or in Filipino', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Vocabulary Development: Identify the English equivalent of words in the Mother Tongue or in Filipino', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Vocabulary Development: Sort and classify familiar words into basic categories (colors, shapes, foods, etc.)', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Determine the meaning of words using clues (Total Physical Response through realia, pictures, body movements, context clues etc.).', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Recognize that some words mean the same (synonyms).', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Recognize that some words mean the same (synonyms).', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Recognize that some words mean the same (synonyms).', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Recognize that some words have opposite meaning (antonyms).', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Recognize that some words have opposite meaning (antonyms).', englishPCData.pre_s43, englishPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Recognize that some words have opposite meaning (antonyms).', englishPCData.pre_s44, englishPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Give the meaning of a two syllable word.', englishPCData.pre_s34, englishPCData.post_s45, 45)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for Mathematics</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '14.78%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '15.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '14.78%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Number Sense</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Gives the place value and finds the value of a digit in three-digit numbers.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Reads and writes numbers up to 1 000 in symbols and in words.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Writes three-digit numbers in expanded form.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Compares numbers up to 1000 using relation symbols.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Orders numbers up to 1000 in increasing or decreasing order.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Identifies the 1st through the 20th with the emphasis on 11th to 20th object in a given set from a given point of reference.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Reads and writes money in symbols and in words through PhP100.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Counts the value of a set of bills or a set of coins through PhP100 (peso-coins only; centavo-coins only; peso-bills only and combined peso-coins and peso-bills).', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Compares values of different denominations of coins and paper bills through PhP100 using relation symbols.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Illustrates the properties of addition (commutative, associative, identity) and applies each in appropriate and relevant situations.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Adds 2-digit by 3-digit numbers with sums up to 1000 without and with regrouping.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving addition of whole numbers including money with sums up to 1000 using appropriate problem solving strategies and tools.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Solves problems involving subtraction of whole numbers including money with minuends up to 1000 using appropriate problem solving strategies and tools.', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Performs orders of operations involving addition and subtractions of small numbers.', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Solves multi-step routine and non-routine problems involving addition and subtraction of 2- to 3-digit numbers including money using appropriate problem solving strategies and tools.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Illustrates multiplication as repeated addition.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Writes a related equation for each type of multiplication: repeated addition, array, counting by multiples, and equal jumps on the number line.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Illustrates the commutative property of multiplication.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving multiplication and addition or subtraction of whole numbers including money using appropriate problem solving strategies &tools.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving multiplication and addition or subtraction of whole numbers including money using appropriate problem solving strategies &tools.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Represents division as equal sharing, repeated subtraction, equal jumps on the number line and using formation of equal groups of objects.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Illustrates that multiplication and division are inverse operations.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving division of numbers by 2,3,4,5 and 10 and with any of the other operations of whole numbers including money using appropriate problem solving strategies and tools.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Identifies unit fractions with denominators of 10 and below.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Compares unit fractions using relation symbols.', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Arranges unit fractions in increasing or decreasing order.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Identifies other fractions less than one with denominators 10 and below.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Compares similar fractions using relation symbols.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Arranges similar fractions in increasing or decreasing order.', mathPCData.pre_s29, mathPCData.post_s29, 29)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Geometry</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identifies shapes/figures that show symmetry in a line.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Identifies and draws the line of symmetry in a given symmetrical figure.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Recognizes shapes that can tessellate.', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Identifies straight lines and curves, flat and curved surfaces in a 3-dimensional object. This is not reflected in the performance standards.', mathPCData.pre_s33, mathPCData.post_s33, 33)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Patterns and Algebra</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determines the missing term/s in a given continuous pattern using two attributes (any two of the following: figures, numbers, colors, sizes, and orientations, etc.).', mathPCData.pre_s34, mathPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Finds the missing value in a number sentence involving multiplication or division of whole numbers using 2, 3, 4, 5 and 10 only.', mathPCData.pre_s35, mathPCData.post_s35, 35)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Measurement</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Writes time in minutes including a.m. and p.m. using analog and digital clocks.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Finds the elapsed time in days.', mathPCData.pre_s37, mathPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Solves problems involving time (minutes including a.m. and p.m. and elapsed time in days).', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Compares length in meters or centimeters.', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving length.', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Compares mass in grams or kilograms.', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving mass.', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Illustrates area as a measure of how much surface is covered or occupied by a plane figure.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Finds the area of a given figure using square-tile units i.e. number of square-tiles needed.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving any figure using square tiles.', mathPCData.pre_s45, mathPCData.post_s45, 45)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Statistics and Probability</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Sorts, classifies, and organizes data in tabular form and presents this into a pictograph without and with scales.', mathPCData.pre_s46, mathPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Infers and interprets data presented in a pictograph without and with scales.', mathPCData.pre_s47, mathPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems using data presented in a pictograph without and with scales.', mathPCData.pre_s48, mathPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems using data presented in a pictograph without and with scales.', mathPCData.pre_s49, mathPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems using data presented in a pictograph without and with scales.', mathPCData.pre_s50, mathPCData.post_s50, 50)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for Science</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '17.12%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '17.62%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '17.12%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Matter</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify different objects based on their characteristics (e. g. Shape, Weight, Volume, Ease of flow).', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Identify if the object is solid, liquid or gas.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Identify if the object is solid, liquid or gas.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Give ways on the proper use and handling solid, liquid and gas found at home and in school.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Give ways on the proper use and handling solid, liquid and gas found at home and in school.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Determine changes in materials based on the effect of temperature: (Solid to liquid, Liquid to solid, Liquid to gas, Solid to gas).', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Identify different objects based on their characteristics (e.g. Shape, Weight, Volume, Ease of flow).', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Living Things</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine the parts and functions of the sense organs of the human body.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Give healthful habits to protect the sense organs.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Describe the place where animals live.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Give the parts and functions of animals.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Give the parts and functions of animals.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Identify animals according to their body parts .', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Give the importance of animals to humans.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Give ways to take care of animals.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Identify the parts of plants.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Give different uses of plants to humans.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Describe ways to take care of plants.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Describe ways to take care of plants.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Characterize living and nonliving things.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Describe how plants grow.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Identify observable characteristics that are passed on from parents to offspring (e.g., humans, animals, plants). ', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Identify the basic needs of humans.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Identify the basic needs of humans.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Give examples how living things depend on the environment to meet their basic needs.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Give ways to protect and conserve the environment.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Force and Motion</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Give the position of a person or an object in relation to a reference point such as chair, door, another person.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Determine things that can make objects move such as people, water, wind, magnets.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Characterize the movements of objects such as fast/slow, forward/backward, stretching/compressing.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Give sources of light and sound, heat and electricity.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Give sources of light and sound, heat and electricity.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Identify uses of light, sound, heat and electricity.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Identify uses of light, sound, heat and electricity.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Earth and Space</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the things found in the surroundings.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Give the importance of surroundings to people and other living things.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Determine the changes in the weather over a period of time.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Determine the changes in the weather over a period of time.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Describe how different types of weather affect activities of humans.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Provide safety and precautionary measures in different types of weather.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Provide safety and precautionary measures in different types of weather.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}