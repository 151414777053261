/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
   Input, Table, Typography, Space, Tooltip, Button, Select, Row, Col,
   Modal, Spin, Breadcrumb, Upload, Image, message, Progress
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
// import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import ProcessingModal from '../../components/processing/ProcessingModal';
// import { AttendanceList } from './components';
// import { StudentReports } from './Components/StudentReports';
import { BStudentReports, BStudentSummaryReports } from './components';
import Excel from 'exceljs';
// import uuid from 'react-uuid';
// import RenderPDF from '../../components/iframe-viewer/pdfReader';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';
import { Column } from 'rc-table';

const { Option } = Select;
const { Text } = Typography;

export function BDiagnosticReport() {
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [listLoading, setListLoading] = useState(false);
   const [isProcessing, setIsProcessing] = useState(false);
   const [levelList, setLevelList] = useState([]);
   const [level, setLevel] = useState(null);
   const [diagnosticType, setDiagnosticType] = useState(null);
   const [subject, setSubject] = useState(null);
   const [subjectID, setSubjectID] = useState(null);

   const [assignedList, setAssignedList] = useState([]);
   const [showAttendance, setShowAttendance] = useState(false);
   const [assignId, setAssignId] = useState(null);
   const [importData, setImportData] = useState([]);
   const [showReport, setShowReport] = useState(false);
   const [template, setTemplate] = useState(null);
   const [title, setTitle] = useState(null);
   const [date, setDate] = useState(null);
   // const [actionPlanFileList, setActionPlanFileList] = useState([]);
   const [actionPlanDoc, setActionPlanDoc] = useState(null);
   const [showActionPlan, setShowActionPlan] = useState(false);

   const [percentage, setPercentage] = useState(0);
   const [isUploading, setIsUploading] = React.useState(false);
   const [showSummaryReport, setShowSummaryReport] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      window.addEventListener('error', e => {
         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
               'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
               'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
               resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
               resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
         }
      });

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getLevels();
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const assigned_ass_columns = [
      {
         title: 'Title',
         key: 'title',
         //dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: payload => (
            <Tooltip placement="top" title={payload.title}>
               {/* <span style={{ cursor: "default" }}>{payload.title}</span> */}
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{payload.title}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{payload.start_date} - {payload.end_date}</p>
               </div>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '6%'
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         render: subject => (
            <span style={{ textTransform: 'capitalize' }}>{subject}</span>
         ),
         width: '19%'
      },
      {
         title: 'Assigned By',
         dataIndex: 'assigned_by_name',
         key: 'assigned_by_name',
         sorter: (a, b) => { return a.assigned_by_name.localeCompare(b.assigned_by_name) },
         ...getColumnSearchProps('assigned_by_name'),
         width: '14%'
      },
      {
         title: 'Assigned To',
         key: 'section_desc',
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.section_desc}>
            <>
               {
                  (payload.section_desc !== null && payload.section_desc !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.section_desc.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '8%'
      },
      // {
      //    title: 'Start Date',
      //    key: 'start_date',
      //    dataIndex: 'start_date',
      //    sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
      //    width: '10%'
      // },
      // {
      //    title: 'End Date',
      //    key: 'end_date',
      //    dataIndex: 'end_date',
      //    sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
      //    width: '10%'
      // },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {/* {
                     // parseInt(user_id) !== payload.assigned_by &&
                     <Tooltip placement="top" title='Show Attendance'><AntDIcons.CarryOutOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowAttendance(payload.id, payload.title, payload.start_date + ' - ' + payload.end_date)} /></Tooltip>
                  } */}
                  {/* {
                     // ((parseInt(user_id) === 10000 || parseInt(user_id) === 1) && payload.report_id === null) &&
                     (parseInt(user_id) === 10000 || parseInt(user_id) === 1) &&
                     <Tooltip placement="top" title={payload.report_id === null ? 'Upload Report' : 'Reupload Report'}>
                        {UploadReport(payload.id, payload.subject, payload.subject_id)}
                     </Tooltip>
                  } */}

                  {
                     (user_role === 'Teacher' || user_role === "Admin") &&
                     <>
                        <Tooltip placement="top" title='Show Reports'><AntDIcons.BarChartOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.id, payload.level_desc, payload.title, payload.start_date + ' - ' + payload.end_date, payload.subject, payload.subject_id)} /></Tooltip>
                     </>
                  }
                  {
                     (user_role === 'Admin') &&
                     <>
                        {UploadActionPlan(payload.id, payload.action_plan_docs)}
                     </>
                  }
                  {
                     (payload.action_plan_docs !== null && (user_role === 'Teacher' || user_role === "Admin")) &&
                     <>
                        <Tooltip placement="top" title='View Action Plan'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewActionPlan(payload.id, payload.action_plan_docs, payload.action_plan_doc_names)} /></Tooltip>
                     </>
                  }
               </Space>
            </>
         },
         width: '8%'
      },
   ].filter(item => !item.hidden);

   const handleSearchDiagnostic = () => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsforreport?assigned_by=${user_id}&type=${diagnosticType}&level=${level}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setAssignedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const handleShowAttendance = (id, title, date) => {
      setAssignId(id);
      setTitle(title);
      setDate(date);
      setShowAttendance(true);
   }

   const UploadReport = (assign_id, subject, subject_id) => {
      const props = {
         onRemove: file => {
            setImportData([]);
         },

         beforeUpload: file => {
            const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
            const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            const fileValid = isXLS || isXLSX;

            if (!fileValid) {
               setImportData([]);

               Modal.error({
                  title: 'Diagnostic Report',
                  content: 'You are not allowed to upload that file type.',
                  centered: true
               });

               return fileValid || Upload.LIST_IGNORE;

            } else {
               const wb = new Excel.Workbook();
               const reader = new FileReader();

               var importData = [];
               var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
               var data = {};

               reader.readAsArrayBuffer(file);
               reader.onload = () => {
                  const buffer = reader.result;
                  wb.xlsx.load(buffer).then((workbook) => {
                     console.log(workbook, 'workbook instance');
                     workbook.eachSheet((sheet, id) => {
                        sheet.eachRow((row, rowIndex) => {

                           if (rowIndex > 1 && row.values[2] !== undefined) {
                              // var new_data = {
                              //    id: '',
                              //    assignment_id: assign_id,
                              //    username: '',
                              //    subject: '',
                              //    scores: '',
                              //    grade_level: '',
                              //    type: '',
                              // }

                              var eng_col_start = 0;
                              var eng_col_end = 0;
                              var mat_col_start = 0;
                              var mat_col_end = 0;
                              var sci_col_start = 0;
                              var sci_col_end = 0;

                              switch (row.values[5]) {
                                 case 'Kindergarten':
                                 case 'Casa Tre':
                                 case 'Advance Casa':
                                    eng_col_start = 7;
                                    eng_col_end = 36;
                                    mat_col_start = 38;
                                    mat_col_end = 67;
                                    sci_col_start = 69;
                                    sci_col_end = 93;
                                    break;
                                 case 'Grade 1':
                                    eng_col_start = 7;
                                    eng_col_end = 36;
                                    mat_col_start = 38;
                                    mat_col_end = 87;
                                    sci_col_start = 89;
                                    sci_col_end = 118;
                                    break;
                                 case 'Grade 2':
                                    eng_col_start = 7;
                                    eng_col_end = 51;
                                    mat_col_start = 53;
                                    mat_col_end = 102;
                                    sci_col_start = 104;
                                    sci_col_end = 143;
                                    break;
                                 case 'Grade 3':
                                    eng_col_start = 7;
                                    eng_col_end = 56;
                                    mat_col_start = 58;
                                    mat_col_end = 97;
                                    sci_col_start = 99;
                                    sci_col_end = 158;
                                    break;
                                 case 'Grade 4':
                                    eng_col_start = 7;
                                    eng_col_end = 66;
                                    mat_col_start = 68;
                                    mat_col_end = 107;
                                    sci_col_start = 109;
                                    sci_col_end = 173;
                                    break;
                                 case 'Grade 5':
                                    eng_col_start = 7;
                                    eng_col_end = 66;
                                    mat_col_start = 68;
                                    mat_col_end = 132;
                                    sci_col_start = 134;
                                    sci_col_end = 183;
                                    break;
                                 case 'Grade 6':
                                    eng_col_start = 7;
                                    eng_col_end = 56;
                                    mat_col_start = 58;
                                    mat_col_end = 112;
                                    sci_col_start = 114;
                                    sci_col_end = 153;
                                    break;
                                 case 'Grade 7':
                                    eng_col_start = 7;
                                    eng_col_end = 66;
                                    mat_col_start = 68;
                                    mat_col_end = 127;
                                    sci_col_start = 129;
                                    sci_col_end = 198;
                                    break;
                                 case 'Grade 8':
                                    eng_col_start = 7;
                                    eng_col_end = 66;
                                    mat_col_start = 68;
                                    mat_col_end = 122;
                                    sci_col_start = 124;
                                    sci_col_end = 188;
                                    break;
                                 case 'Grade 9':
                                    eng_col_start = 7;
                                    eng_col_end = 56;
                                    mat_col_start = 58;
                                    mat_col_end = 117;
                                    sci_col_start = 119;
                                    sci_col_end = 178;
                                    break;
                                 case 'Grade 10':
                                    eng_col_start = 7;
                                    eng_col_end = 46;
                                    mat_col_start = 48;
                                    mat_col_end = 92;
                                    sci_col_start = 94;
                                    sci_col_end = 138;
                                    break;
                              }

                              if (subject_id === 'english' || subject_id === null || subject_id === 'ems') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };

                                 //-- Get english scores
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = 'english';
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = null;

                                 var scores = [];
                                 for (var i = eng_col_start; i <= eng_col_end; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              if (subject_id === 'math' || subject_id === null || subject_id === 'ems') {
                                 //-- Get math scores
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = 'math';
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = null;

                                 scores = [];
                                 for (i = mat_col_start; i <= mat_col_end; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              if (subject_id === 'science' || subject_id === null || subject_id === 'ems') {
                                 //-- Get science scores
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = 'science';
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = null;

                                 scores = [];
                                 for (i = sci_col_start; i <= sci_col_end; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- Start: Data collection for Senior High

                              //-- General Mathematics(gm)
                              //-- Oral Communication(oc)
                              if (subject_id === 'gm' || subject_id === 'oc') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 58; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- General Physics 1(gp1)
                              //-- General Biology 1(gb1)
                              //-- Fundamentals of Accountancy, Business and Management 2(fabm2) 
                              //-- Philippine Politics and Governance(ppg)
                              //-- Business Finance(bf)
                              //-- Trends, network and critical thinking(tnct)
                              //-- General Physics 2(gp2)
                              //-- Applied Economics(ae)
                              if (subject_id === 'gp1' || subject_id === 'gb1' || subject_id === 'fabm2' ||
                                 subject_id === 'ppg' || subject_id === 'bf' || subject_id === 'tnct' ||
                                 subject_id === 'gp2' || subject_id === 'ae') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 48; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- World Religion and Belief System(wrbs)
                              //-- General Biology 2(gb2)
                              //-- Creative Writing(cw)
                              if (subject_id === 'wrbs' || subject_id === 'gb2' || subject_id === 'cw') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 38; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- Business Ethics and Social Responsibility(besr)
                              if (subject_id === 'besr') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 43; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- Reading and Writing(raw)
                              if (subject_id === 'raw') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 53; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- Statistics and Probabiltiy(stats)
                              if (subject_id === 'stats') {
                                 data = {
                                    id: '',
                                    assignment_id: assign_id,
                                    username: '',
                                    subject: '',
                                    scores: '',
                                    grade_level: '',
                                    type: '',
                                 };
                                 data.id = 'diagnostic_report_' + o2o_mode;
                                 data.username = row.values[2];
                                 data.subject = subject_id;
                                 data.grade_level = row.values[5];
                                 data.type = diagnosticType;
                                 data.track = row.values[6];

                                 scores = [];
                                 for (i = 9; i <= 68; i++) {
                                    scores.push(row.values[i]);
                                 }

                                 data.scores = scores.join(',');
                                 importData.push(data);
                                 //------------------
                              }

                              //-- End: Data collecdtion for Senior High
                           }
                        });
                     });

                     // if (subject !== null)
                     handleImportData(importData);
                  });
               };
            }

            return false;
         },
      };

      return (
         <Upload
            {...props}
            showUploadList={false}
            accept=".xls, .xlsx"
            style={{ width: "100%" }}
         >
            <AntDIcons.CloudUploadOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
         </Upload>
      );
   };

   const handleImportData = (data) => {
      setIsProcessing(true);

      var url = `${process.env.REACT_APP_API_CPH}/importdiagnosticreport`;
      axios.post(url, data).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            Modal.success({
               title: 'Diagnostic Report',
               content: 'Diagnostic report import successful!',
               centered: true,
            });

            setIsProcessing(false);
            handleSearchDiagnostic();
         }
      }, (error) => {
         Modal.error({
            title: 'Diagnostic Report',
            content: 'Diagnostic report import failed!',
            centered: true,
         });

         setIsProcessing(false);
      });
   }

   const handleShowReport = (id, template, title, date, subject, subject_id) => {
      setAssignId(id);
      setTemplate(template);
      setTitle(title);
      setDate(date);
      setSubject(subject);
      setSubjectID(subject_id);
      setShowReport(true);
   }

   const UploadActionPlan = (id, docs) => {
      const props = {
         beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
               message.error(`Only PDF files can be uploaded`);
            }
            else
               handleUploadActionPlan(id, file);
         },
      };

      return (
         <Upload {...props} showUploadList={false} accept=".pdf">
            <Tooltip placement="top" title={docs !== null ? "Re-upload Action Plan" : "Upload Action Plan"}>
               <AntDIcons.CloudUploadOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
            </Tooltip>
         </Upload>
      );
   };

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUploadActionPlan = (id, file) => {
      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      let filename = file.name;

      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadlearningplan?s3path=${process.env.REACT_APP_S3_DIAGNOSTIC_ASSESSMENT_PATH}`;
      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            var url = `${process.env.REACT_APP_API_CPH}/updatediagnosticactionplan?id=${id}&doc=${data}&doc_name=${filename}`;
            axios.post(url, data).then(response => {
               console.log(response);
               if (response.data) {
                  const data = response.data;

                  Modal.success({
                     title: 'Diagnostic Report',
                     content: 'File uploaded.',
                     centered: true
                  });

                  setIsUploading(false);
                  handleSearchDiagnostic();
               }
            }, (error) => {
               Modal.error({
                  title: 'Diagnostic Report',
                  content: 'File upload failed.',
                  centered: true
               });
               setIsUploading(false);
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Diagnostic Report',
            content: 'File upload failed.',
            centered: true
         });
         setIsUploading(false);
      });
   }

   const handleViewActionPlan = (id, doc, doc_names) => {
      setActionPlanDoc(doc);
      setShowActionPlan(true);
   }

   return (
      <>
         <NoticeBoardSlide />
         <ProcessingModal
            processing={isProcessing}
            title={'Diagnostic Report'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">
                        Diagnostic Report
                     </li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Diagnostic Report</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={10} style={{ paddingBottom: "20px" }} >
                  <Col span={24}>
                     <div className='search-columns-3'>
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => setLevel(val)}
                        >
                           <Option value={-1}>All</Option>
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>

                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Report Type"
                           onChange={(val) => setDiagnosticType(val)}
                        >
                           <Option value={'pre'}>Pre Test</Option>
                           <Option value={'post'}>Post Test</Option>
                        </Select>

                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchDiagnostic()} icon={<AntDIcons.SearchOutlined />}>Search</Button>
                     </div>
                  </Col>
               </Row>

               {assignedList !== null
                  ?
                  <>
                     <div className="dashboard-item-inner3">
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={assigned_ass_columns}
                           dataSource={assignedList}
                           pagination={false}
                           // style={{ minWidth: "1000px" }}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 12.2rem)',
                           }}
                           // footer={() => 'Total Records: ' + assignedList.length}
                           footer={() =>
                              <Row style={{ width: "100%" }}>
                                 <Col style={{ width: "70%" }}>
                                    {'Total Records: ' + assignedList.length}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {
                                       assignedList.length > 0 &&
                                       <Button className='button-shadow' size='middle' type='primary' shape='round' onClick={() => setShowSummaryReport(true)}>
                                          Summary Report
                                       </Button>
                                    }
                                 </Col>
                              </Row>
                           }
                        />
                     </div>
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
            </div>
         </div>

         {/* <Modal
            key='show-attendance'
            title={<div>
               <Space direction='horizontal'>
                  <Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" />
                  <div style={{ width: "100%", cursor: "default" }}>
                     <b><Text>Attendance - {title}</Text></b>
                     <p style={{ marginBottom: '0px' }}>{date}</p>
                  </div>
               </Space>
            </div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showAttendance}
            width='55%'
            onCancel={() => { setShowAttendance(false); }}
            onOk={() => { setShowAttendance(false); }}
         >
            <AttendanceList
               id={assignId}
            />
         </Modal> */}

         <Modal
            key='show-report'
            title={<div>
               <Space direction='horizontal'>
                  <Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" />
                  <div style={{ width: "100%", cursor: "default" }}>
                     <b><Text>View Report - {title}</Text></b>
                     <p style={{ marginBottom: '0px' }}>{date}</p>
                  </div>
               </Space>
            </div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showReport}
            width='50%'
            onCancel={() => { setShowReport(false); }}
            onOk={() => { setShowReport(false); }}
         >
            <BStudentReports
               id={assignId}
               template={template}
               type={diagnosticType}
               subject={subject}
               subject_id={subjectID}
            />
         </Modal>

         <Modal
            key="modal-action-plan"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> View Action Plan</div>}
            destroyOnClose={true}
            centered
            open={showActionPlan}
            onOk={() => setShowActionPlan(false)}
            okText={"Close"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
            width='75%'
         >
            <div className='student_list_container'>
               <DocumentViewer
                  document={process.env.REACT_APP_S3_DIAGNOSTIC_ASSESSMENT_PATH + '/' + actionPlanDoc}
                  pdfshowdownload={true}
                  pdfshowprint={true}
                  ismodal={true}
                  dvheight={"calc(100vh - 220px)"}
               />
            </div>
         </Modal>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>

         <Modal
            key='show-summary-report'
            title={<div>
               <Space direction='horizontal'>
                  <Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" />
                  <div style={{ width: "100%", cursor: "default" }}>
                     <b><Text>View Summary Report</Text></b>
                     {/* <p style={{ marginBottom: '0px' }}>{date}</p> */}
                  </div>
               </Space>
            </div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showSummaryReport}
            width='50%'
            onCancel={() => { setShowSummaryReport(false); }}
            onOk={() => { setShowSummaryReport(false); }}
         >
            <BStudentSummaryReports
               level={level}
               type={diagnosticType}
            />
         </Modal>
      </>
   );
}