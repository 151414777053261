/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ProgressBar from "@ramonak/react-progress-bar";
import { IntroSum } from '../components';

export function MTG7ScienceSummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [matter, setMatter] = useState(null);
   const [living, setLiving] = useState(null);
   const [force, setForce] = useState(null);
   const [earth, setEarth] = useState(null);
   const [total, setTotal] = useState(null);

   const [pcData, setPCData] = useState(null);
   // const [VN, setVN] = useState(null);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var preData = data.filter(item => item.diagnostic_type === 'pre');
            var postData = data.filter(item => item.diagnostic_type === 'post');
            // setVN(preData.length);

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  setPCData(data2[0]);
                  extractData(preData, postData);

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractData = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _matter = {};
      var _living = {};
      var _force = {};
      var _earth = {};

      var _total = {};
      var _proficiency = '';

      //-- Matter (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.68) / 1.79) > 1)
            _competent++;
         else if (((_sumVal - 5.68) / 1.79) > -0.51)
            _basic++;
         else if (((_sumVal - 5.68) / 1.79) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.68) / 1.79) > 1)
                  _competent++;
               else if (((_sumVal - 5.68) / 1.79) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.68) / 1.79) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.78 ? 'Beginner' : (_postAve >= 4.79 && _postAve <= 7.47 ? 'Basic' : (_postAve >= 7.48 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.78 ? 'Beginner' : (_preAve >= 4.79 && _preAve <= 7.47 ? 'Basic' : (_preAve >= 7.48 && _preAve <= 11 ? 'Competent' : ''));
      }

      _matter = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.9) / 1.52) > 1)
            _competent++;
         else if (((_sumVal - 4.9) / 1.52) > -0.51)
            _basic++;
         else if (((_sumVal - 4.9) / 1.52) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22;
            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.9) / 1.52) > 1)
                  _competent++;
               else if (((_sumVal - 4.9) / 1.52) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.9) / 1.52) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.13 ? 'Beginner' : (_postAve >= 4.14 && _postAve <= 6.42 ? 'Basic' : (_postAve >= 6.43 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.13 ? 'Beginner' : (_preAve >= 4.14 && _preAve <= 6.42 ? 'Basic' : (_preAve >= 6.43 && _preAve <= 11 ? 'Competent' : ''));
      }

      _living = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living (end)      

      //-- Force (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.4) / 1.62) > 1)
            _competent++;
         else if (((_sumVal - 4.4) / 1.62) > -0.51)
            _basic++;
         else if (((_sumVal - 4.4) / 1.62) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33;;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.4) / 1.62) > 1)
                  _competent++;
               else if (((_sumVal - 4.4) / 1.62) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.4) / 1.62) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.58 ? 'Beginner' : (_postAve >= 3.59 && _postAve <= 6.02 ? 'Basic' : (_postAve >= 6.03 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.58 ? 'Beginner' : (_preAve >= 3.59 && _preAve <= 6.02 ? 'Basic' : (_preAve >= 6.03 && _preAve <= 11 ? 'Competent' : ''));
      }

      _force = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force (end)

      //-- Earth (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.28) / 1.25) > 1)
            _competent++;
         else if (((_sumVal - 4.28) / 1.25) > -0.51)
            _basic++;
         else if (((_sumVal - 4.28) / 1.25) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.28) / 1.25) > 1)
                  _competent++;
               else if (((_sumVal - 4.28) / 1.25) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.28) / 1.25) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.65 ? 'Beginner' : (_postAve >= 3.66 && _postAve <= 5.53 ? 'Basic' : (_postAve >= 5.54 && _postAve <= 7 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.65 ? 'Beginner' : (_preAve >= 3.66 && _preAve <= 5.53 ? 'Basic' : (_preAve >= 5.54 && _preAve <= 7 ? 'Competent' : ''));
      }

      _earth = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth (end)

      _total = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _matter.prem + _living.prem + _force.prem + _earth.prem,
         postm: _matter.postm + _living.postm + _force.postm + _earth.postm,
         min: _matter.min + _living.min + _force.min + _earth.min,
         max: _matter.max + _living.max + _force.max + _earth.max,
         sd: _matter.sd + _living.sd + _force.sd + _earth.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _living.beginner + _force.beginner + _earth.beginner,
         basic: _matter.basic + _living.basic + _force.basic + _earth.basic,
         competent: _matter.competent + _living.competent + _earth.competent
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 18.56 ? 'Beginner' : (_total.postm >= 18.57 && _total.postm <= 25.09 ? 'Basic' : (_total.postm >= 25.2 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 18.56 ? 'Beginner' : (_total.prem >= 18.57 && _total.prem <= 25.09 ? 'Basic' : (_total.prem >= 25.1 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setTotal(_total);
      setMatter(_matter);
      setLiving(_living);
      setForce(_force);
      setEarth(_earth);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ width: '20.56%', borderStyle: 'solid', borderWidth: '0.75pt', borderBottom: '1pt solid #cccccc', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.78%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ width: '11.4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.prem <= 0 || data.prem === null ? '' : (data.prem).toFixed(2)}</p>
            </td>
            <td style={{ width: '11.68%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm <= 0 || data.postm === null ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ width: '10.8%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.min}</p>
            </td>
            <td style={{ width: '11.16%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.max}</p>
            </td>
            <td style={{ width: '14.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.sd).toFixed(2)}</p>
            </td>
            <td style={{ width: '15.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <Row><Col span={24} style={{ textAlign: "left" }}><p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. {label !== "TOTAL" ? "Frequency of Proficiency Levels on " : ""} {label}</strong></p></Col></Row>
            <Row>
               <Col span={12}>
                  <table cellSpacing={0} cellPadding={0} style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner') === '100.00' ? '100' : (Utils.getPercentage(data, 'beginner') === '0.00' ? '0' : Utils.getPercentage(data, 'beginner'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic') === '100.00' ? '100' : (Utils.getPercentage(data, 'basic') === '0.00' ? '0' : Utils.getPercentage(data, 'basic'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent') === '100.00' ? '100' : (Utils.getPercentage(data, 'competent') === '0.00' ? '0' : Utils.getPercentage(data, 'competent'))}</p>
                        </td>
                     </tr>
                  </table>
               </Col>
               <Col span={12}>
                  <div style={{ marginLeft: "20px", marginRight: "20px", height: "200px" }}>
                     <Bar
                        options={{
                           responsive: true,
                           scales: {
                              y:
                              {
                                 min: 0,
                                 max: 100,
                                 stepSize: 1,
                              },
                              x:
                                 {},
                           }
                        }}

                        data={{
                           labels: ['Beginner', 'Basic', 'Competent'],
                           datasets: [
                              {
                                 label: label,
                                 backgroundColor: "#4F81BD",
                                 borderColor: "#4F81BD",
                                 data: [Utils.getPercentage(data, 'beginner'), Utils.getPercentage(data, 'basic'), Utils.getPercentage(data, 'competent')],
                              },
                           ],
                        }}
                     />
                  </div>
               </Col>
            </Row>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ width: '11.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  <strong>{index}</strong>
               </p>
            </td>
            <td style={{ width: '330.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  {label}
               </p>
            </td>
            <td style={{ width: '43.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {predata !== null ? predata.toFixed(2) + '%' : ''} */}
                  {/* {predata !== null ? (predata === 100 ? predata : (predata === 0 ? '0' : predata.toFixed(2))) : ''} */}
                  {
                     (predata !== null && predata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={predata !== 100 ? predata.toFixed(1) + "%" : predata + "%"}
                        completed={predata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '75.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {postdata !== null ? (postdata === 100 ? postdata : (postdata === 0 ? '0' : postdata.toFixed(2))) : ''} */}
                  {
                     (postdata !== null && postdata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={postdata !== 100 ? postdata.toFixed(1) + "%" : postdata + "%"}
                        completed={postdata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
         </tr>
      );
   }

   const generateContentStandards = (label, beg1, beg2, bas1, bas2, comp1, comp2) => {
      return (
         <tr style={{ height: '14.5pt' }}>
            <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.54%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg2}</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas2}</p>
            </td>
            <td style={{ width: '8.42%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '6.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp2}</p>
            </td>
         </tr>
      );
   }

   const renderLabel = (label) => {
      return (
         <>
            <tr>
               <td colSpan={4} style={{ width: '11.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                  <p style={{ margin: '0pt 5.65pt', textAlign: 'left', fontSize: '11pt' }}>
                     <strong>{label}</strong>
                  </p>
               </td>
            </tr>
         </>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ margin: '20px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#4F81BD"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '10pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}>
                              <strong>&nbsp;</strong>
                           </p>
                           <div style={{ pageBreakBefore: 'always' }}></div>
                           <p style={{ textAlign: 'center', fontSize: '14pt' }}><strong>Summary of Results for {props.subject}</strong></p>
                           <p style={{ textAlign: 'center' }}><strong>PRE TEST</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ textAlign: 'center', width: '25%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Area</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>N</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Pretest Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Post Test Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Min</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '11%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Max</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '14%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Standard Deviation</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '13%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong>
                                    </td>
                                 </tr>
                                 {generateMeanLevelRow('Matter', matter, false)}
                                 {generateMeanLevelRow('Living Things', living, false)}
                                 {generateMeanLevelRow('Force and Motion', force, false)}
                                 {generateMeanLevelRow('Earth and Space', earth, false)}
                                 {generateMeanLevelRow('TOTAL', total, true)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Content Standards</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Beginner</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Basic</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Competent</span></strong></p>
                                    </td>
                                 </tr>
                                 {generateContentStandards('Matter', 0, 4.78, 4.79, 7.47, 7.48, 11)}
                                 {generateContentStandards('Living Things', 0, 4.13, 4.14, 6.42, 6.43, 11)}
                                 {generateContentStandards('Force and Motion', 0, 3.58, 3.59, 6.02, 6.03, 11)}
                                 {generateContentStandards('Earth and Space', 0, 3.65, 3.66, 5.53, 5.54, 7)}
                                 {generateContentStandards('TOTAL', 0, 18.56, 18.57, 25.09, 25.1, 40)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           {generateFrequencyRow('Matter', matter, 1)}
                           {generateFrequencyRow('Living Things', living, 2)}
                           {generateFrequencyRow('Force and Motion', force, 3)}
                           {generateFrequencyRow('Earth and Space', earth, 4)}
                           {generateFrequencyRow('TOTAL', total, 5)}
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>Percent of Correct</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td colSpan={2} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                    </td>
                                    <td style={{ width: '19.9%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Pre Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '20.6%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Post Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderLabel("Part 1: Matter")}
                                 {generatePercentCorrectRow("Describe the Particle Model of Matter as “All matter is made up of tiny particles with each pure substance having its own kind of particles.", pcData.pre_s1, pcData.post_s1, 1)}
                                 {generatePercentCorrectRow("Describe that particles are constantly in motion, have spaces between them, attract each other, and move faster as the temperature increases (or with the addition of heat)", pcData.pre_s2, pcData.post_s2, 2)}
                                 {generatePercentCorrectRow("Use diagrams and illustrations to describe the arrangement, spacing, and relative motion of the particles in each of the three states (phases) of matter", pcData.pre_s3, pcData.post_s3, 3)}
                                 {generatePercentCorrectRow("Explain the changes of state in terms of particle arrangement and energy changes: (a.) solid → liquid → vapor, and (b.) vapor → liquid → solid;", pcData.pre_s4, pcData.post_s4, 4)}
                                 {generatePercentCorrectRow("Follow appropriate steps of a scientific investigation which includes: a. Aim or problem, b. Materials and equipment, c. Method or procedures, d. Results including data, and e. Conclusion.", pcData.pre_s5, pcData.post_s5, 5)}
                                 {generatePercentCorrectRow("Make accurate measurements using standard units for physical quantities and organize the collected data when carrying out a scientific investigation", pcData.pre_s6, pcData.post_s6, 6)}
                                 {generatePercentCorrectRow("Identify the role of the solute and solvent in a solution", pcData.pre_s7, pcData.post_s7, 7)}
                                 {generatePercentCorrectRow("Express quantitatively the amount of solute present in a given volume of solution", pcData.pre_s8, pcData.post_s8, 8)}
                                 {generatePercentCorrectRow("Demonstrate how different factors affect the solubility of a solute in a given solvent, such as heat", pcData.pre_s9, pcData.post_s9, 9)}
                                 {generatePercentCorrectRow("Identify solutions, which can be found at home and in school and that react with litmus indicator, as acids, bases, and salts", pcData.pre_s10, pcData.post_s10, 10)}
                                 {generatePercentCorrectRow("Demonstrate proper use and handling of science equipment.", pcData.pre_s11, pcData.post_s11, 11)}
                                 {renderLabel("Part 2: Life Science")}
                                 {generatePercentCorrectRow("Identify the parts and functions, and demonstrate proper handling and storing of a compound microscope", pcData.pre_s12, pcData.post_s12, 12)}
                                 {generatePercentCorrectRow("Identify the parts and functions, and demonstrate proper handling and storing of a compound microscope", pcData.pre_s13, pcData.post_s13, 13)}
                                 {generatePercentCorrectRow("Use proper techniques in observing and identifying the parts of a cell with a microscope such as the cell membrane, nucleus, cytoplasm, mitochondria, chloroplasts, and ribosomes;", pcData.pre_s14, pcData.post_s14, 14)}
                                 {generatePercentCorrectRow("Recognize that some organisms consist of a single cell (unicellular) like in bacteria and some consist of many cells (multicellular) like in a human;", pcData.pre_s15, pcData.post_s15, 15)}
                                 {generatePercentCorrectRow("Differentiate plant and animal cells based on their organelles;", pcData.pre_s16, pcData.post_s16, 16)}
                                 {generatePercentCorrectRow("Recognize that cells reproduce through two types of cell division, mitosis and meiosis, and describe mitosis as cell division for growth and repair;", pcData.pre_s17, pcData.post_s17, 17)}
                                 {generatePercentCorrectRow("Explain that genetic information is passed onto offspring from both parents by the process of meiosis and fertilization", pcData.pre_s18, pcData.post_s18, 18)}
                                 {generatePercentCorrectRow("Differentiate sexual from asexual reproduction in terms of: a) number of parents involved, and b) similarities of offspring to parents", pcData.pre_s19, pcData.post_s19, 19)}
                                 {generatePercentCorrectRow("Use a labeled diagram to describe the connections between the levels of biological organization to one another from cells to the biosphere", pcData.pre_s20, pcData.post_s20, 20)}
                                 {generatePercentCorrectRow("Describe the trophic levels of an organism as levels of energy in a food pyramid;", pcData.pre_s21, pcData.post_s21, 21)}
                                 {generatePercentCorrectRow("Use examples of food pyramids to describe the transfer of energy between organisms from one trophic level to another.", pcData.pre_s22, pcData.post_s22, 22)}
                                 {renderLabel("Part 3: Force, Motion, and Energy")}
                                 {generatePercentCorrectRow("Identify that forces act between objects and can be measured.", pcData.pre_s23, pcData.post_s23, 23)}
                                 {generatePercentCorrectRow("Identify and describe everyday situations that demonstrate: a. balanced forces such as a box resting on an inclined plane, a man standing still, or an object moving with constant velocity; b. unbalanced forces, such as freely falling fruit or an accelerating car", pcData.pre_s24, pcData.post_s24, 24)}
                                 {generatePercentCorrectRow("Identify and describe everyday situations that demonstrate: a. balanced forces such as a box resting on an inclined plane, a man standing still, or an object moving with constant velocity; b. unbalanced forces, such as freely falling fruit or an accelerating car", pcData.pre_s25, pcData.post_s25, 25)}
                                 {generatePercentCorrectRow("Identify that when forces are not balanced, they can cause changes in the object’s speed or direction of motion;", pcData.pre_s26, pcData.post_s26, 26)}
                                 {generatePercentCorrectRow("Explain the difference between distance and displacement", pcData.pre_s27, pcData.post_s27, 27)}
                                 {generatePercentCorrectRow("Distinguish between speed and velocity using the concept of vectors", pcData.pre_s28, pcData.post_s28, 28)}
                                 {generatePercentCorrectRow("Describe uniform velocity and represent it using distance-time graphs", pcData.pre_s29, pcData.post_s29, 29)}
                                 {generatePercentCorrectRow("Explain the difference between heat and temperature", pcData.pre_s30, pcData.post_s30, 30)}
                                 {generatePercentCorrectRow("Identify advantageous and disadvantageous examples of conduction, convection, and radiation", pcData.pre_s31, pcData.post_s31, 31)}
                                 {generatePercentCorrectRow("Explain in terms of the particle model the processes underlying convection and conduction of heat energy", pcData.pre_s32, pcData.post_s32, 32)}
                                 {generatePercentCorrectRow("Explain in terms of the particle model the processes underlying convection and conduction of heat energy", pcData.pre_s33, pcData.post_s33, 33)}
                                 {renderLabel("Part 4: Earth and Space")}
                                 {generatePercentCorrectRow("Classify geological faults according to the angle of the fault plane and direction of slip", pcData.pre_s34, pcData.post_s34, 34)}
                                 {generatePercentCorrectRow("Describe how the effects of earthquakes on communities depend on their magnitude", pcData.pre_s35, pcData.post_s35, 35)}
                                 {generatePercentCorrectRow("Explain how earthquakes result in tsunamis that devastate shoreline communities", pcData.pre_s36, pcData.post_s36, 36)}
                                 {generatePercentCorrectRow("Describe procedures that the authorities have in place to alert communities of pending tsunamis and what procedures can be implemented should a tsunami impact a community", pcData.pre_s37, pcData.post_s37, 37)}
                                 {generatePercentCorrectRow("Explain how energy from the Sun interacts with the atmosphere.", pcData.pre_s38, pcData.post_s38, 38)}
                                 {generatePercentCorrectRow("Explain, using models, how the tilt of the Earth affects the changes in the length of daytime at different times of the year", pcData.pre_s39, pcData.post_s39, 39)}
                                 {generatePercentCorrectRow("Explain how solar energy contributes to the occurrence of land and sea breezes, monsoons, and the Intertropical Convergence Zone (ITCZ)", pcData.pre_s40, pcData.post_s40, 40)}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}