/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { Empty, Spin } from 'antd';
import { InterpretationInd, IntroIndOld } from '../components';

export function English10Report(props) {
   const [reportData, setReportData] = useState(null);
   const [readingComprehension, setReadingComprehension] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [writing, setWriting] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.subject_id, props.assignment_id, props.student_id, props.template)
   }, []);

   const getReport = (subject, assignment_id, student_id, template) => {
      setDataLoading(true);
      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreport?s=${subject}&ai=${assignment_id}&si=${student_id}&t=${template}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data[0];

            if (data !== undefined) {
               setReadingComprehension(data.s1 + data.s2 + data.s3 + data.s4 + data.s5 + data.s6 + data.s7 + data.s8 + data.s9 +
                  data.s10 + data.s11 + data.s12 + data.s13 + data.s14 + data.s15 + data.s16 + data.s17);
               setViewingComprehension(data.s18 + data.s19 + data.s20 + data.s21);
               setVocabulary(data.s22 + data.s23 + data.s24 + data.s25 + data.s26 + data.s27 + data.s28);
               setWriting(data.s29 + data.s30 + data.s31 + data.s32);
               setGrammar(data.s33 + data.s34 + data.s35 + data.s36 + data.s37 + data.s38 + data.s39 + data.s40);

               setReportData(data);
            }

            setDataLoading(false);
         }
      }, (error) => {
         setDataLoading(false);
         console.log(error.message);
      });
      // }
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               reportData !== null
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='individual-report'>

                        <IntroIndOld
                           subject='English'
                           grade_level={reportData.grade_level}
                           student_name={reportData.student_name}
                           school_name={reportData.school_name}
                           section={reportData.section}
                           diagnostic_date={reportData.diagnostic_date}
                        />

                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Competency Score</strong></p>
                        <div style={{ textAlign: 'center' }}>
                           <table cellSpacing={0} cellPadding={0} style={{ marginRight: 'auto', marginLeft: 'auto', borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Learning Competency</span></strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Score</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Reading Comprehension</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>1</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Read closely to get the author’s purpose.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s1}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s1}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>2</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Read closely to get explicitly and implicitly stated information.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s2}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s2}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>3</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Read closely to get explicitly and implicitly stated information.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s3}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s3}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>4</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Identifying textual details that affirm or refute a claim.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s4}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s4}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>5</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Identifying textual details that affirm or refute a claim.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s5}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s5}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>6</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Draw conclusions from the set of details.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s6}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s6}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>7</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Draw conclusions from the set of details.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s7}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s7}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>8</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Identify power struggles of characters (Marxist).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s8}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s8}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>9</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Examine Biases.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s9}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s9}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>10</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Relevance of the selection to the historical context during which it was produced (historical).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s10}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s10}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>11</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Distinguish facts from beliefs (fact).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s11}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s11}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>12</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Distinguish facts from beliefs (belief).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s12}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s12}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>13</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Point out relationships among statements.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s13}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s13}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>14</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Point out relationships among statements.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s14}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s14}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>15</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Distinguish between general and specific statements.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s15}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s15}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>16</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Distinguish between general and specific statements.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s16}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s16}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>17</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Distinguish between general and specific statements.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s17}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s17}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Viewing Comprehension</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>18</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Determine the relevance and the truthfulness of the ideas presented in the material viewed.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s18}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s18}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>19</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Formulate predictions based on the material viewed.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s19}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s19}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>20</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Draw generalizations and conclusions based on the materials viewed.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s20}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s20}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>21</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Detect bias and prejudice in the material viewed.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s21}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s21}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Vocabulary</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>22</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Differentiate formal from informal definitions of words.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s22}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s22}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>23</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Give technical and operational definitions (technical).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s23}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s23}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>24</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Give technical and operational definitions (operational).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s24}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s24}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>25</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Give expanded definitions of words.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s25}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s25}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>26</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Give expanded definitions of words.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s26}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s26}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>27</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Get familiar with technical terms used in research.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s27}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s27}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>28</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Get familiar with technical terms used in research.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s28}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s28}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Writing and Composition</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>29</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Identify features of persuasive texts.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s29}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s29}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>30</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Identify parts and features of argumentative essays.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s30}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s30}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>31</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Formulate claims of fact, policy, and value.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s31}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s31}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>32</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Acknowledge sources by preparing a bibliography.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s32}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s32}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Grammar</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>33</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use reflexive and intensive pronouns.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s33}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s33}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>34</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use reflexive and intensive pronouns.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s34}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s34}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>35</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use modals</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s35}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s35}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>36</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use modals</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s36}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s36}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>37</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use modals</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s37}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s37}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>38</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Observe correct grammar in making definitions.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s38}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s38}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>39</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use words and expressions that affirm or negate.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s39}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s39}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>40</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Use pronouns effectively.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s40}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s40}%</p>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><a name="_Hlk103365502"><strong>Summary of Results.</strong>&nbsp; The result for the content standards is expressed in terms of the raw score, percent correct, standard score, and proficiency level.&nbsp;</a></p>
                        <ul type="disc" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Raw Score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The raw score is the total number of correct answers for the content standard.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Percent Correct.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The percent correct is the raw score converted into percentage. The percent correct provides you with an easier way to determine how many correct answers you are able to obtain for each content standard. If the percent correct is close to 100%, the more correct answers you are able to attain.</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Standard score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The scores are converted into a standard score to allow comparison of scores across content standards and across levels. A standard score near 0.0 means that the learner’s standing in the content standard is within the majority of the test takers. A standard score of more than 1.00 mean that the attainment of the competencies is high because there are several correct answers.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Proficiency Level.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The proficiency level describes your present level of attainment on content standard. The levels of proficiency start from beginner to basic to competent. The beginner level means few correct answers attained in the content standard. The basic level means that the score is within the majority of the test takers. Competent means that several correct answers are attained.</span><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span></li>
                        </ul>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>Summary of Results</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Areas</span></strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Raw Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct</span></strong></p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Reading Comprehension</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          readingComprehension
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((readingComprehension) / 17) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((readingComprehension - 11.73) / 2.09).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((readingComprehension - 11.73) / 2.09) > 1
                                             ? 'Competent'
                                             : (((readingComprehension - 11.73) / 2.09) >= -0.51
                                                ? 'Basic'
                                                : (((readingComprehension - 11.73) / 2.09) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Viewing Comprehension</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          viewingComprehension
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((viewingComprehension / 4) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((viewingComprehension - 2.9) / 0.82).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((viewingComprehension - 2.9) / 0.82) > 1
                                             ? 'Competent'
                                             : (((viewingComprehension - 2.9) / 0.82) >= -0.51
                                                ? 'Basic'
                                                : (((viewingComprehension - 2.9) / 0.82) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>


                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Vocabulary Development</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          vocabulary
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((vocabulary) / 7) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((vocabulary - 3.47) / 0.77).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((vocabulary - 3.47) / 0.77) > 1
                                             ? 'Competent'
                                             : (((vocabulary - 3.47) / 0.77) >= -0.51
                                                ? 'Basic'
                                                : (((vocabulary - 3.47) / 0.77) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>

                              <tr>
                                 <td style={{ width: '197.1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Writing and Composition</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          writing
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((writing) / 4) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((writing - 2.88) / 1.36).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((writing - 2.88) / 1.36) > 1
                                             ? 'Competent'
                                             : (((writing - 2.88) / 1.36) >= -0.51
                                                ? 'Basic'
                                                : (((writing - 2.88) / 1.36) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Grammar</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          grammar
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((grammar) / 8) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((grammar - 6.26) / 1.19).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((grammar - 6.26) / 1.19) > 1
                                             ? 'Competent'
                                             : (((grammar - 6.26) / 1.19) >= -0.51
                                                ? 'Basic'
                                                : (((grammar - 6.26) / 1.19) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Overall English</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          viewingComprehension + vocabulary + readingComprehension + grammar + writing
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((viewingComprehension + vocabulary + readingComprehension + grammar + writing) / 40) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((viewingComprehension + vocabulary + readingComprehension + grammar + writing) - 27.26) / 3.9).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((viewingComprehension + vocabulary + readingComprehension + grammar + writing) - 27.26) / 3.9) > 1
                                             ? 'Competent'
                                             : ((((viewingComprehension + vocabulary + readingComprehension + grammar + writing) - 27.26) / 3.9) >= -0.51
                                                ? 'Basic'
                                                : ((((viewingComprehension + vocabulary + readingComprehension + grammar + writing) - 27.26) / 3.9) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>

                        <InterpretationInd backgroundColor='#000000' />

                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                     </div>
                  </div>
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}