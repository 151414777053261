/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade10SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [readingComprehension, setReadingComprehension] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [writing, setWriting] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _viewingComprehension = {};
      var _vocabulary = {};
      var _writing = {};
      var _grammar = {};

      var _total = {};
      var _proficiency = '';

      //-- Reading Comprehension (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 +
            pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11.73) / 2.09) > 1)
            _competent++;
         else if (((_sumVal - 11.73) / 2.09) > -0.51)
            _basic++;
         else if (((_sumVal - 11.73) / 2.09) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 +
               post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11.73) / 2.09) > 1)
                  _competent++;
               else if (((_sumVal - 11.73) / 2.09) > -0.51)
                  _basic++;
               else if (((_sumVal - 11.73) / 2.09) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 10.99 ? 'Beginner' : (_postAve >= 11 && _postAve <= 12 ? 'Basic' : (_postAve >= 12.01 && _postAve <= 14 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 10.99 ? 'Beginner' : (_preAve >= 11 && _preAve <= 12 ? 'Basic' : (_preAve >= 12.01 && _preAve <= 14 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Viewing Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.9) / 0.82) > 1)
            _competent++;
         else if (((_sumVal - 2.9) / 0.82) > -0.51)
            _basic++;
         else if (((_sumVal - 2.9) / 0.82) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.9) / 0.82) > 1)
                  _competent++;
               else if (((_sumVal - 2.9) / 0.82) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.9) / 0.82) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.99 ? 'Beginner' : (_postAve >= 2 && _postAve <= 3 ? 'Basic' : (_postAve >= 3.01 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.99 ? 'Beginner' : (_preAve >= 2 && _preAve <= 3 ? 'Basic' : (_preAve >= 3.01 && _preAve <= 4 ? 'Competent' : ''));
      }

      _viewingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewing Comprehension (end)      

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.47) / 0.77) > 1)
            _competent++;
         else if (((_sumVal - 3.47) / 0.77) > -0.51)
            _basic++;
         else if (((_sumVal - 3.47) / 0.77) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.47) / 0.77) > 1)
                  _competent++;
               else if (((_sumVal - 3.47) / 0.77) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.47) / 0.77) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.99 ? 'Beginner' : (_postAve >= 2 && _postAve <= 3 ? 'Basic' : (_postAve >= 3.01 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.99 ? 'Beginner' : (_preAve >= 2 && _preAve <= 3 ? 'Basic' : (_preAve >= 3.01 && _preAve <= 4 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      //-- Writing (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.88) / 1.36) > 1)
            _competent++;
         else if (((_sumVal - 2.88) / 1.36) > -0.51)
            _basic++;
         else if (((_sumVal - 2.88) / 1.36) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.88) / 1.36) > 1)
                  _competent++;
               else if (((_sumVal - 2.88) / 1.36) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.88) / 1.36) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.99 ? 'Beginner' : (_postAve >= 2 && _postAve <= 3 ? 'Basic' : (_postAve >= 3.01 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.99 ? 'Beginner' : (_preAve >= 2 && _preAve <= 3 ? 'Basic' : (_preAve >= 3.01 && _preAve <= 4 ? 'Competent' : ''));
      }

      _writing = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Writing (end)    

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 6.26) / 1.19) > 1)
            _competent++;
         else if (((_sumVal - 6.26) / 1.19) > -0.51)
            _basic++;
         else if (((_sumVal - 6.26) / 1.19) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 6.26) / 1.19) > 1)
                  _competent++;
               else if (((_sumVal - 6.26) / 1.19) > -0.51)
                  _basic++;
               else if (((_sumVal - 6.26) / 1.19) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.99 ? 'Beginner' : (_postAve >= 5 && _postAve <= 6 ? 'Basic' : (_postAve >= 6.01 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.99 ? 'Beginner' : (_preAve >= 5 && _preAve <= 6 ? 'Basic' : (_preAve >= 6.01 && _preAve <= 8 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)     

      _total = {
         vn: pre_data.length,
         prem: _vocabulary.prem + _readingComprehension.prem + _grammar.prem + _viewingComprehension.prem + _writing.prem,
         postm: _vocabulary.postm + _readingComprehension.postm + _grammar.postm + _viewingComprehension.postm + _writing.postm,
         min: _vocabulary.min + _readingComprehension.min + _grammar.min + _viewingComprehension.min + _writing.min,
         max: _vocabulary.max + _readingComprehension.max + _grammar.max + _viewingComprehension.max + _writing.max,
         sd: _vocabulary.sd + _readingComprehension.sd + _grammar.sd + _viewingComprehension.sd + _writing.sd,
         proficiency: _proficiency,
         beginner: _vocabulary.beginner + _readingComprehension.beginner + _grammar.beginner + _viewingComprehension.beginner + _writing.beginner,
         basic: _vocabulary.basic + _readingComprehension.basic + _grammar.basic + _viewingComprehension.basic + _writing.basic,
         competent: _vocabulary.competent + _readingComprehension.competent + _grammar.competent + _viewingComprehension.competent + _writing.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 25.99 ? 'Beginner' : (_total.postm >= 26 && _total.postm <= 28 ? 'Basic' : (_total.postm >= 28.01 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 25.99 ? 'Beginner' : (_total.prem >= 26 && _total.prem <= 28 ? 'Basic' : (_total.prem >= 28.01 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setViewingComprehension(_viewingComprehension);
      setVocabulary(_vocabulary);
      setReadingComprehension(_readingComprehension);
      setWriting(_writing);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _algebra = {};
      var _geometry = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Algebra (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.47) / 1.62) > 1)
            _competent++;
         else if (((_sumVal - 5.47) / 1.62) > -0.51)
            _basic++;
         else if (((_sumVal - 5.47) / 1.62) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.47) / 1.62) > 1)
                  _competent++;
               else if (((_sumVal - 5.47) / 1.62) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.47) / 1.62) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 16.9 ? 'Beginner' : (_postAve >= 16.91 && _postAve <= 20.2 ? 'Basic' : (_postAve >= 20.21 && _postAve <= 34 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 16.9 ? 'Beginner' : (_preAve >= 16.91 && _preAve <= 20.2 ? 'Basic' : (_preAve >= 20.21 && _preAve <= 34 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //--Algebra (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.47) / 1.62) > 1)
            _competent++;
         else if (((_sumVal - 5.47) / 1.62) > -0.51)
            _basic++;
         else if (((_sumVal - 5.47) / 1.62) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.47) / 1.62) > 1)
                  _competent++;
               else if (((_sumVal - 5.47) / 1.62) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.47) / 1.62) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.99 ? 'Beginner' : (_postAve >= 5 && _postAve <= 7.25 ? 'Basic' : (_postAve >= 7.26 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.99 ? 'Beginner' : (_preAve >= 5 && _preAve <= 7.25 ? 'Basic' : (_preAve >= 7.26 && _preAve <= 12 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Statistics (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 +
            pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8.19) / 2.17) > 1)
            _competent++;
         else if (((_sumVal - 8.19) / 2.17) > -0.51)
            _basic++;
         else if (((_sumVal - 8.19) / 2.17) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 +
               post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8.19) / 2.17) > 1)
                  _competent++;
               else if (((_sumVal - 8.19) / 2.17) > -0.51)
                  _basic++;
               else if (((_sumVal - 8.19) / 2.17) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.99 ? 'Beginner' : (_postAve >= 7 && _postAve <= 10 ? 'Basic' : (_postAve >= 10.01 && _postAve <= 16 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.99 ? 'Beginner' : (_preAve >= 7 && _preAve <= 10 ? 'Basic' : (_preAve >= 10.01 && _preAve <= 16 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics (end)

      _total = {
         vn: pre_data.length,
         prem: _geometry.prem + _algebra.prem + _statistics.prem,
         postm: _geometry.postm + _algebra.postm + _statistics.postm,
         min: _geometry.min + _algebra.min + _statistics.min,
         max: _geometry.max + _algebra.max + _statistics.max,
         sd: _geometry.sd + _algebra.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _geometry.beginner + _algebra.beginner + _statistics.beginner,
         basic: _geometry.basic + _algebra.basic + _statistics.basic,
         competent: _geometry.competent + _algebra.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 25.99 ? 'Beginner' : (_total.postm >= 26 && _total.postm <= 28 ? 'Basic' : (_total.postm >= 28.01 && _total.postm <= 45 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 25.99 ? 'Beginner' : (_total.prem >= 26 && _total.prem <= 28 ? 'Basic' : (_total.prem >= 28.01 && _total.prem <= 45 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;


      var _earthandspace = {};
      var _forceandmotion = {};
      var _livingthings = {};
      var _matter = {};

      var _proficiency = '';
      var _total = 0;

      //-- Earth and Space(start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.59) / 1.41) > 1)
            _competent++;
         else if (((_sumVal - 4.59) / 1.41) > -0.51)
            _basic++;
         else if (((_sumVal - 4.59) / 1.41) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.59) / 1.41) > 1)
                  _competent++;
               else if (((_sumVal - 4.59) / 1.41) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.59) / 1.41) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.99 ? 'Beginner' : (_postAve >= 4 && _postAve <= 5 ? 'Basic' : (_postAve >= 5.01 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.99 ? 'Beginner' : (_preAve >= 4 && _preAve <= 5 ? 'Basic' : (_preAve >= 5.01 && _preAve <= 8 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.81) / 0.99) > 1)
            _competent++;
         else if (((_sumVal - 2.81) / 0.99) > -0.51)
            _basic++;
         else if (((_sumVal - 2.81) / 0.99) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.81) / 0.99) > 1)
                  _competent++;
               else if (((_sumVal - 2.81) / 0.99) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.81) / 0.99) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.99 ? 'Beginner' : (_postAve >= 2 && _postAve <= 3 ? 'Basic' : (_postAve >= 3.01 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.99 ? 'Beginner' : (_preAve >= 2 && _preAve <= 3 ? 'Basic' : (_preAve >= 3.01 && _preAve <= 8 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Living Things (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 +
            pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.38) / 1.82) > 1)
            _competent++;
         else if (((_sumVal - 7.38) / 1.82) > -0.51)
            _basic++;
         else if (((_sumVal - 7.38) / 1.82) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 +
               post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.38) / 1.82) > 1)
                  _competent++;
               else if (((_sumVal - 7.38) / 1.82) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.38) / 1.82) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 5.99 ? 'Beginner' : (_postAve >= 6 && _postAve <= 7 ? 'Basic' : (_postAve >= 7.01 && _postAve <= 15 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 5.99 ? 'Beginner' : (_preAve >= 6 && _preAve <= 7 ? 'Basic' : (_preAve >= 7.01 && _preAve <= 15 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things (end)

      //-- Matter (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 +
            pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.52) / 2.63) > 1)
            _competent++;
         else if (((_sumVal - 7.52) / 2.63) > -0.51)
            _basic++;
         else if (((_sumVal - 7.52) / 2.63) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 +
               post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.52) / 2.63) > 1)
                  _competent++;
               else if (((_sumVal - 7.52) / 2.63) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.52) / 2.63) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 5.99 ? 'Beginner' : (_postAve >= 6 && _postAve <= 8 ? 'Basic' : (_postAve >= 8.01 && _postAve <= 14 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 5.99 ? 'Beginner' : (_preAve >= 6 && _preAve <= 8 ? 'Basic' : (_preAve >= 8.01 && _preAve <= 14 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)      

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 19.99 ? 'Beginner' : (_total.postm >= 20 && _total.postm <= 23 ? 'Basic' : (_total.postm >= 23.01 && _total.postm <= 45 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 19.99 ? 'Beginner' : (_total.prem >= 20 && _total.prem <= 23 ? 'Basic' : (_total.prem >= 23.01 && _total.prem <= 45 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="28%" nowrap style={{ width: '28.7%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.26%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="15%" nowrap style={{ width: '15.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Pretest Mean</span></b></p>
                                    </td>
                                    <td width="15%" style={{ width: '15.58%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Post Test Mean</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.22%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Min</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Max</span></b></p>
                                    </td>
                                    <td width="4%" nowrap style={{ width: '4.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="17%" style={{ width: '17.16%', border: 'solid black 1.0pt', borderLeft: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Reading Comprehension', readingComprehension, false)}
                                       {generateMeanLevelRow('Viewing Comprehension', viewingComprehension, false)}
                                       {generateMeanLevelRow('Writing Composition', writing, false)}
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Patterns and Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('Statistics and Probability', statisticsAndProbability, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Earth Science', earthAndSpace, false)}
                                       {generateMeanLevelRow('Force, Motion and Energy', forceAndMotion, false)}
                                       {generateMeanLevelRow('Living Things', livingThings, false)}
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in' }}>&nbsp;</p>
                           <p style={{ marginBottom: '0in' }}><b>INTERPRETATION OF MEANS</b></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="43%" style={{ width: '43.12%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>ACHIEVEMENT</span></b></p>
                                    </td>
                                    <td width="19%" style={{ width: '19.72%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="14%" style={{ width: '14.02%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="23%" style={{ width: '23.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 25.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>26 - 28</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>28.1 - 40</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Reading Comprehension</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 10.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>11 - 12</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>12.1 - 14</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Viewing Comprehension</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 1.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>2 - 3</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>3.1 - 4</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 1.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>2 - 4</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>4.1 - 7</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Writing and Composition</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 1.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>2 - 3</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>3.1 - 4</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 4.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>5 - 6</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>6.1 - 8</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="100%" colSpan={4} style={{ width: '100.0%', border: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 25.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>26 - 28</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>28.1 - 45</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Patterns and algebra</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 12.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>13 - 14</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>14.1 - 21</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 4.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>5 - 6</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>6.1 – 8</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Statistics</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 6.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>7 - 10</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>11.1 - 16</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="100%" colSpan={4} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 19.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>20 - 23</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>34 - 45</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 3.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>4 - 5</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>5.1 – 8</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Force and motion</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 1.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>2 - 3</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>3.1 - 8</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Living things</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 5.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>6 - 7</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>7.1 - 15</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="43%" style={{ width: '43.12%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>0 - 5.99</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>6 - 8</p>
                                          </td>
                                          <td width="23%" style={{ width: '23.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>8.1 - 14</p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>
                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                 <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Viewing Comprehension', viewingComprehension, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Writing Composition', writing, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 6)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 10)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 15)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b>&nbsp;</b></p>
                                 <p style={{ marginBottom: '0in' }}><b>&nbsp;</b></p>
                                 <p style={{ marginBottom: '0in' }}><b>Percentage of Correct Answers in the English Learning Competencies</b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="59%" colSpan={2} rowSpan={2} style={{ width: '59.44%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="20%" style={{ width: '20.46%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="20%" style={{ width: '20.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="20%" style={{ width: '20.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="54%" style={{ width: '54.84%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Reading Comprehension</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>

                                       {generatePercentCorrectRow('Read closely to get the author’s purpose.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Read closely to get explicitly and implicitly stated information.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Read closely to get explicitly and implicitly stated information.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Identifying textual details that affirm or refute a claim.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Identifying textual details that affirm or refute a claim.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Draw conclusions from the set of details.', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Draw conclusions from the set of details.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Identify power struggles of characters (Marxist).', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Examine Biases.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Relevance of the selection to the historical context during which it was produced (historical).', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Distinguish facts from beliefs (fact).', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Distinguish facts from beliefs (belief).', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Point out relationships among statements.', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Point out relationships among statements.', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s17, englishPCData.post_s17, 17)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="54%" style={{ width: '54.84%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Viewing Comprehension</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine the relevance and the truthfulness of the ideas presented in the material viewed.', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Formulate predictions based on the material viewed.', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Draw generalizations and conclusions based on the materials viewed.', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Detect bias and prejudice in the material viewed.', englishPCData.pre_s21, englishPCData.post_s21, 21)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="54%" style={{ width: '54.84%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Vocabulary</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Differentiate formal from informal definitions of words.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Give technical and operational definitions (technical).', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Give technical and operational definitions (operational).', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Give expanded definitions of words.', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Give expanded definitions of words.', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Get familiar with technical terms used in research.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Get familiar with technical terms used in research.', englishPCData.pre_s28, englishPCData.post_s28, 28)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="54%" style={{ width: '54.84%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Writing and Composition</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify features of persuasive texts.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Identify parts and features of argumentative essays.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Formulate claims of fact, policy, and value.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Acknowledge sources by preparing a bibliography.', englishPCData.pre_s32, englishPCData.post_s32, 32)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="54%" style={{ width: '54.84%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Grammar</b></p>
                                          </td>
                                          <td width="20%" style={{ width: '20.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="20%" style={{ width: '20.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Use reflexive and intensive pronouns.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Use reflexive and intensive pronouns.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Use modals.', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Use modals.', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Use modals.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Observe correct grammar in making definitions.', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Use words and expressions that affirm or negate.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Use pronouns effectively.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}>&nbsp;</p>
                                 <p style={{ marginBottom: '0in' }}><b>&nbsp;</b></p>
                                 <p style={{ marginBottom: '0in' }}><b>Percentage of Correct Answers
                                    in the Math Learning Competencies</b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="62%" colSpan={2} rowSpan={2} style={{ width: '62.1%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="19%" style={{ width: '19.16%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.74%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="19%" style={{ width: '19.16%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.74%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="57%" style={{ width: '57.5%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Patterns and Algebra</b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.16%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.74%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Generates patterns.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Determines arithmetic means and nth term of an arithmetic sequence.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Illustrates a geometric sequence.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Differentiates a geometric sequence from an arithmetic sequence.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Differentiates a finite geometric sequence from an infinite geometric sequence.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Determines geometric means and nth term of a geometric sequence.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Finds the sum of the terms of a given finite or infinite geometric sequence.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Solves problems involving sequences.', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Performs division of polynomials using long division and synthetic division.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Performs division of polynomials using long division and synthetic division.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Proves the Remainder Theorem and the Factor Theorem.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Factors polynomials.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Factors polynomials.', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Factors polynomials.', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Proves Rational Root Theorem.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Illustrates polynomial equations (in a graph).', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Solves polynomial equations.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Solves polynomial equations.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Solves problems involving polynomials and polynomial equations.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Illustrates polynomial functions (in a graph).', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Solves problems involving polynomial functions.', mathPCData.pre_s21, mathPCData.post_s21, 21)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="57%" style={{ width: '57.5%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Geometry</b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.16%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.74%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Proves theorems related to chords, arcs, central angles, and inscribed angles.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Illustrates secants, tangents, segments, and sectors of a circle.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Proves theorems on secants, tangents, and segments', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Solves problems on circles.', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Illustrates secants, tangents, segments, and sectors of a circle.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Proves theorems on secants, tangents, and segments', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Determines the center and radius of a circle given its equation and vice versa.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Illustrates the center-radius form of the equation of a circle.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Solves problems involving geometric figures on the coordinate plane.', mathPCData.pre_s30, mathPCData.post_s30, 30)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="57%" style={{ width: '57.5%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Statistics and Probability</b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.16%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.74%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Illustrates the permutation of objects.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Solves problems involving permutations.', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Differentiates permutation from combination of n objects taken r at a time.', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Solves problems involving permutations.', mathPCData.pre_s34, mathPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Illustrates events, and union and intersection of events.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Solves problems involving permutations and combinations.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Finds the probability of (A U B).', mathPCData.pre_s37, mathPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Illustrates the probability of a union of two events.', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Solves problems involving probability.', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Illustrates independent events.', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Calculates a specified measure of position (e.g. 90th percentile) of a set of data.', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Illustrates the following measures of position: quartiles, deciles and percentiles', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Solves problems involving measures of position.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Interprets measures of position.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Uses appropriate measures of position and other statistical methods in analyzing and interpreting research data.', mathPCData.pre_s45, mathPCData.post_s45, 45)}

                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}>&nbsp;</p>
                                 <p style={{ marginBottom: '0in' }}><b>Percentage of Correct Answers in the Science Learning Competencies</b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="67%" colSpan={2} rowSpan={2} style={{ width: '67.56%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="16%" style={{ width: '16.48%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="15%" style={{ width: '15.96%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="16%" style={{ width: '16.48%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.96%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="62%" style={{ width: '62.96%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Earth and Space</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.48%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the distribution of active volcanoes, earthquake epicenters, and major mountain belts.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Describe the different types of plate boundaries.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Explain the different processes that occur along the plate boundaries.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Describe the internal structure of the Earth.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Describe the internal structure of the Earth.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Describe the possible causes of plate movement.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Enumerate the lines of evidence that support plate movement.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Enumerate the lines of evidence that support plate movement.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="62%" style={{ width: '62.96%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Force and Motion</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.48%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Compare the relative wavelengths of different forms of electromagnetic waves.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Cite examples of practical applications of the different regions of EM waves, such as the use of radio waves in telecommunications.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Cite examples of practical applications of the different regions of EM waves, such as the use of radio waves in telecommunications.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Explain the effects of EM radiation on living things and the environment.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Predict the qualitative characteristics (orientation, type, and magnification) of images formed by plane and curved mirrors and lenses.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Apply ray diagramming techniques in describing the characteristics and positions of images formed by lenses.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Identify ways in which the properties of mirrors and lenses determine their use in optical instruments (e.g., cameras and binoculars).', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Explain the operation of a simple electric motor and generator.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="62%" style={{ width: '62.96%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Living Things</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.48%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the parts of the reproductive system and their functions.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Describe the parts of the reproductive system and their functions.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Explain the role of hormones involved in the female and male reproductive systems.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Explain the role of hormones involved in the female and male reproductive systems.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Describe the feedback mechanisms involved in regulating processes in the female reproductive system (e.g., menstrual cycle).', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Describe how the nervous system coordinates and regulates these feedback mechanisms to maintain homeostasis.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Explain how protein is made using information from DNA.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Explain how mutations may cause changes in the structure and function of a protein.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Explain how fossil records, comparative anatomy, and genetic information provide evidence for evolution.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Explain the occurrence of evolution.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Explain how species diversity increases the probability of adaptation and survival of organisms in changing environments.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Explain the relationship between population growth and carrying capacity.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Suggest ways to minimize human impact on the environment.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Suggest ways to minimize human impact on the environment.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Suggest ways to minimize human impact on the environment.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="62%" style={{ width: '62.96%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Matter</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.48%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Investigate the relationship between:volume and pressure at constant temperature of a gas.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Investigate the relationship between:volume and pressure at constant temperature of a gas.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Investigate the relationship between:volume and pressure at constant temperature of a gas.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Investigate the relationship between: volume and temperature at constant pressure of a gas.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Investigate the relationship between: volume and temperature at constant pressure of a gas.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Investigate the relationship between: volume and temperature at constant pressure of a gas.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Explain the relationship between volume and pressure using the kinetic molecular theory.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Explain the relationship between volume and pressure using the kinetic molecular theory.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Recognize the major categories of biomolecules such as carbohydrates, lipids, proteins, and nucleic acids.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Recognize the major categories of biomolecules such as carbohydrates, lipids, proteins, and nucleic acids.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Apply the principles of conservation of mass to chemical reactions.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Apply the principles of conservation of mass to chemical reactions.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Explain how the factors affecting rates of chemical reactions are applied in food preservation and materials production, control of fire, pollution, and corrosion.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Explain how the factors affecting rates of chemical reactions are applied in food preservation and materials production, control of fire, pollution, and corrosion.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}