import React, { useEffect } from 'react';
import "./SearchServiceGoogleAPI.scss";
import { Gallery } from "react-grid-gallery";
import { SearchOutlined } from '@ant-design/icons';
import GoogleService from '../../../services/GoogleService';
import Enums from '../../../misc/Enums';
import { Input, Skeleton } from 'antd';

export default function SearchObjectsAPI(props) {
    const [searchText, setSearchText] = React.useState("");
    const [searchList, setSearchList] = React.useState([]);
    const [isLoading, setIsLoading] =  React.useState(false);


    useEffect(() => {
      setSearchList([])
      setSearchText("")
   }, [props.type]);


   const onSearchGoogle = async () =>
      {    
          setIsLoading(true)
          GoogleService.searchGoogleService("google.image", searchText).then((response) => {
              if (response?.data) {
                 var varitems = response.data.items;
                 var searchResult = [];
                    varitems.map((item) => {
                     //   var searchItem = {
                     //      type: Enums.ResourcesType.isGoogleImage, link: item.link, title: item.title,
                     //      description: item.snippet, image: item.link
                     //   }
                       var searchItem = {
                        src : item.link,
                        original : item.link,
                        width: item.image.thumbnailWidth,
                        height: item.image.thumbnailHeight,
                        caption: item.title,
                        type: Enums.ResourcesType.isGoogleImage, 
                        videoId: "1", 
                        title: item.title,
                        description: item.snippet, 
                        image: item.link                     
                     }                       
                       searchResult.push(searchItem);
                    })
                 setSearchList(searchResult)
                 setIsLoading(false)
              }
              else {
                 setIsLoading(false)
                 // No Data
              }
           }).catch((ex) => {
              setIsLoading(false)
              // message.error(ex.message)
           })
      }

   const onSearch = async () =>
   {    
      if (props.type === "image")
         await onSearchGoogle()
      else
         await onSearchPixabay()
   }

    const onSearchPixabay = async (e) =>
    {    
        setIsLoading(true)
 
        var api = ""
        const headers = {}
        const pixabayAPIKey = `${process.env.REACT_APP_PIXABAY_API}` 
         api = `https://pixabay.com/api/?key=${pixabayAPIKey}&q=`+encodeURIComponent(searchText)+`&image_type=${props.type}&pretty=true`;
         
         fetch(api)
         .then(res => res.json())
         .then((response) => {
            if (response?.hits) {
               var varitems = response.hits;
               var searchResult = [];
               varitems.map((item) => {
                  // var searchItem = {
                  //    type: Enums.ResourcesType.isGoogleImage, videoId: item.id, title: item.type,
                  //    description: item.tags, image: item.previewURL
                  // }
                  var searchItem = {
                     src : item.previewURL,
                     original : item.previewURL,
                     width: item.previewWidth,
                     height: item.previewHeight,
                     caption: item.type,
                     type: Enums.ResourcesType.isGoogleImage, 
                     videoId: item.id, 
                     title: item.type,
                     description: item.tags, 
                     image: item.previewURL                     
                  }
                  searchResult.push(searchItem);
               })
               setSearchList(searchResult)
               setIsLoading(false)
            }
            else {
               setIsLoading(false)
               // No Data
            }
         }).catch((ex) => {
            setIsLoading(false)
            // message.error(ex.message)
         })
    }

    const imageClick = (index,item) =>
      {
         props.add(item)
      }
    return(
        <>
            <div className="logo">
               <div style={{color : 'white' , fontWeight : 'bold', width : '100%', fontSize : '18px' }}>
                 {props.type === "image" ? "IMAGE " : "VECTOR IMAGE "}  SEARCH
               </div>
              {/* <img src={searchLogo}/> */}
            </div>
            <div className='container-search'>
                <div className='search-group'>
                   <Input size="large"  onChange={(e) => setSearchText(e.target.value)}  onPressEnter={onSearch}
                        value={searchText} placeholder="Search Keywords" prefix={<SearchOutlined />} style={{borderRadius : '5px'}} />
                </div>
            </div>
            {!isLoading ? <div className='result-container'>

               <Gallery
                  images={searchList}
                  onClick={imageClick} 
                  enableImageSelection={false}
                  />
              
            </div>:
            <div className='result-container'>
               <div className='image-container' > 
                <Skeleton.Image active className='image-result' style={{width : "250px" , height : "180px"}}/>
               </div>
               <div style={{ width : '250px', margin : 'auto', paddingTop : '10px'}}>
                  <div className=' author-style'  ><Skeleton.Input active style={{width : "250px" }}/></div>
                  <div className=' description-style' ><Skeleton.Input active  style={{width : "250px" }}/></div>    
               </div>           
            </div>
            }
        </>
    )
}
