/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function KinderSummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [auditory, setAuditory] = useState(null);
   const [oralLanguage, setOralLanguage] = useState(null);
   const [alphabetKnowledge, setAlphabetKnowledge] = useState(null);
   const [studySkills, setStudySkills] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [sorting, setSorting] = useState(null);
   const [counting, setCounting] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [dataAnalysis, setDataAnalysis] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [livingThings, setLivingThings] = useState(null);
   const [physicalScience, setPhysicalScience] = useState(null);
   const [earthScience, setEarthScience] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   const [dataLoading, setDataLoading] = useState(false);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {
               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _auditory = {};
      var _oralLanguage = {};
      var _alphabetKnowledge = {};
      var _studySkills = {};
      var _vocabulary = {};
      var _total = {};
      var _proficiency = '';

      //-- Auditory (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.17) / 0.99) > 1)
            _competent++;
         else if (((_sumVal - 4.17) / 0.99) > -0.51)
            _basic++;
         else if (((_sumVal - 4.17) / 0.99) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.17) / 0.99) > 1)
                  _competent++;
               else if (((_sumVal - 4.17) / 0.99) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.17) / 0.99) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.67 ? 'Beginner' : (_postAve >= 3.68 && _postAve <= 5.16 ? 'Basic' : (_postAve >= 5.17 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.67 ? 'Beginner' : (_preAve >= 3.68 && _preAve <= 5.16 ? 'Basic' : (_preAve >= 5.17 && _preAve <= 6 ? 'Competent' : ''));
      }

      _auditory = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Auditory (end)      

      //-- Oral Language (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s7 + pre_data[i].s8;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.44) / 0.42) > 1)
            _competent++;
         else if (((_sumVal - 1.44) / 0.42) > -0.51)
            _basic++;
         else if (((_sumVal - 1.44) / 0.42) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s7 + post_data[i].s8;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.44) / 0.42) > 1)
                  _competent++;
               else if (((_sumVal - 1.44) / 0.42) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.44) / 0.42) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.22 ? 'Beginner' : (_postAve >= 1.23 && _postAve <= 1.86 ? 'Basic' : (_postAve >= 1.87 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.22 ? 'Beginner' : (_preAve >= 1.23 && _preAve <= 1.86 ? 'Basic' : (_preAve >= 1.87 && _preAve <= 2 ? 'Competent' : ''));
      }

      _oralLanguage = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Oral Language (end)

      //-- Alphabet Knowledge (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.67) / 1.37) > 1)
            _competent++;
         else if (((_sumVal - 3.67) / 1.37) > -0.51)
            _basic++;
         else if (((_sumVal - 3.67) / 1.37) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.67) / 1.37) > 1)
                  _competent++;
               else if (((_sumVal - 3.67) / 1.37) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.67) / 1.37) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.98 ? 'Beginner' : (_postAve >= 2.99 && _postAve <= 5.04 ? 'Basic' : (_postAve >= 5.05 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.98 ? 'Beginner' : (_preAve >= 2.99 && _preAve <= 5.04 ? 'Basic' : (_preAve >= 5.05 && _preAve <= 6 ? 'Competent' : ''));
      }

      _alphabetKnowledge = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Alphabet Knowledge (end)

      //-- Study Skills (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s15 + pre_data[i].s16;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 0.92) / 0.43) > 1)
            _competent++;
         else if (((_sumVal - 0.92) / 0.43) > -0.51)
            _basic++;
         else if (((_sumVal - 0.92) / 0.43) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s15 + post_data[i].s16;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 0.92) / 0.43) > 1)
                  _competent++;
               else if (((_sumVal - 0.92) / 0.43) > -0.51)
                  _basic++;
               else if (((_sumVal - 0.92) / 0.43) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.7 ? 'Beginner' : (_postAve >= 0.71 && _postAve <= 1.35 ? 'Basic' : (_postAve >= 1.36 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.7 ? 'Beginner' : (_preAve >= 0.71 && _preAve <= 1.35 ? 'Basic' : (_preAve >= 1.36 && _preAve <= 2 ? 'Competent' : ''));
      }

      _studySkills = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Study Skills (end)

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10.06) / 1.7) > 1)
            _competent++;
         else if (((_sumVal - 10.06) / 1.7) > -0.51)
            _basic++;
         else if (((_sumVal - 10.06) / 1.7) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10.06) / 1.7) > 1)
                  _competent++;
               else if (((_sumVal - 10.06) / 1.7) > -0.51)
                  _basic++;
               else if (((_sumVal - 10.06) / 1.7) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 9.2 ? 'Beginner' : (_postAve >= 9.21 && _postAve <= 11.8 ? 'Basic' : (_postAve >= 11.81 && _postAve <= 14 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 9.2 ? 'Beginner' : (_preAve >= 9.21 && _preAve <= 11.8 ? 'Basic' : (_preAve >= 11.81 && _preAve <= 14 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      _total = {
         vn: pre_data.length,
         prem: _auditory.prem + _oralLanguage.prem + _alphabetKnowledge.prem + _studySkills.prem + _vocabulary.prem,
         postm: _auditory.postm + _oralLanguage.postm + _alphabetKnowledge.postm + _studySkills.postm + _vocabulary.postm,
         min: _auditory.min + _oralLanguage.min + _alphabetKnowledge.min + _studySkills.min + _vocabulary.min,
         max: _auditory.max + _oralLanguage.max + _alphabetKnowledge.max + _studySkills.max + _vocabulary.max,
         sd: _auditory.sd + _oralLanguage.sd + _alphabetKnowledge.sd + _studySkills.sd + _vocabulary.sd,
         proficiency: _proficiency,
         beginner: _auditory.beginner + _oralLanguage.beginner + _alphabetKnowledge.beginner + _studySkills.beginner + _vocabulary.beginner,
         basic: _auditory.basic + _oralLanguage.basic + _alphabetKnowledge.basic + _studySkills.basic + _vocabulary.basic,
         competent: _auditory.competent + _oralLanguage.competent + _alphabetKnowledge.competent + _studySkills.competent + _vocabulary.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 16.1 ? 'Beginner' : (_total.postm >= 16.11 && _total.postm <= 21.2 ? 'Basic' : (_total.postm >= 21.21 && _total.postm <= 30 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 16.1 ? 'Beginner' : (_total.prem >= 16.11 && _total.prem <= 21.2 ? 'Basic' : (_total.prem >= 21.21 && _total.prem <= 30 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setAuditory(_auditory);
      setOralLanguage(_oralLanguage);
      setAlphabetKnowledge(_alphabetKnowledge);
      setStudySkills(_studySkills);
      setVocabulary(_vocabulary);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _sorting = {};
      var _counting = {};
      var _algebra = {};
      var _measurement = {};
      var _dataAnalysis = {};
      var _total = 0;
      var _proficiency = '';

      //-- Sorting (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.89) / 0.32) > 1)
            _competent++;
         else if (((_sumVal - 3.89) / 0.32) > -0.51)
            _basic++;
         else if (((_sumVal - 3.89) / 0.32) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.89) / 0.32) > 1)
                  _competent++;
               else if (((_sumVal - 3.89) / 0.32) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.89) / 0.32) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.72 ? 'Beginner' : (_postAve >= 3.73 && _postAve <= 4.21 ? 'Basic' : (_postAve >= 4.22 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.72 ? 'Beginner' : (_preAve >= 3.73 && _preAve <= 4.21 ? 'Basic' : (_preAve >= 4.22 && _preAve <= 5 ? 'Competent' : ''));
      }

      _sorting = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Sorting (end)

      //-- Counting (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 6.33) / 1.24) > 1)
            _competent++;
         else if (((_sumVal - 6.33) / 1.24) > -0.51)
            _basic++;
         else if (((_sumVal - 6.33) / 1.24) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 6.33) / 1.24) > 1)
                  _competent++;
               else if (((_sumVal - 6.33) / 1.24) > -0.51)
                  _basic++;
               else if (((_sumVal - 6.33) / 1.24) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 5.7 ? 'Beginner' : (_postAve >= 5.71 && _postAve <= 7.57 ? 'Basic' : (_postAve >= 7.58 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 5.7 ? 'Beginner' : (_preAve >= 5.71 && _preAve <= 7.57 ? 'Basic' : (_preAve >= 7.58 && _preAve <= 8 ? 'Competent' : ''));
      }

      _counting = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Counting (end)

      //-- Patterns and Algebra (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 +
            pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.28) / 1.49) > 1)
            _competent++;
         else if (((_sumVal - 9.28) / 1.49) > -0.51)
            _basic++;
         else if (((_sumVal - 9.28) / 1.49) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 +
               post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.28) / 1.49) > 1)
                  _competent++;
               else if (((_sumVal - 9.28) / 1.49) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.28) / 1.49) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8.53 ? 'Beginner' : (_postAve >= 8.54 && _postAve <= 10.8 ? 'Basic' : (_postAve >= 10.81 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8.53 ? 'Beginner' : (_preAve >= 8.54 && _preAve <= 10.8 ? 'Basic' : (_preAve >= 10.81 && _preAve <= 12 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Patterns and Algebra (end)

      //-- Measurement (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.5) / 0.51) > 1)
            _competent++;
         else if (((_sumVal - 2.5) / 0.51) > -0.51)
            _basic++;
         else if (((_sumVal - 2.5) / 0.51) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s26 + post_data[i].s27 + post_data[i].s28;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.5) / 0.51) > 1)
                  _competent++;
               else if (((_sumVal - 2.5) / 0.51) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.5) / 0.51) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.24 ? 'Beginner' : (_postAve >= 2.25 && _postAve <= 3.01 ? 'Basic' : (_postAve >= 3.02 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.24 ? 'Beginner' : (_preAve >= 2.25 && _preAve <= 3.01 ? 'Basic' : (_preAve >= 3.02 && _preAve <= 3 ? 'Competent' : ''));
      }

      _measurement = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Data Analysis (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s29 + pre_data[i].s30;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 0.89) / 0.78) > 1)
            _competent++;
         else if (((_sumVal - 0.89) / 0.78) > -0.51)
            _basic++;
         else if (((_sumVal - 0.89) / 0.78) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s29 + post_data[i].s30;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 0.89) / 0.78) > 1)
                  _competent++;
               else if (((_sumVal - 0.89) / 0.78) > -0.51)
                  _basic++;
               else if (((_sumVal - 0.89) / 0.78) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.49 ? 'Beginner' : (_postAve >= 0.50 && _postAve <= 1.67 ? 'Basic' : (_postAve >= 1.68 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.49 ? 'Beginner' : (_preAve >= 0.50 && _preAve <= 1.67 ? 'Basic' : (_preAve >= 1.68 && _preAve <= 2 ? 'Competent' : ''));
      }

      _dataAnalysis = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Data Analysis (end)

      _total = {
         vn: pre_data.length,
         prem: _sorting.prem + _counting.prem + _algebra.prem + _measurement.prem + _dataAnalysis.prem,
         postm: _sorting.postm + _counting.postm + _algebra.postm + _measurement.postm + _dataAnalysis.postm,
         min: _sorting.min + _counting.min + _algebra.min + _measurement.min + _dataAnalysis.min,
         max: _sorting.max + _counting.max + _algebra.max + _measurement.max + _dataAnalysis.max,
         sd: _sorting.sd + _counting.sd + _algebra.sd + _measurement.sd + _dataAnalysis.sd,
         proficiency: _proficiency,
         beginner: _sorting.beginner + _counting.beginner + _algebra.beginner + _measurement.beginner + _dataAnalysis.beginner,
         basic: _sorting.basic + _counting.basic + _algebra.basic + _measurement.basic + _dataAnalysis.basic,
         competent: _sorting.competent + _counting.competent + _algebra.competent + _measurement.competent + _dataAnalysis.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 18.9 ? 'Beginner' : (_total.postm >= 18.91 && _total.postm <= 23.36 ? 'Basic' : (_total.postm >= 23.37 && _total.postm <= 30 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 18.9 ? 'Beginner' : (_total.prem >= 18.91 && _total.prem <= 23.36 ? 'Basic' : (_total.prem >= 23.37 && _total.prem <= 30 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setSorting(_sorting);
      setCounting(_counting);
      setPatternsAndAlgebra(_algebra);
      setMeasurement(_measurement);
      setDataAnalysis(_dataAnalysis);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _livingthings = {};
      var _physicalScience = {};
      var _earthscience = {};
      var _proficiency = '';
      var _total = 0;

      //-- Living Things and their Environment (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10.17) / 2.41) > 1)
            _competent++;
         else if (((_sumVal - 10.17) / 2.41) > -0.51)
            _basic++;
         else if (((_sumVal - 10.17) / 2.41) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10.17) / 2.41) > 1)
                  _competent++;
               else if (((_sumVal - 10.17) / 2.41) > -0.51)
                  _basic++;
               else if (((_sumVal - 10.17) / 2.41) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8.96 ? 'Beginner' : (_postAve >= 8.97 && _postAve <= 12.58 ? 'Basic' : (_postAve >= 12.59 && _postAve <= 18 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8.96 ? 'Beginner' : (_preAve >= 8.97 && _preAve <= 12.58 ? 'Basic' : (_preAve >= 12.59 && _preAve <= 18 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)

      //-- Physical Science (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 +
            pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.06) / 0.94) > 1)
            _competent++;
         else if (((_sumVal - 2.06) / 0.94) > -0.51)
            _basic++;
         else if (((_sumVal - 2.06) / 0.94) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 +
               post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.06) / 0.94) > 1)
                  _competent++;
               else if (((_sumVal - 2.06) / 0.94) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.06) / 0.94) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.58 ? 'Beginner' : (_postAve >= 1.59 && _postAve <= 3 ? 'Basic' : (_postAve >= 3.01 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.58 ? 'Beginner' : (_preAve >= 1.59 && _preAve <= 3 ? 'Basic' : (_preAve >= 3.01 && _preAve <= 4 ? 'Competent' : ''));
      }

      _physicalScience = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Physical Science (end)

      //-- Earth Science (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.05) / 0.71) > 1)
            _competent++;
         else if (((_sumVal - 2.05) / 0.71) > -0.51)
            _basic++;
         else if (((_sumVal - 2.05) / 0.71) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s23 + post_data[i].s24 + post_data[i].s25;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.05) / 0.71) > 1)
                  _competent++;
               else if (((_sumVal - 2.05) / 0.71) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.05) / 0.71) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.69 ? 'Beginner' : (_postAve >= 1.70 && _postAve <= 2.76 ? 'Basic' : (_postAve >= 2.77 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.69 ? 'Beginner' : (_preAve >= 1.70 && _preAve <= 2.76 ? 'Basic' : (_preAve >= 2.77 && _preAve <= 3 ? 'Competent' : ''));
      }

      _earthscience = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth Science (end)

      _total = {
         vn: pre_data.length,
         prem: _livingthings.prem + _physicalScience.prem + _earthscience.prem,
         postm: _livingthings.postm + _physicalScience.postm + _earthscience.postm,
         min: _livingthings.min + _physicalScience.min + _earthscience.min,
         max: _livingthings.max + _physicalScience.max + _earthscience.max,
         sd: _livingthings.sd + _physicalScience.sd + _earthscience.sd,
         proficiency: _proficiency,
         beginner: _livingthings.beginner + _physicalScience.beginner + _earthscience.beginner,
         basic: _livingthings.basic + _physicalScience.basic + _earthscience.basic,
         competent: _livingthings.competent + _physicalScience.competent + _earthscience.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 18.3 ? 'Beginner' : (_total.postm >= 18.31 && _total.postm <= 22.5 ? 'Basic' : (_total.postm >= 22.49 && _total.postm <= 25 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 18.3 ? 'Beginner' : (_total.prem >= 18.31 && _total.prem <= 22.5 ? 'Basic' : (_total.prem >= 22.49 && _total.prem <= 25 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setLivingThings(_livingthings);
      setPhysicalScience(_earthscience);
      setEarthScience(_earthscience);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '31.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '4.8%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>N</span></strong></p>
                                    </td>
                                    <td style={{ width: '12.52%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRE TEST MEAN</span></strong></p>
                                    </td>
                                    <td style={{ width: '13.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST MEAN</span></strong></p>
                                    </td>
                                    <td style={{ width: '7.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>MIN</span></strong></p>
                                    </td>
                                    <td style={{ width: '8.92%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>MAX</span></strong></p>
                                    </td>
                                    <td style={{ width: '6.52%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>SD</span></strong></p>
                                    </td>
                                    <td style={{ width: '14.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PROFICIENCY LEVEL</span></strong></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Auditory Perception and Discrimination', auditory, false)}
                                       {generateMeanLevelRow('Oral Language', oralLanguage, false)}
                                       {generateMeanLevelRow('Alphabet Knowledge', alphabetKnowledge, false)}
                                       {generateMeanLevelRow('Study Skills', studySkills, false)}
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }

                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Sorting and Classifying', sorting, false)}
                                       {generateMeanLevelRow('Counting', counting, false)}
                                       {generateMeanLevelRow('Patterns and Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Measurement', measurement, false)}
                                       {generateMeanLevelRow('Probability', dataAnalysis, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }

                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Living Things', livingThings, false)}
                                       {generateMeanLevelRow('Physica Science', physicalScience, false)}
                                       {generateMeanLevelRow('Earth Science', earthScience, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'justify' }}><strong>INTERPRETATION</strong></p>
                           <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>BEGINNER</span></strong></p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>BASIC</span></strong></p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>COMPETENT</span></strong></p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Reading Comprehension</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7.07</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7.08</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.8</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.81</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>13</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Oral Language</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.27</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.28</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.80</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.81</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Book and Print Knowledge</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.99</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.50</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.51</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Alphabet Knowledge</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.50</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.51</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.70</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.71</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Grammar</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.3</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10.31</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.72</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.73</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Vocabulary</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.83</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.84</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>9.78</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>9.79</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>ENGLISH</strong></p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16.91</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>16.92</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>25.7</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>25.67</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>45</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td colSpan={12} style={{ borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Number Sense</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.20</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.21</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.51</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.52</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>29</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Geometry</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.68</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.69</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.16</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.17</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Algebra</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.95</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0.96</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.84</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>1.85</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Measurement</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.12</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.13</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.21</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>6.22</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>10</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Statistics</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2.11</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>2.12</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.16</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.17</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>MATH</strong></p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.6</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>18.60</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>27.75</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>27.76</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>50</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td colSpan={12} style={{ borderStyle: 'solid', borderWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Matter</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.09</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.1</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.00</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.01</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Living Things and their Environment</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>8</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>8.01</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.88</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>11.89</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>19</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Force and Motion</p>
                                    </td>
                                    <td style={{ width: '4.06%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.14</p>
                                    </td>
                                    <td style={{ width: '2.72%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>4.15</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.96</p>
                                    </td>
                                    <td style={{ width: '2.74%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.97</p>
                                    </td>
                                    <td style={{ width: '3.5%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; Earth and Space</p>
                                    </td>
                                    <td style={{ width: '4.06%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.56</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>3.57</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.17</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>5.18</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>7</p>
                                    </td>
                                 </tr>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '43.32%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>SCIENCE</strong></p>
                                    </td>
                                    <td style={{ width: '4.06%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>0</p>
                                    </td>
                                    <td style={{ width: '3.46%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>20.11</p>
                                    </td>
                                    <td style={{ width: '2.72%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>20.12</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '7.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>24.5</p>
                                    </td>
                                    <td style={{ width: '2.74%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '8.84%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>24.54</p>
                                    </td>
                                    <td style={{ width: '3.5%', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>-</p>
                                    </td>
                                    <td style={{ width: '5.36%', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>40</p>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Auditory Perception and Discrimination', auditory, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Oral Language', oralLanguage, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Alphabet Knowledge', alphabetKnowledge, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Study Skills', studySkills, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 6)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Sorting and Classifying', sorting, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Counting', counting, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Measurement', measurement, 10)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Probability', dataAnalysis, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 12)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 15)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Physical Science', physicalScience, 16)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth science', earthScience, 17)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 18)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for English</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '17%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '16.48%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Auditory Perception and Discrimination</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify familiar sounds in the environment.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Identify familiar sounds in the environment.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Tell who/what is producing a given sound.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Tell who/what is producing a given sound.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Tell if the sound is loud/soft, high/low.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Tell if the sound is loud/soft, high/low.', englishPCData.pre_s6, englishPCData.post_s6, 6)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Oral Language</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Use polite greetings and courteous expressions in appropriate situations 1.1 Good Morning/Afternoon 1.2 Thank You/You’re Welcome 1.3 Excuse Me/I’m Sorry 1.4 Please…./May I…..', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Use polite greetings and courteous expressions in appropriate situations 1.1 Good Morning/Afternoon 1.2 Thank You/You’re Welcome 1.3 Excuse Me/I’m Sorry 1.4 Please…./May I…..', englishPCData.pre_s8, englishPCData.post_s8, 8)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Alphabet Knowledge</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Alphabet (mother tongue, orthography).', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Alphabet (mother tongue, orthography).', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Give the sound of each letter (mother tongue, orthography).', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Give the sound of each letter (mother tongue, orthography).', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow("Name the beginning letters of their friends', names, their family members', and common things they use.", englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow("Name the beginning letters of their friends', names, their family members', and common things they use.", englishPCData.pre_s14, englishPCData.post_s14, 14)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Study Skills</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Name the places and the things found in a map of a classroom.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Name the places and the things found in a map of a classroom.', englishPCData.pre_s16, englishPCData.post_s16, 16)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '61.92%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Vocabulary Development</strong></p>
                                          </td>
                                          <td style={{ width: '17%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '16.48%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Name common objects/things in the environment (in school, home, and community).', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Name common objects/things in the environment (in school, home, and community).', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Describe common objects/things in the environment based on color, shape, size, and function/use.', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Describe common objects/things in the environment based on color, shape, size, and function/use.', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Give the meaning of words presented through real objects, pictures, actions, synonyms and antonyms, and context clues.', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Give the meaning of words presented through real objects, pictures, actions, synonyms and antonyms, and context clues.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Name objects that begin with a particular letter of the alphabet.', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Name objects that begin with a particular letter of the alphabet.', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Give the names of family members, school personnel, and community helpers, and the roles they play/ jobs they do/things they use.', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Give the names of family members, school personnel, and community helpers, and the roles they play/ jobs they do/things they use.', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Name the places and the things found in the classroom, school and community.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Name the places and the things found in the classroom, school and community.', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Give the synonyms and antonyms of given words.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Give the synonyms and antonyms of given words.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                    </tbody>
                                 </table>
                              </>
                           }

                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for Mathematics</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '14.78%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '15.36%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '14.78%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Sorting and Classifying</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Recognize simple shapes in the environment.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Identify two-dimensional shapes.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Identify three dimensional shapes.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Describe objects according to.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Group objects that are alike.', mathPCData.pre_s5, mathPCData.post_s5, 5)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Counting</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Copy, make and continue patterns.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Count forward and backward from memory up to 10.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Recognize and identify numeral0 to 10.', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Read and write numeral 0 to 10.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Match numerals to a set of concrete objects from 0 to 10.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Identify the number that comes before, after or in-between.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Arrange three numbers from least to greatest/ greatest to least.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Match objects/pictures using one-to-one correspondence.', mathPCData.pre_s13, mathPCData.post_s13, 13)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Patterns and Algebra</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Recognize and identify coins and bills up to PhP20 (pesos and centavos).', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Recognize the words “put together,” “add to.” and “in all”, that indicate the act of adding whole numbers.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Recognizes the words “take away,” “less.” and “are left”, that indicate the act of subtracting whole numbers.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Represent the concept of addition by combining elements of two sets using concrete objects.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Represent the concept of subtraction by crossing out/taking away element from a set using concrete objects.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Use manipulative to explore the concept of addition and subtraction with sums and differences between 0 and 10.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Add quantities up to 10 using concrete objects.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Subtract quantities up to 10 using concrete objects.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Match addition and subtraction expressions with concrete representations.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Write addition and subtraction expressions and equation with concrete representations.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Recognize situations that require addition and subtraction.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Compare objects based on their size, length, weight, mass.', mathPCData.pre_s25, mathPCData.post_s25, 25)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Measurement</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Tell the time of day when activities are being done, E.g. morning, afternoon, night time.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Compare time intervals by determining which activities take a longer or shorter time.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Collect data on one variable (e.g. sex or boys and girls) through observation and asking a question.', mathPCData.pre_s28, mathPCData.post_s28, 28)}

                                       <tr style={{ height: '14.7pt' }}>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '65.26%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Data Analysis and Probability</strong></p>
                                          </td>
                                          <td style={{ width: '15.36%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '14.78%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Organize data into pictographs.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Interpret pictographs.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                    </tbody>
                                 </table>
                              </>
                           }

                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>Percent Correct for Science</strong></p>
                                 <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                       <tr>
                                          <td colSpan={2} rowSpan={2} style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>PRETEST</span></strong></p>
                                          </td>
                                          <td style={{ width: '17.12%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>POST TEST</span></strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '17.62%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                          <td style={{ width: '17.12%', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Percent Correct for the Level</strong></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Living Things</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify one’s basic body parts.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Tell the function of each basic body part.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Demonstrate movements using different body parts.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Name the five senses and their corresponding body parts.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Use the senses to observe the environment.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Use the senses to observe and perform simple experiments in classifying objects.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Identify one’s basic needs and ways to care for one’s body.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Practice ways to care for one’s body.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Physical Science</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe how one grows and changes.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Name common animals.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Observe, describe, and examine common animals using their senses.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Group animals according to certain characteristics.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Identify the needs of animals.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Identify ways to care for animals.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Identify and describe how animals can be useful.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Name common plants.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Communicate changes observed.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Group plants according to certain characteristics.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Classify objects according to observable properties like size, color, shape, texture, and weight.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Identify needs of plants and ways to care for plants.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Identify and describe how plants can be useful.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Explores how objects can be moved like pushing, pulling, rising, sinking, blowing.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}

                                       <tr>
                                          <td style={{ width: '4.6%', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                          </td>
                                          <td style={{ width: '60.66%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Earth Science</strong></p>
                                          </td>
                                          <td style={{ width: '17.62%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                          <td style={{ width: '17.12%', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                             <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify what we wear and use for each kind of weather.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Identify simple ways of taking care of the environment.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Explore simple cause-and-effect relationships in familiar events and situations.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}