/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade5SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [vocabulary, setVocabulary] = useState(null);
   const [readingComprehension, setReadingComprehension] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [numberSense, setNumberSense] = useState(null);
   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _grammar = {};
      var _viewingcomprehesion = {};
      var _vocabulary = {};
      var _total = {};
      var _proficiency = '';

      //-- Vocabulary (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.87) / 1.29) > 1)
            _competent++;
         else if (((_sumVal - 4.87) / 1.29) > -0.51)
            _basic++;
         else if (((_sumVal - 4.87) / 1.29) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.87) / 1.29) > 1)
                  _competent++;
               else if (((_sumVal - 4.87) / 1.29) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.87) / 1.29) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.22 ? 'Beginner' : (_postAve >= 4.23 && _postAve <= 6.16 ? 'Basic' : (_postAve >= 6.17 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.22 ? 'Beginner' : (_preAve >= 4.23 && _preAve <= 6.16 ? 'Basic' : (_preAve >= 6.17 && _preAve <= 8 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)      

      //-- Reading Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 +
            pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 +
            pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10.74) / 1.84) > 1)
            _competent++;
         else if (((_sumVal - 10.74) / 1.84) > -0.51)
            _basic++;
         else if (((_sumVal - 10.74) / 1.84) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 +
               post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 +
               post_data[i].s24 + post_data[i].s25 + post_data[i].s26;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10.74) / 1.84) > 1)
                  _competent++;
               else if (((_sumVal - 10.74) / 1.84) > -0.51)
                  _basic++;
               else if (((_sumVal - 10.74) / 1.84) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 9.81 ? 'Beginner' : (_postAve >= 9.82 && _postAve <= 12.6 ? 'Basic' : (_postAve >= 12.61 && _postAve <= 18 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 9.81 ? 'Beginner' : (_preAve >= 9.82 && _preAve <= 12.6 ? 'Basic' : (_preAve >= 12.61 && _preAve <= 18 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 +
            pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 +
            pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 16.57) / 5.62) > 1)
            _competent++;
         else if (((_sumVal - 16.57) / 5.62) > -0.51)
            _basic++;
         else if (((_sumVal - 16.57) / 5.62) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 +
               post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 +
               post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 16.57) / 5.62) > 1)
                  _competent++;
               else if (((_sumVal - 16.57) / 5.62) > -0.51)
                  _basic++;
               else if (((_sumVal - 16.57) / 5.62) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 13.8 ? 'Beginner' : (_postAve >= 13.81 && _postAve <= 22.2 ? 'Basic' : (_postAve >= 22.21 && _postAve <= 29 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 13.8 ? 'Beginner' : (_preAve >= 13.81 && _preAve <= 22.2 ? 'Basic' : (_preAve >= 22.21 && _preAve <= 29 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)

      //-- Viewwing Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.78) / 1.24) > 1)
            _competent++;
         else if (((_sumVal - 1.78) / 1.24) > -0.51)
            _basic++;
         else if (((_sumVal - 1.78) / 1.24) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.78) / 1.24) > 1)
                  _competent++;
               else if (((_sumVal - 1.78) / 1.24) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.78) / 1.24) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.15 ? 'Beginner' : (_postAve >= 1.16 && _postAve <= 3.02 ? 'Basic' : (_postAve >= 3.03 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.15 ? 'Beginner' : (_preAve >= 1.16 && _preAve <= 3.02 ? 'Basic' : (_preAve >= 3.03 && _preAve <= 5 ? 'Competent' : ''));
      }

      _viewingcomprehesion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewwing Comprehension (end)

      _total = {
         vn: pre_data.length,
         prem: _readingComprehension.prem + _viewingcomprehesion.prem + _grammar.prem + _vocabulary.prem,
         postm: _readingComprehension.postm + _viewingcomprehesion.postm + _grammar.postm + _vocabulary.postm,
         min: _readingComprehension.min + _viewingcomprehesion.min + _grammar.min + _vocabulary.min,
         max: _readingComprehension.max + _viewingcomprehesion.max + _grammar.max + _vocabulary.max,
         sd: _readingComprehension.sd + _viewingcomprehesion.sd + _grammar.sd + _vocabulary.sd,
         proficiency: _proficiency,
         beginner: _readingComprehension.beginner + _viewingcomprehesion.beginner + _grammar.beginner + _vocabulary.beginner,
         basic: _readingComprehension.basic + _viewingcomprehesion.basic + _grammar.basic + _vocabulary.basic,
         competent: _readingComprehension.competent + _viewingcomprehesion.competent + _grammar.competent + _vocabulary.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 25.1 ? 'Beginner' : (_total.postm >= 25.2 && _total.postm <= 33.6 ? 'Basic' : (_total.postm >= 33.61 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 25.1 ? 'Beginner' : (_total.prem >= 25.2 && _total.prem <= 33.6 ? 'Basic' : (_total.prem >= 33.61 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setVocabulary(_vocabulary);
      setReadingComprehension(_readingComprehension);
      setViewingComprehension(_viewingcomprehesion);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _numbersense = {};
      var _geometry = {};
      var _algebra = {};
      var _measurement = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Number Sense (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 +
            pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 +
            pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 17.5) / 4.94) > 1)
            _competent++;
         else if (((_sumVal - 17.5) / 4.94) > -0.51)
            _basic++;
         else if (((_sumVal - 17.5) / 4.94) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 +
               post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 +
               post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 17.5) / 4.94) > 1)
                  _competent++;
               else if (((_sumVal - 17.5) / 4.94) > -0.51)
                  _basic++;
               else if (((_sumVal - 17.5) / 4.94) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 15 ? 'Beginner' : (_postAve >= 15.1 && _postAve <= 22.5 ? 'Basic' : (_postAve >= 22.51 && _postAve <= 34 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 15 ? 'Beginner' : (_preAve >= 15.1 && _preAve <= 22.5 ? 'Basic' : (_preAve >= 22.51 && _preAve <= 34 ? 'Competent' : ''));
      }

      _numbersense = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Number Sense (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.48) / 0.73) > 1)
            _competent++;
         else if (((_sumVal - 1.48) / 0.73) > -0.51)
            _basic++;
         else if (((_sumVal - 1.48) / 0.73) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s35 + post_data[i].s36 + post_data[i].s37;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.48) / 0.73) > 1)
                  _competent++;
               else if (((_sumVal - 1.48) / 0.73) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.48) / 0.73) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.11 ? 'Beginner' : (_postAve >= 1.12 && _postAve <= 2.21 ? 'Basic' : (_postAve >= 2.22 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.11 ? 'Beginner' : (_preAve >= 1.12 && _preAve <= 2.21 ? 'Basic' : (_preAve >= 2.22 && _preAve <= 3 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Patterns and Algebra (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s38 + pre_data[i].s39;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 0.87) / 0.49) > 1)
            _competent++;
         else if (((_sumVal - 0.87) / 0.49) > -0.51)
            _basic++;
         else if (((_sumVal - 0.87) / 0.49) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s38 + post_data[i].s39;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 0.87) / 0.49) > 1)
                  _competent++;
               else if (((_sumVal - 0.87) / 0.49) > -0.51)
                  _basic++;
               else if (((_sumVal - 0.87) / 0.49) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.62 ? 'Beginner' : (_postAve >= 0.63 && _postAve <= 1.36 ? 'Basic' : (_postAve >= 1.37 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.62 ? 'Beginner' : (_preAve >= 0.63 && _preAve <= 1.36 ? 'Basic' : (_preAve >= 1.37 && _preAve <= 2 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Patterns and Algebra (end)

      //-- Measurement (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 +
            pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56 +
            pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60 + pre_data[i].s61 + pre_data[i].s62;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 12.26) / 2.45) > 1)
            _competent++;
         else if (((_sumVal - 12.26) / 2.45) > -0.51)
            _basic++;
         else if (((_sumVal - 12.26) / 2.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 +
               post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55 + post_data[i].s56 +
               post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60 + post_data[i].s61 + post_data[i].s62;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 12.26) / 2.45) > 1)
                  _competent++;
               else if (((_sumVal - 12.26) / 2.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 12.26) / 2.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 11 ? 'Beginner' : (_postAve >= 11.1 && _postAve <= 14.7 ? 'Basic' : (_postAve >= 14.71 && _postAve <= 23 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 11 ? 'Beginner' : (_preAve >= 11.1 && _preAve <= 14.7 ? 'Basic' : (_preAve >= 14.71 && _preAve <= 23 ? 'Competent' : ''));
      }

      _measurement = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Statistics and Probability (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s63 + pre_data[i].s64 + pre_data[i].s65;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.3) / 0.82) > 1)
            _competent++;
         else if (((_sumVal - 1.3) / 0.82) > -0.51)
            _basic++;
         else if (((_sumVal - 1.3) / 0.82) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s63 + post_data[i].s64 + post_data[i].s65;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.3) / 0.82) > 1)
                  _competent++;
               else if (((_sumVal - 1.3) / 0.82) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.3) / 0.82) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.88 ? 'Beginner' : (_postAve >= 0.89 && _postAve <= 2.12 ? 'Basic' : (_postAve >= 2.13 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.88 ? 'Beginner' : (_preAve >= 0.89 && _preAve <= 2.12 ? 'Basic' : (_preAve >= 2.13 && _preAve <= 3 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics and Probability (end)

      _total = {
         vn: pre_data.length,
         prem: _numbersense.prem + _geometry.prem + _algebra.prem + _measurement.prem + _statistics.prem,
         postm: _numbersense.postm + _geometry.postm + _algebra.postm + _measurement.postm + _statistics.postm,
         min: _numbersense.min + _geometry.min + _algebra.min + _measurement.min + _statistics.min,
         max: _numbersense.max + _geometry.max + _algebra.max + _measurement.max + _statistics.max,
         sd: _numbersense.sd + _geometry.sd + _algebra.sd + _measurement.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _numbersense.beginner + _geometry.beginner + _algebra.beginner + _measurement.beginner + _statistics.beginner,
         basic: _numbersense.basic + _geometry.basic + _algebra.basic + _measurement.basic + _statistics.basic,
         competent: _numbersense.competent + _geometry.competent + _algebra.competent + _measurement.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 26.8 ? 'Beginner' : (_total.postm >= 26.81 && _total.postm <= 36.7 ? 'Basic' : (_total.postm >= 36.71 && _total.postm <= 65 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 26.8 ? 'Beginner' : (_total.prem >= 26.81 && _total.prem <= 36.7 ? 'Basic' : (_total.prem >= 36.71 && _total.prem <= 65 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setNumberSense(_numbersense);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setMeasurement(_measurement);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _matter = {};
      var _livingthings = {};
      var _forceandmotion = {};
      var _earthandspace = {};
      var _proficiency = '';
      var _total = 0;

      //-- Matter (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4) / 2.45) > 1)
            _competent++;
         else if (((_sumVal - 4) / 2.45) > -0.51)
            _basic++;
         else if (((_sumVal - 4) / 2.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4) / 2.45) > 1)
                  _competent++;
               else if (((_sumVal - 4) / 2.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 4) / 2.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.77 ? 'Beginner' : (_postAve >= 2.78 && _postAve <= 6.45 ? 'Basic' : (_postAve >= 6.46 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.77 ? 'Beginner' : (_preAve >= 2.78 && _preAve <= 6.45 ? 'Basic' : (_preAve >= 6.46 && _preAve <= 10 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living Things and their Environment (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 +
            pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 +
            pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 16.69) / 2.69) > 1)
            _competent++;
         else if (((_sumVal - 16.69) / 2.69) > -0.51)
            _basic++;
         else if (((_sumVal - 16.69) / 2.69) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 +
               post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 +
               post_data[i].s41 + post_data[i].s42 + post_data[i].s43;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 16.69) / 2.69) > 1)
                  _competent++;
               else if (((_sumVal - 16.69) / 2.69) > -0.51)
                  _basic++;
               else if (((_sumVal - 16.69) / 2.69) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 15.3 ? 'Beginner' : (_postAve >= 15.31 && _postAve <= 19.4 ? 'Basic' : (_postAve >= 19.41 && _postAve <= 33 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 15.3 ? 'Beginner' : (_preAve >= 15.31 && _preAve <= 19.4 ? 'Basic' : (_preAve >= 19.41 && _preAve <= 33 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 +
            pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.23) / 1.64) > 1)
            _competent++;
         else if (((_sumVal - 7.23) / 1.64) > -0.51)
            _basic++;
         else if (((_sumVal - 7.23) / 1.64) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 +
               post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.23) / 1.64) > 1)
                  _competent++;
               else if (((_sumVal - 7.23) / 1.64) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.23) / 1.64) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.4 ? 'Beginner' : (_postAve >= 6.41 && _postAve <= 8.87 ? 'Basic' : (_postAve >= 8.88 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.4 ? 'Beginner' : (_preAve >= 6.41 && _preAve <= 8.87 ? 'Basic' : (_preAve >= 8.88 && _preAve <= 12 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60 + pre_data[i].s61 + pre_data[i].s62 + pre_data[i].s63 + pre_data[i].s64 + pre_data[i].s65;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5) / 1.29) > 1)
            _competent++;
         else if (((_sumVal - 5) / 1.29) > -0.51)
            _basic++;
         else if (((_sumVal - 5) / 1.29) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60 + post_data[i].s61 + post_data[i].s62 + post_data[i].s63 + post_data[i].s64 + post_data[i].s65;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5) / 1.29) > 1)
                  _competent++;
               else if (((_sumVal - 5) / 1.29) > -0.51)
                  _basic++;
               else if (((_sumVal - 5) / 1.29) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.35 ? 'Beginner' : (_postAve >= 4.36 && _postAve <= 6.29 ? 'Basic' : (_postAve >= 6.3 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.35 ? 'Beginner' : (_preAve >= 4.36 && _preAve <= 6.29 ? 'Basic' : (_preAve >= 6.3 && _preAve <= 10 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 33.91 ? 'Beginner' : (_total.postm >= 33.92 && _total.postm <= 42.9 ? 'Basic' : (_total.postm >= 42.91 && _total.postm <= 65 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 33.91 ? 'Beginner' : (_total.prem >= 33.92 && _total.prem <= 42.9 ? 'Basic' : (_total.prem >= 42.91 && _total.prem <= 65 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null && predata !== undefined ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null && postdata !== undefined ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="28%" nowrap style={{ width: '28.1%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.26%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="17%" nowrap style={{ width: '17.36%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST MEAN</span></b></p>
                                    </td>
                                    <td width="14%" style={{ width: '14.9%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST MEAN</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.56%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>MIN</span></b></p>
                                    </td>
                                    <td width="7%" nowrap style={{ width: '7.1%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>MAX</span></b></p>
                                    </td>
                                    <td width="4%" nowrap style={{ width: '4.92%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="15%" style={{ width: '15.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Reading Comprehension', readingComprehension, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('Viewing Comprehension', viewingComprehension, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Number Sense', numberSense, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Measurement', measurement, false)}
                                       {generateMeanLevelRow('Statistics and Probability', statisticsAndProbability, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('Living Things and their Environment', livingThings, false)}
                                       {generateMeanLevelRow('Force and Motion', forceAndMotion, false)}
                                       {generateMeanLevelRow('Earth and Space', earthAndSpace, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                           <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">INTERPRETATION</span></b></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td width="33%" nowrap valign="top" style={{ width: '33.42%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="20%" nowrap colSpan={3} valign="top" style={{ width: '20.42%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="19%" nowrap colSpan={3} valign="top" style={{ width: '19.62%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="20%" nowrap colSpan={3} valign="top" style={{ width: '20.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.22</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.23</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.16</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.17</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>8</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Reading Comprehension</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.81</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.82</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>12.6</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>12.59</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>18</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.8</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.81</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.2</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.21</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>29</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Viewing Comprehension</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.15</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.16</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.02</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.03</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>5</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25.1</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25.2</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>33.6</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>33.59</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} valign="top" style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Number Sense</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>15</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>15.1</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.5</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.47</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>34</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.11</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.12</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.21</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.22</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Patterns and Algebra</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.62</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.63</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.36</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>1.37</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Measurement</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11.1</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>14.7</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>14.72</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>23</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Statistics</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.88</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.89</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.12</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.13</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>26.8</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>26.81</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>36.7</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>36.73</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>65</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} valign="top" style={{ width: '100.0%', border: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.44</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.45</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.23</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.24</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Living Things</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>5.3</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>5.31</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>8.29</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>8.3</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>15</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Force, Motion and Energy </span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.75</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.76</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11.1</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11.1</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>14</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.98</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.99</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.69</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.7</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>15</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="33%" nowrap valign="top" style={{ width: '33.42%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="6%" nowrap valign="top" style={{ width: '6.86%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.94%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.8</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap valign="top" style={{ width: '8.44%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>22.81</span></p>
                                          </td>
                                          <td width="3%" nowrap valign="top" style={{ width: '3.56%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="7%" nowrap valign="top" style={{ width: '7.62%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>30.7</span></p>
                                          </td>
                                          <td width="2%" nowrap valign="top" style={{ width: '2.8%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="11%" nowrap valign="top" style={{ width: '11.02%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>30.74</span></p>
                                          </td>
                                          <td width="4%" nowrap valign="top" style={{ width: '4.36%', border: 'none', borderBottom: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="5%" nowrap valign="top" style={{ width: '5.56%', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>50</span></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Viewing Comprehension', viewingComprehension, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 5)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Number Sense', numberSense, 6)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Measurement', measurement, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 10)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 11)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 15)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 16)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in English</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="65%" colSpan={2} rowSpan={2} style={{ width: '65.98%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING
                                             COMPETENCY</span></b></p>
                                       </td>
                                       <td width="17%" style={{ width: '17.26%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="16%" style={{ width: '16.76%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="17%" style={{ width: '17.26%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.76%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.38%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Vocabulary Development</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.26%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.76%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Infer the meaning of unfamiliar words (compound) based on given context clues.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Infer the meaning of unfamiliar words (compound) based on given context clues.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Clarify meaning of words using dictionaries, thesaurus, and/or online resources.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Clarify meaning of words using dictionaries, thesaurus, and/or online resources.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Identify different meanings of content specific words (denotation and connotation) (Science).', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Identify different meanings of content specific words (denotation and connotation) (Science).', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Infer the meaning of unfamiliar words (compound, affixed) based on.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Infer the meaning of unfamiliar words (compound, affixed) based on.', englishPCData.pre_s8, englishPCData.post_s8, 8)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.38%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Reading Comprehension</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.26%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.76%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Infer the theme of literary text.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Infer the theme of literary text.', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Distinguish text-types according to purpose.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Distinguish text-types according to purpose.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Analyze figures of speech (simile, metaphor, personification, hyperbole) in a given text.', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Analyze figures of speech (simile, metaphor, personification, hyperbole) in a given text.', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Analyze figures of speech (simile, metaphor, personification, hyperbole) in a given text.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Analyze figures of speech (simile, metaphor, personification, hyperbole) in a given text.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Main idea, key sentences and supporting details of a given paragraph.', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Main idea, key sentences and supporting details of a given paragraph.', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Main idea, key sentences and supporting details of a given paragraph.', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Distinguish text-types according to features (structural and language).', englishPCData.pre_s26, englishPCData.post_s26, 26)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.38%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Grammar</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.26%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.76%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Compose clear and coherent sentences using appropriate grammatical structures.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose and audience.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose and audience.', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose and audience.', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose and audience.', englishPCData.pre_s43, englishPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Use compound sentences to show cause and effect.', englishPCData.pre_s44, englishPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Use compound sentences to show cause and effect.', englishPCData.pre_s45, englishPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Use compound sentences to show cause and effect.', englishPCData.pre_s46, englishPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Use compound sentences to show cause and effect.', englishPCData.pre_s47, englishPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Use compound sentences to show problem- solution relationship of ideas.', englishPCData.pre_s48, englishPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Use compound sentences to show problem- solution relationship of ideas.', englishPCData.pre_s49, englishPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Use compound sentences to show problem- solution relationship of ideas.', englishPCData.pre_s50, englishPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Use compound sentences to show problem- solution relationship of ideas.', englishPCData.pre_s51, englishPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Use complex sentences to show cause and effect.', englishPCData.pre_s52, englishPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Use complex sentences to show cause and effect.', englishPCData.pre_s53, englishPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Use complex sentences to show cause and effect.', englishPCData.pre_s54, englishPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Use complex sentences to show cause and effect.', englishPCData.pre_s55, englishPCData.post_s55, 55)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.38%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Viewing Comprehension</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.26%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.76%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe different forms and conventions of film and moving pictures (lights, blocking, direction, characterization, acting, dialog, setting or set-up).', englishPCData.pre_s56, englishPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Describe different forms and conventions of film and moving pictures (lights, blocking, direction, characterization, acting, dialog, setting or set-up).', englishPCData.pre_s57, englishPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Determine images/ideas that are explicitly used to influence viewers.', englishPCData.pre_s58, englishPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Determine images/ideas that are explicitly used to influence viewers.', englishPCData.pre_s59, englishPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Analyze how visual and multimedia elements contribute to the meaning of a text.', englishPCData.pre_s60, englishPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Math</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="69%" colSpan={2} rowSpan={2} style={{ width: '69.62%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING
                                             COMPETENCY</span></b></p>
                                       </td>
                                       <td width="15%" style={{ width: '15.22%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="15%" style={{ width: '15.16%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="15%" style={{ width: '15.22%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Number Sense</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.22%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Reads and writes numbers up to 10 000 000 in symbols and in words.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Reads and writes numbers up to 10 000 000 in symbols and in words.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Rounds numbers to the nearest  Hundred thousand and million.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Rounds numbers to the nearest  Hundred thousand and million.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Uses divisibility rules for 4, 8, 12, and 11 to find common factors.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving factors, multiples, and divisibility rules for 2,3,4,5,6,8,9,10,11, and 12', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Simplifies a series of operations on whole numbers involving more than two operations using the PMDAS or GMDAS rule.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Finds the common factors and the GCF of 2–4 numbers using continuous division', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Solves real-life problems involving GCF and LCM of 2-3 given numbers.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Subtracts fractions and mixed fractions without and with regrouping.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Subtracts fractions and mixed fractions without and with regrouping.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Solves routine and non-routine Problems involving addition and/or subtraction of fractions using appropriate problem solving strategies and tools.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Multiplies a fraction and a whole number and another fraction', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Solves routine or non-routine problems involving multiplication without or with addition or subtraction of fractions and whole numbers using appropriate problem solving strategies and tools.', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Divides simple fractions, whole numbers by a fraction and vice versa.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Solves routine and non routine problems involving division without r with any of the other operations of fractions and whole numbers using appropriate problem solving strategies. ', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Gives the place value and the value of a digit of a given decimal number through ten thousandths', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Rounds decimal numbers to the nearest hundredth and thousandth.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Rounds decimal numbers to the nearest hundredth and thousandth.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Compares and arranges decimal Numbers.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Adds and subtracts decimal numbers through thousandths without and with regrouping.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Solves routine or non-routine problems involving addition and subtraction of decimal numbers including money using appropriate problem solving strategies and tools.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Multiplies decimals up to 2 decimal places by 1- to 2-digit whole numbers.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Multiplies decimals with factors up to 2 decimal places.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving multiplication without or with addition or subtraction of decimals and whole numbers including money using appropriate problem solving strategies and tools.', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Divides decimals with up to 2 decimal places.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Divides whole numbers with quotients in decimal form.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving division without or with any of the other operations of decimals and whole numbers including money using appropriate problem solving strategies and tools.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Expresses ratio using either the colon (:) or fraction.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Finds the missing term in a pair of equivalent ratios.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Recognizes when two quantities are in direct proportion.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Visualizes percent and its relationship to fractions, ratios, and decimal numbers using models', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Identifies the base, percentage, and rate in a problem.', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving percentage using appropriate strategies and tools.', mathPCData.pre_s34, mathPCData.post_s34, 34)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Geometry</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.22%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Visualizes, names, and describes polygons with 5 or more sides.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Visualizes congruent polygons.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Visualizes and describes solid figures.', mathPCData.pre_s37, mathPCData.post_s37, 37)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Pattern and Algebra</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.22%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Formulates the rule in finding the next term in a sequence.', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Uses different strategies (looking for a pattern, working backwards, etc.) to solve for the unknown in simple equations involving one or more operations on whole numbers and fractions', mathPCData.pre_s39, mathPCData.post_s39, 39)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Measurement</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.22%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Measures time using a 12-hour and a 24-hour clock.', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Measures time using a 12-hour and a 24-hour clock.', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Calculates time in the different world time zones in relation to the Philippines.', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Solves problems involving time.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Visualizes circumference of a circle.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Measures circumference of a circle using appropriate tools.', mathPCData.pre_s45, mathPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Derives a formula in finding the circumference of a circle.', mathPCData.pre_s46, mathPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Finds the circumference of a circle.', mathPCData.pre_s47, mathPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving circumference of a circle.', mathPCData.pre_s48, mathPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving circumference of a circle.', mathPCData.pre_s49, mathPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Derives a formula in finding the area of a circle .', mathPCData.pre_s50, mathPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Finds the area of a given circle.', mathPCData.pre_s51, mathPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving the area of a circle.', mathPCData.pre_s52, mathPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving the area of a circle.', mathPCData.pre_s53, mathPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Creates problems involving a circle, with reasonable answers.', mathPCData.pre_s54, mathPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Names the appropriate unit of measure used for measuring the volume of a cube and a rectangle prism.', mathPCData.pre_s55, mathPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Derives the formula in finding the volume of a cube and a rectangular prism using cubic cm and cubic m.', mathPCData.pre_s56, mathPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Converts cu. cm to cu. m and vice versa; cu.cm to L and vice versa', mathPCData.pre_s57, mathPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Finds the volume of a given cube and rectangular prism using cu. cm and cu. M.', mathPCData.pre_s58, mathPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Estimates and uses appropriate units of measure for volume.', mathPCData.pre_s59, mathPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving volume of a cube and rectangular prism in real-life situations using appropriate strategies and tools', mathPCData.pre_s60, mathPCData.post_s60, 60)}
                                       {generatePercentCorrectRow('Reads and measures temperature using thermometer (alcohol and/or digital) in degree Celsius', mathPCData.pre_s61, mathPCData.post_s61, 61)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems involving temperature in real-life situations.', mathPCData.pre_s62, mathPCData.post_s62, 62)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Statistics and Probability</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.22%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.16%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Interprets data presented in different kinds of line graphs (single to double line graph).', mathPCData.pre_s63, mathPCData.post_s63, 63)}
                                       {generatePercentCorrectRow('Solves routine and non-routine problems using data presented in a line graph.', mathPCData.pre_s64, mathPCData.post_s64, 64)}
                                       {generatePercentCorrectRow('Draws inferences based on data presented in a line graph.', mathPCData.pre_s65, mathPCData.post_s65, 65)}

                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Science</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="63%" colSpan={2} rowSpan={2} style={{ width: '63.18%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.44%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.38%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="18%" style={{ width: '18.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.38%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Matter</span></b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.38%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>

                                       {generatePercentCorrectRow('Use the properties of materials whether they are useful or harmful.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Use the properties of materials whether they are useful or harmful.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Investigate changes that happen in materials under the following conditions.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Investigate changes that happen in materials under the following conditions.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Recognize the importance of recycle, reduce, reuse, recover and repair in waste management.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Recognize the importance of recycle, reduce, reuse, recover and repair in waste management.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Living Things</span></b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.38%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the parts of the reproductive system and their functions.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Describe the parts of the reproductive system and their functions.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Explain the menstrual cycle.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Explain the menstrual cycle.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Give ways of taking care of the reproductive organs.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Give ways of taking care of the reproductive organs.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Describe the different modes of reproduction in animals such as butterflies, mosquitoes, frogs, cats and dogs.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Describe the different modes of reproduction in animals such as butterflies, mosquitoes, frogs, cats and dogs.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Describe the reproductive parts in plants and their functions.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Describe the reproductive parts in plants and their functions.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Describe the different modes of reproduction in flowering and non-flowering plants such as moss, fern, mongo and others.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Describe the different modes of reproduction in flowering and non-flowering plants such as moss, fern, mongo and others.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Discuss the interactions among living things and non-living things in estuaries and intertidal zones.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Discuss the interactions among living things and non-living things in estuaries and intertidal zones.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Explain the need to protect and conserve estuaries and intertidal zones.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Force and Motion</span></b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.38%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the need to protect and conserve estuaries and intertidal zones.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Describe the motion of an object by tracing and measuring its change in position (distance travelled) over a period of time.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Describe the motion of an object by tracing and measuring its change in position (distance travelled) over a period of time.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Use appropriate measuring tools and correct standard units.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Use appropriate measuring tools and correct standard units.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Discuss why some materials are good conductors of heat and electricity.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Discuss why some materials are good conductors of heat and electricity.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Infer how black and colored objects affect the ability to absorb heat.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Infer how black and colored objects affect the ability to absorb heat.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Relate the ability of the material to block, absorb or transmit light to its use.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Relate the ability of the material to block, absorb or transmit light to its use.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Infer the conditions necessary to make a bulb light up.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Infer the conditions necessary to make a bulb light up.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Determine the effects of changing the number or type of components in a circuit.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Earth and Space</span></b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.38%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine the effects of changing the number or type of components in a circuit.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Infer that electricity can be used to produce magnets.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Infer that electricity can be used to produce magnets.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Design an experiment to determine the factors that affect the strength of the electromagnet.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Design an experiment to determine the factors that affect the strength of the electromagnet.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Describe how rocks turn into soil.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Describe how rocks turn into soil.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Investigate extent of soil erosion in the community and its effects on living things and the environment.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Investigate extent of soil erosion in the community and its effects on living things and the environment.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Communicate the data collected from the investigation on soil erosion.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Communicate the data collected from the investigation on soil erosion.', sciencePCData.pre_s46, sciencePCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Observe the changes in the weather before, during and after a typhoon.', sciencePCData.pre_s47, sciencePCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Observe the changes in the weather before, during and after a typhoon.', sciencePCData.pre_s48, sciencePCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Identify star patterns that can be seen at particular times of the year.', sciencePCData.pre_s49, sciencePCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Identify star patterns that can be seen at particular times of the year.', sciencePCData.pre_s50, sciencePCData.post_s50, 50)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}