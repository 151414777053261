/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col,
   Modal, Image, Breadcrumb
} from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Link } from "react-router-dom";

var g_soa_url = "";
var g_subdomain = "";
var g_upload_date = "";

export default function Soa() {
   const [searchText, setSearchText] = useState("");
   const [searchedColumn, setSearchedColumn] = useState("");

   const [listLoading, setListLoading] = useState(false);
   const [soaList, setSoaList] = useState([]);
   const [modalVisible, setModalVisible] = useState(false);
   const [downloadStatus, setDownloadStatus] = useState(false);

   g_subdomain = localStorage.getItem("lms-subdomain");

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);

   useEffect(() => {
      Utils.verifyJwt();

      getSOAList(user_id);
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const SoaListColumns = [
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         width: '50%'
      },
      {
         title: 'Upload Date',
         key: 'date_created',
         dataIndex: 'date_created',
         width: '30%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">

                     {payload.file_url !== null && payload.file_url !== "" &&
                        <>
                           <Tooltip placement="top" title={'View Statement of Account'}>
                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                                 onClick={() => handlePreview(payload.file_url, payload.date_created)}
                              />
                           </Tooltip>
                        </>
                     }
                  </Space>
               </>
            );
         },
         width: '20%'
      },
   ].filter(item => !item.hidden);

   const renderPreviewer = (document) => {
      if (document !== null && document !== '' && modalVisible) {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  <div style={{ width: "100%", height: "calc(100vh - 185px)" }}>
                     <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                        <Viewer
                           pageLayout={{
                              transformSize: ({ size }) => ({
                                 height: size.height + 30,
                                 width: size.width + 30,
                              }),
                           }}
                           fileUrl={url}
                           defaultScale={SpecialZoomLevel.PageWidth}
                           initialPage={0}
                        />
                     </Worker>
                  </div>
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  <div
                     className={'modal-document-viewer'}
                     style={{ height: "calc(100vh - 195px)" }}
                  >
                     <Image
                        src={url}
                        style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
                     />
                  </div>
               </>
         }
      }
   }

   const getSOAList = (id) => {
      var url = `${process.env.REACT_APP_API_CPH}/getsoa?id=${id}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setSoaList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handlePreview = (soa_url, upload_date) => {
      g_soa_url = soa_url;
      g_upload_date = moment(upload_date).format("MM-DD-YYYY");
      setModalVisible(true);
   }

   const _downloadFile = (url, name) => {
      setDownloadStatus(true);

      var re = new RegExp(' ', 'g');
      var file_name = "soa_" + name.replace(re, '_');
      var pos = url.lastIndexOf('.');
      var ext = url.slice(pos);

      fetch(url)
         .then(response => {
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = file_name + ext;
               a.click();
               setDownloadStatus(false)
            });
         });
   };

   const handleModalClose = () => {
      setModalVisible(false);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Attendance</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Statement of Accounts</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12} style={{ overflow: "auto" }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     <Table
                        rowKey={'uid'}
                        loading={listLoading}
                        size='small'
                        columns={SoaListColumns}
                        dataSource={soaList}
                        pagination={{ position: ['topCenter'] }}
                        scroll={{
                           x: '1000px',
                           y: 'calc(100svh - 17.5rem)',
                        }}
                        footer={() => 'Total Records: ' + soaList.length}
                     />
                  </div>
               </Col>
            </Row>
         </div>

         <Modal
            title={"Statement of Account"}
            // okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            // width={window.innerWidth - 300}
            width='75%'

            onCancel={() => { handleModalClose(); }}
            onOk={() => { handleModalClose(); }}
            footer={[
               <Row>
                  <Col span={12} style={{ textAlign: "left" }}>
                     <Button shape='round'
                        type="primary"
                        loading={downloadStatus}
                        onClick={() => _downloadFile(`${process.env.REACT_APP_API_S3_URL}/${g_subdomain}/${g_soa_url}`, g_upload_date)}
                     >
                        Download
                     </Button>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                     <Button shape='round' type='primary' onClick={() => { handleModalClose(); }}>
                        Close
                     </Button>
                  </Col>
               </Row>,
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(g_soa_url)}
            </div>
         </Modal>
      </>
   );
}

