/* eslint-disable default-case */
import Enums from "./Enums";
import LoginService from "../services/LoginService";
import jwtDecode from 'jwt-decode';
// import * as AntDIcons from '@ant-design/icons';
import { FileImageOutlined, FilePdfOutlined, FilePptOutlined, FileWordOutlined, FileExcelOutlined, VideoCameraOutlined, FileOutlined } from '@ant-design/icons';
import {
   EnglishKReport, English1Report, English2Report, English3Report, English4Report, English5Report, English6Report, English7Report, English8Report, English9Report, English10Report,
   ScienceKReport, Science1Report, Science2Report, Science3Report, Science4Report, Science5Report, Science6Report, Science7Report, Science8Report, Science9Report, Science10Report,
   MathKReport, Math1Report, Math2Report, Math3Report, Math4Report, Math5Report, Math6Report, Math7Report, Math8Report, Math9Report, Math10Report,
   Grade1SummaryReport, Grade2SummaryReport, Grade3SummaryReport, Grade4SummaryReport, Grade5SummaryReport, Grade6SummaryReport, Grade7SummaryReport, Grade8SummaryReport,
   Grade9SummaryReport, Grade10SummaryReport, KinderSummaryReport,
   GMG11Report, Physics1G12Report, Biology1G12Report, FABM2G12Report, WRBSG12Report, OCG11Report, PPGG12Report, BFG12Report, BESRG12Report, RAWG11Report, CWG12Report, TNCTG12Report,
   GP2G12Report, GB2G12Report, SPG11Report, AEG12Report,

   MTLanguageKReport, MTScienceKReport, MTMathKReport, MTKLanguageSummaryReport, MTKScienceSummaryReport, MTKMathSummaryReport,
   MTG1LanguageReport, MTG1MathReport, MTG1RALReport, MTG1LanguageSummaryReport, MTG1MathSummaryReport, MTG1RALSummaryReport,
   MTG4EnglishReport, MTG4MathReport, MTG4ScienceReport, MTG4EnglishSummaryReport, MTG4MathSummaryReport, MTG4ScienceSummaryReport,
   MTG7EnglishReport, MTG7MathReport, MTG7ScienceReport, MTG7EnglishSummaryReport, MTG7MathSummaryReport, MTG7ScienceSummaryReport,

   BFG12SummaryReport, PPGG12SummaryReport, GP1G12SummaryReport, GB1G12SummaryReport, FABM2G12SummaryReport, WRBSG12SummaryReport, OCG11SummaryReport, GMG11SummaryReport, RAWG11SummaryReport,
   CWG12SummaryReport, TNCTG12SummaryReport, BESRG12SummaryReport, GP2G12SummaryReport, GB2G12SummaryReport, SPG11SummaryReport, AEG12SummaryReport,
   BC12Report, CHEM212Report, DIASS12Report, OM12Report, BC12SummaryReport, CHEM212SummaryReport, DIASS12SummaryReport, OM12SummaryReport
} from '../pages/DiagnosticAssessmentPage/reports';
// import { getTwoToneColor, setTwoToneColor } from '@ant-design/icons';
import recursivelyLowercaseJSONKeys from 'recursive-lowercase-json';
import { Buffer } from 'buffer';

const decodeJwt = () => {
   const jwt = localStorage.getItem('lms-jwt');

   if (jwt) {
      var decodedString = jwtDecode(jwt);
      return decodedString;
   }
   else {
      window.location.href = '/';
      return;
   }
}

const getCurrentUserInfo = (type) => {
   const jwt = localStorage.getItem('lms-jwt');

   if (jwt) {
      var decodedString = jwtDecode(jwt);
      if (type === Enums.UserInfo.role)
         return decodedString.role;
      if (type === Enums.UserInfo.username)
         return decodedString.username;
      if (type === Enums.UserInfo.id)
         return decodedString[Enums.UserInfo.id];
      if (type === Enums.UserInfo.fullname)
         return decodedString.unique_name;
      if (type === Enums.UserInfo.otherdata)
         return decodedString[Enums.UserInfo.otherdata];
   }
   else {
      window.location.href = '/';
      return;
   }
}

const checkFileImage = (type) => {
   switch (type) {
      case Enums.FileType.xls: case Enums.FileType.xlsx:
         return Enums.FileTypeImage.excel;
      case Enums.FileType.docx: case Enums.FileType.doc:
         return Enums.FileTypeImage.word;
      case Enums.FileType.pptx:
         return Enums.FileTypeImage.ppt;
      case Enums.FileType.ppsx:
         return Enums.FileTypeImage.ppsx;
      case Enums.FileType.pdf:
         return Enums.FileTypeImage.pdf;
      case Enums.FileType.jpeg: case Enums.FileType.png: case Enums.FileType.bmp:
         return Enums.FileTypeImage.img;
      case Enums.FileType.mp4:
         return Enums.FileTypeImage.mp4;
      default:
         return Enums.FileTypeImage.others;
   }
}

const verifyJwt = async () => {
   const jwt = localStorage.getItem('lms-jwt');

   if (jwt) {
      try {
         await LoginService.jwtValidation().catch((e) => {
            window.location.href = '/';
            return;
         })
      }
      catch {
         window.location.href = '/';
         return;
      }
   }
   else {
      window.location.href = '/';
      return;
   }
};

const convertKeysToLowerCase = (obj) => {
   // const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
   // const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'

   // let transformedObj = isArray(obj) ? [] : {}

   // for (let key in obj) {
   //    // replace the following with any transform function
   //    const transformedKey = key.toLowerCase()

   //    if (isObject(obj[key]) || isArray(obj[key])) {
   //       transformedObj[transformedKey] = convertKeysToLowerCase(obj[key])
   //    } else {
   //       transformedObj[transformedKey] = obj[key]
   //    }
   // }
   var transformedObj = recursivelyLowercaseJSONKeys(obj)
   return transformedObj
};

function getFileIcon(fileName) {
   // setTwoToneColor("#70d0d4");
   // getTwoToneColor();
   var retVal = null;

   const fileExtension = fileName.split(".").pop();

   switch (fileExtension.toUpperCase()) {
      case "PNG":
      case "JPG":
      case "JPEG":
         retVal = <FileImageOutlined />;
         break;
      case "PDF":
         retVal = <FilePdfOutlined />;
         break;
      case "PPT":
      case "PPTX":
         retVal = <FilePptOutlined />;
         break;
      case "DOC":
      case "DOCX":
         retVal = <FileWordOutlined />;
         break;
      case "XLS":
      case "XLSX":
         retVal = <FileExcelOutlined />;
         break;
      case "MP4":
      case "AVI":
         retVal = <VideoCameraOutlined />;
         break;
      default:
         retVal = <FileOutlined />;
         break;
   }

   return retVal;
}

function standardDeviation(arr) {
   let mean = arr.reduce((acc, curr) => {
      return acc + curr
   }, 0) / arr.length;

   arr = arr.map((el) => {
      return (el - mean) ** 2
   })

   let total = arr.reduce((acc, curr) => acc + curr, 0);

   return Math.sqrt(total / arr.length);
}

function getPercentage(data, proficiency) {
   if (proficiency === 'beginner')
      return (data.beginner / (data.beginner + data.basic + data.competent) * 100).toFixed(2);

   if (proficiency === 'basic')
      return (data.basic / (data.beginner + data.basic + data.competent) * 100).toFixed(2);

   if (proficiency === 'competent')
      return (data.competent / (data.beginner + data.basic + data.competent) * 100).toFixed(2);
}

const displayDiagnosticReport = (template, assign_id, subject, subjectID, studentID) => {
   if (subject !== null) {
      switch (template) {
         case 'PreKinder':
         case 'Kindergarten':
         case 'Kindergarten 1':
         case 'Kindergarten 2':
         case 'Casa':
         case 'Advance Casa':
         case 'Senior Casa':
            switch (subjectID) {
               case 'english':
                  return (
                     <EnglishKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <MathKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <ScienceKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtlang':
                  return (
                     <MTLanguageKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtmath':
                  return (
                     <MTMathKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtsci':
                  return (
                     <MTScienceKReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 1':
            switch (subjectID) {
               case 'english':
                  return (
                     <English1Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math1Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science1Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtlang':
                  return (
                     <MTG1LanguageReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtmath':
                  return (
                     <MTG1MathReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtral':
                  return (
                     <MTG1RALReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 2':
            switch (subjectID) {
               case 'english':
                  return (
                     <English2Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math2Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science2Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 3':
            switch (subjectID) {
               case 'english':
                  return (
                     <English3Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math3Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science3Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 4':
            switch (subjectID) {
               case 'english':
                  return (
                     <English4Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math4Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science4Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mteng':
                  return (
                     <MTG4EnglishReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtmath':
                  return (
                     <MTG4MathReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtsci':
                  return (
                     <MTG4ScienceReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 5':
            switch (subjectID) {
               case 'english':
                  return (
                     <English5Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math5Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science5Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 6':
            switch (subjectID) {
               case 'english':
                  return (
                     <English6Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math6Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science6Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 7':
            switch (subjectID) {
               case 'english':
                  return (
                     <English7Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math7Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science7Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mteng':
                  return (
                     <MTG7EnglishReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtmath':
                  return (
                     <MTG7MathReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'mtsci':
                  return (
                     <MTG7ScienceReport
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 8':
            switch (subjectID) {
               case 'english':
                  return (
                     <English8Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math8Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science8Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 9':
            switch (subjectID) {
               case 'english':
                  return (
                     <English9Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math9Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science9Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 10':
            switch (subjectID) {
               case 'english':
                  return (
                     <English10Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'math':
                  return (
                     <Math10Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'science':
                  return (
                     <Science10Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 11':
            switch (subjectID) {
               case 'oc':
                  return (
                     <OCG11Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'raw':
                  return (
                     <RAWG11Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'gm':
                  return (
                     <GMG11Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'stats':
                  return (
                     <SPG11Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
         case 'Grade 12':
            switch (subjectID) {
               case 'besr':
                  return (
                     <BESRG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'gp1':
                  return (
                     <Physics1G12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'gb1':
                  return (
                     <Biology1G12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'gp2':
                  return (
                     <GP2G12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'gb2':
                  return (
                     <GB2G12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'fabm2':
                  return (
                     <FABM2G12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'wrbs':
                  return (
                     <WRBSG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'ppg':
                  return (
                     <PPGG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'bf':
                  return (
                     <BFG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'cw':
                  return (
                     <CWG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'tnct':
                  return (
                     <TNCTG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'ae':
                  return (
                     <AEG12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'bc':
                  return (
                     <BC12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'chem2':
                  return (
                     <CHEM212Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'diass':
                  return (
                     <DIASS12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
               case 'om':
                  return (
                     <OM12Report
                        subject={subject}
                        assignment_id={assign_id}
                        student_id={studentID}
                        template={template}
                        subject_id={subjectID}
                     />
                  );
            }
            break;
      }
   }
}

// const displayDiagnosticReport = (template, assign_id, subject, studentID) => {
//    if (subject !== null) {
//       switch (template) {
//          case 'Kindergarten':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <EnglishKReport
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <MathKReport
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <ScienceKReport
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 1':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English1Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math1Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science1Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 2':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English2Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math2Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science2Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 3':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English3Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math3Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science3Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 4':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English4Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math4Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science4Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 5':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English5Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math5Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science5Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 6':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English6Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math6Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science6Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 7':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English7Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math7Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science7Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 8':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English8Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math8Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science8Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 9':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English9Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math9Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science9Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//          case 'Grade 10':
//             switch (subject) {
//                case 'english':
//                   return (
//                      <English10Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'math':
//                   return (
//                      <Math10Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//                case 'science':
//                   return (
//                      <Science10Report
//                         subject={subject}
//                         assignment_id={assign_id}
//                         student_id={studentID}
//                         template={template}
//                      />
//                   );
//             }
//             break;
//       }
//    }
// }

function GetProficiency(percentage) {
   if (percentage >= 0 && percentage <= 59.49)
      return "BEGINNER";

   if (percentage >= 59.50 && percentage <= 79.49)
      return "PROFICIENT";

   if (percentage >= 79.50 && percentage <= 100)
      return "COMPETENT";
}

function GetCardBackground(subject, level) {
   var retVal = 'bg_default.png';

   if (level === 'formative_1')
      retVal = 'bg_l1_' + subject.toLowerCase().replace(/ /g, "_") + ".png";

   if (level === 'formative_2')
      retVal = 'bg_l2_' + subject.toLowerCase().replace(/ /g, "_") + ".png";

   if (level === 'formative_3')
      retVal = 'bg_l3_' + subject.toLowerCase().replace(/ /g, "_") + ".png";

   // switch (level) {
   //    case 'kinder':
   //    case 'grade 1':
   //    case 'grade 2':
   //    case 'grade 3':
   //    case 'grade 4':
   //    case 'grade 5':
   //    case 'grade 6':
   //       retVal = 'bg_gs_' + subject.toLowerCase().replace(/ /g, "_") + ".png";
   //       break;
   //    case 'grade 7':
   //    case 'grade 8':
   //    case 'grade 9':
   //    case 'grade 10':
   //    case 'grade 11':
   //    case 'grade 12':
   //       retVal = 'bg_jhs_' + subject.toLowerCase().replace(/ /g, "_") + ".png";
   //       break;
   // }

   return retVal;
}

const getBase64 = (img, callback) => {
   const reader = new FileReader();
   reader.addEventListener('load', () => callback(reader.result));
   reader.readAsDataURL(img);
}

function convertLetterToNumber(letter) {
   if (letter !== null && letter !== undefined && letter !== '') {
      const charCode = letter.charCodeAt(0);
      if (charCode >= 65 && charCode <= 90) {
         return charCode - 64;
      } else {
         return '';
      }
   } else return '';
}

function generateSequenceNumbers(max_no) {
   const numbers = Array(max_no).fill().map((_, index) => index + 1);
   numbers.sort(() => Math.random() - 0.5);

   return numbers;
}

function removeSpaces(str) {
   return str.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
}

function GetTypeLabel(assessment_type) {
   switch (assessment_type) {
      case 'formative_1':
         return 'Formative Level 1';
      case 'formative_2':
         return 'Formative Level 2';
      case 'formative_3':
         return 'Formative Level 3';
      case 'summative':
         return 'Summative';
      case 'study_plan_1':
         return 'Study Plan 1';
      case 'study_plan_2':
         return 'Study Plan 2';
   }
}

function dataUrlToFile(dataUrl, filename) {
   const arr = dataUrl.split(',');
   if (arr.length < 2) { return undefined; }
   const mimeArr = arr[0].match(/:(.*?);/);
   if (!mimeArr || mimeArr.length < 2) { return undefined; }
   const mime = mimeArr[1];
   const buff = Buffer.from(arr[1], 'base64');
   return new File([buff], filename, { type: mime });
}

const modules = {
   checkFileImage,
   verifyJwt,
   convertKeysToLowerCase,
   decodeJwt,
   getCurrentUserInfo,
   GetFileIcon: getFileIcon,
   standardDeviation,
   getPercentage,
   displayDiagnosticReport,
   GetProficiency,
   GetCardBackground,
   getBase64,
   convertLetterToNumber,
   generateSequenceNumbers,
   removeSpaces,
   GetTypeLabel,
   dataUrlToFile,
};

export default modules;