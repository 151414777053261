/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
   Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select,
   Row, Col, message, Popconfirm, Radio, Tag, Drawer, notification, Modal, Breadcrumb,
   Spin, Segmented, Form
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import OAAssessmentAssignTool from '../../components/assessment/OAAssessmentAssignTool';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;
// const masterListData = [];

export function Assessment() {
   const navigate = useNavigate();
   const location = useLocation();
   const aTab = location.state !== null ? location.state.activetab : "1";

   const [dataContainer, setDataContainer] = useState([]);
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [questionTypeModalVisible, setQuestionTypeModalVisible] = useState(false);
   const [questionType, setQuestionType] = useState(1);
   // const [showMasterList, setShowMasterList] = useState(false);
   // const [selectedAssessment, setSelectedAssessment] = useState("");

   const [assOptions, setAssOptions] = useState(null);

   const [assignVisible, setAssignVisible] = useState(false);
   const [assTitle, setAssTitle] = useState('');
   const [assTerm, setAssTerm] = useState('');
   // const [terms, setTerms] = useState([]);
   const [masterList, setMasterList] = useState([]);
   const [assignedList, setAssignedList] = useState([]);
   const [currentList, setCurrentList] = useState([]);
   const [upcomingList, setUpcomingList] = useState([]);
   const [pastList, setPastList] = useState([]);
   const [deleteList, setDeletedList] = useState([]);
   const [activeTab, setActiveTab] = useState("1");
   const [listLoading, setListLoading] = useState(true);

   const [processing, setProcessing] = useState(false);

   const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const [userList, setUserList] = useState([]);
   const [user_id, setUser_Id] = useState(Utils.getCurrentUserInfo(Enums.UserInfo.id));

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   // const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));

   const [teacherList, setTeacherList] = useState(null);
   const [shareToVisible, setShareToVisible] = useState(false);
   const [sharing, setSharing] = useState(false);
   const [selectedTeachers, setSelectedTeachers] = useState(null);
   const [assessmentID, setAssessmentID] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'registrar' && user_role.toLowerCase() !== 'co-teacher') {
         navigate('/home');
      } else {
         setActiveTab(() => {
            refreshList(aTab, user_id);
            return aTab
         });
      }

      // if (user_role.toLowerCase() === "admin") {
      var url = `${process.env.REACT_APP_API_LESSON}/listAllStaff`;
      axios.get(url).then((response) => {
         if (response.data) {
            let data = response.data;

            var users = data.filter((d) => d.id !== Number(user_id)).sort((a, b) => {
               var aVal = a["first_Name"];
               var bVal = b["first_Name"];
               switch (typeof aVal) {
                  case 'string':
                     return aVal.localeCompare(bVal);
                  case 'number':
                     return aVal - bVal;
                  default:
                     throw new Error("Unsupported value to sort by");
               }
            });

            for (var i = 0; i < users.length; i++) {
               users[i].key = users[i].id;
            }

            setTeacherList(users);
            var final = data.filter((d) => d.id === Number(user_id));
            Array.prototype.push.apply(final, users);
            setUserList(final)
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
      // }
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const masterListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'term',
         key: 'term',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'shared',
         key: 'shared',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'created_by',
         key: 'created_by',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '26%'
      },
      {
         title: 'Term',
         key: 'term_name',
         dataIndex: 'term_name',
         sorter: (a, b) => { return a.term_name.localeCompare(b.term_name) },
         ...getColumnSearchProps('term_name'),
         ellipsis: {
            showTitle: false,
         },
         render: term_name => (
            <Tooltip placement="topLeft" title={term_name}>
               <span style={{ cursor: "default" }}>{term_name}</span>
            </Tooltip>
         ),
         width: '9%'
      },
      {
         title: 'Level',
         key: 'level_desc',
         dataIndex: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: level_desc => (
            <Tooltip placement="topLeft" title={level_desc}>
               <span style={{ cursor: "default" }}>{level_desc}</span>
            </Tooltip>
         ),
         width: '9%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc.localeCompare(b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '17%'
      },
      {
         title: 'Created By',
         key: 'created_by_name',
         dataIndex: 'created_by_name',
         sorter: (a, b) => { return a.created_by_name.localeCompare(b.created_by_name) },
         ...getColumnSearchProps('created_by_name'),
         ellipsis: {
            showTitle: false,
         },
         render: created_by_name => (
            <Tooltip placement="topLeft" title={created_by_name}>
               <span style={{ cursor: "default" }}>{created_by_name}</span>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Date Created',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '12%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {payload.created_by.toString() === user_id.toString() &&
                     <Tooltip placement="top" title='Edit'><AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessment(payload.id, payload.question_type)} /></Tooltip>
                  }
                  <Tooltip placement="top" title='Assign'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignAssessment(payload.id, payload.title, payload.term, payload.question_type)} /></Tooltip>
                  {payload.created_by.toString() === user_id.toString() &&
                     // <Tooltip placement="top" title={payload.shared === 0 ? 'Share' : 'Unshare'}>
                     //    <Popconfirm
                     //       title={payload.shared === 0 ? "Are you sure you want to share this assessment?" : "Are you sure you want to unshare this assessment?"}
                     //       onConfirm={() => payload.shared === 0 ? handleShareAssessment(payload.id) : handleUnshareAssessment(payload.id)}
                     //       okText="Yes"
                     //       cancelText="No"
                     //    >
                     //       <AntDIcons.ShareAltOutlined style={{ cursor: 'pointer', color: payload.shared === 0 ? '' : '#4abec7' }} />
                     //    </Popconfirm>
                     // </Tooltip>
                     <Tooltip placement="top" title={"Share/Unshare this with other teachers"}>
                        <AntDIcons.ShareAltOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowSharing(payload.id, payload.shared_to)} />
                     </Tooltip>
                  }
                  <Tooltip placement="top" title='Duplicate'>
                     <Popconfirm
                        title="Create a copy of this assessment?"
                        onConfirm={() => handleCopyAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.CopyOutlined color={'#4abec7'} style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  {payload.created_by.toString() === user_id.toString() &&
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this assessment?"
                           onConfirm={() => handleDeleteAssessment(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  }
                  <Tooltip placement="top" title='Preview'>
                     <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssessmentPreview(payload.id, payload.question_type, payload.origin)} />
                  </Tooltip>
               </Space>
            </>
         },
         width: '14%'
      },
   ].filter(item => !item.hidden);

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '40%'
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term_name',
         key: 'term_name',
         width: '8%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         ...getColumnSearchProps('assigned_by_name'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by_name}>
               <Text>{object.assigned_by_name}</Text>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Assigned To',
         key: 'sections',
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.section_desc}>
            <>
               {
                  (payload.sections !== null && payload.sections !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.sections.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Date Assigned',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '13%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {
                  activeTab === '2' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.question_type)} /></Tooltip>
                     <Tooltip placement="top" title='View Tracker'><AntDIcons.ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, activeTab)} /></Tooltip>
                  </Space>
               }

               {
                  activeTab === '3' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.question_type)} /></Tooltip>
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this assigned assessment?"
                           onConfirm={() => handleDeleteAssignment(payload.id)}
                           // onCancel={cancel}
                           okText="Yes"
                           cancelText="No">

                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  </Space>
               }

               {
                  activeTab === '4' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.question_type)} /></Tooltip>
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this assigned assessment?"
                           onConfirm={() => handleDeleteAssignment(payload.id)}
                           // onCancel={cancel}
                           okText="Yes"
                           cancelText="No">

                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                     <Tooltip placement="top" title='View Tracker'><AntDIcons.ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, activeTab)} /></Tooltip>
                  </Space>
               }
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const recycle_bin_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,

         // ellipsis: {
         //    showTitle: true,
         // },
         // render: id => (
         //    <Radio value={id} defaultChecked={false} onClick={() => setSelectedAssessment(id)} />
         // ),
         // width:'7%'
      },
      {
         title: '',
         dataIndex: 'assessment_id',
         key: 'assessment_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'created_by',
         key: 'created_by',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '64%'
      },
      {
         title: 'Origin',
         key: 'origin',
         dataIndex: 'origin',
         sorter: (a, b) => { return a.origin.localeCompare(b.origin) },
         width: '13%'
      },
      {
         title: 'Date Deleted',
         key: 'date_deleted',
         dataIndex: 'date_deleted',
         sorter: (a, b) => { return a.date_deleted.localeCompare(b.date_deleted) },
         width: '13%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='View'>
                     <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignAssessmentView(payload.assessment_id, payload.question_type, payload.origin)} />
                  </Tooltip>
                  {/* {payload.created_by.toString() === user_id && */}
                  <Tooltip placement="top" title='Restore'>
                     <Popconfirm
                        title="Do you want to restore this assessment?"
                        onConfirm={() => payload.origin.toLowerCase() === 'master list' ? handleRestoreAssessment(payload.id) : handleRestoreAssignedAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.UndoOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  {/* } */}
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const handleCopyAssessment = (id) => {
      var assinfo = null;
      setListLoading(true);

      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;

            var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
            var utc = new Date().toJSON().slice(0, 19);

            assinfo = {
               Id: "ass_" + o2o_mode + "_" + uuid(),
               Title: data.title + " - (" + utc + ")",
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: user_id,
               Copied_from: data.id,
               Question_sheet: data.question_sheet,
               Level: data.level,
               Subject: data.subject
            };

            //-- Insert duplicated assessment
            const url = `${process.env.REACT_APP_API_ASSESSMENT}/add?at=summative`;
            axios.post(url, assinfo).then(response => {
               if (response.data) {
                  const data = response.data;
                  Modal.success({
                     title: 'Summative Assessment',
                     content: 'A copy of your assessment has been created.',
                     centered: true,
                     onOk: getMasterList(user_id),
                  });
               }
            }, (error) => {
               console.log(error.message);
               setListLoading(false);
               Modal.error({
                  title: 'Summative Assessment',
                  content: 'Assessment duplication failed.',
                  centered: true
               });
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Assessment duplication failed.',
            centered: true
         });
      });
   }

   const handleShareAssessment = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/share?id=${id}&at=summative`;
      axios.post(url).then((response) => {
         if (response.data) {
            const data = response.data;

            Modal.success({
               title: 'Summative Assessment',
               content: 'Your shared assessment can now be seen by other teachers.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Share assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleUnshareAssessment = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/unshare?id=${id}&at=summative`;
      axios.post(url).then((response) => {
         if (response.data) {
            const data = response.data;

            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assessment has been unshared.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Unshare assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleDeleteAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/delete?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Delete assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleDeleteAssignment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/deleteassigned?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assigned assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Delete assigned assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleRestoreAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/undelete?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Restore assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleRestoreAssignedAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/undeleteassigned?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assigned assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Resotre assigned assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleCreateAssessment = () => {
      setQuestionTypeModalVisible(false);

      if (questionType === 1) {
         navigate('/assessment/create-pdf', { state: { title: assTitle, term: assTerm, type: "summative" } });
      } else if (questionType === 2) {
         navigate('/assessment/create-manual', { state: { title: assTitle, term: assTerm, type: "summative" } });
      }
   };

   const handleEditAssessment = (id, q_type) => {

      if (q_type.toString() === '1') {
         navigate('/assessment/edit-pdf', { state: { assessmentID: id } });
      }
      else if (q_type.toString() === '2') {
         navigate('/assessment/edit-manual', { state: { assessmentID: id } });
      }
   };

   const handleAssignAssessmentView = (id, q_type, origin) => {
      if (q_type.toString() === '1') {
         navigate('/assessment/view-pdf', { state: { id, origin } });
      }
      else if (q_type.toString() === '2') {
         navigate('/assessment/view-manual', { state: { id, origin } });
      }
   }

   const handleAssignAssessment = (ass_id, title, term, mode) => {
      let ass_options_default = {
         id: '',
         assessment_id: ass_id,
         assigned_by: user_id,
         duration: '1',
         allowed_attempts: '1',
         passing_percentage: '50',
         allow_result_viewing: '0',
         enable_timer: '0',
         start_date: '',
         end_date: '',
         assigned_students: '',
         assigned_students_with_section: '',
         term: term,
         level: null,
         randomize_questions: '0',
         mode
      }

      setAssOptions(ass_options_default);
      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleEditAssessmentAssign = (ass_id, title, mode) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/assigninfo?id=${ass_id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               duration: data.duration.toString(),
               allowed_attempts: data.allowed_attempts.toString(),
               passing_percentage: data.passing_percentage.toString(),
               allow_result_viewing: data.allow_result_viewing.toString(),
               enable_timer: data.enable_timer.toString(),
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               assigned_students_with_section: data.assigned_students_with_section,
               term: data.term,
               level: data.level,
               randomize_questions: data.randomize_questions,
               mode
            };

            setAssOptions(ass_options);
         }
      }, (error) => {
         console.log(error.message);
      });

      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      let tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);

      // onDrawerClose()
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const handleSaveAssignment = (data) => {
      // message.info(JSON.stringify(data));
      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var assignmentData = data;

         assignmentData.id = "ass_assign_" + o2o_mode + "_" + uuid();

         // message.info(JSON.stringify(assignmentData));

         const url = `${process.env.REACT_APP_API_ASSESSMENT}/assign?at=summative`;
         axios.post(url, assignmentData).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description:
                     'Assessment was assigned successfully',
               });

               setAssignVisible(false);
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment failed',
            });
         });
      } else {//-- Edit assignment
         const url = `${process.env.REACT_APP_API_ASSESSMENT}/assignupdate?at=summative`;
         axios.post(url, data).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description:
                     'Assessment assignment was updated successfully',
               });

               setAssignVisible(false);
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment update failed',
            });
         });
      }
   }

   const handleOASaveAssignment = (data) => {
      setProcessing(true);
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var assignmentData = data;

      assignmentData.id = data.id === '' ? "oa_ass_assign_" + o2o_mode + "_" + uuid() : data.id;

      const url = `${process.env.REACT_APP_API_CPH}/upsertassignexam`;
      axios.post(url, assignmentData).then(response => {
         if (response.data) {
            setProcessing(false);
            // message.success('Assessment was created.');
            // Modal.success({
            //    content: 'Assessment was assigned.',
            //    centered: true,
            // });
            notification.success({
               message: `Online Application`,
               description:
                  'Assessment was assigned successfully',
            });

            setAssignVisible(false);

         }
      }, (error) => {
         setProcessing(false);
         console.log(error.message);
         notification.error({
            message: `Online Application`,
            description:
               'Assessment assignment failed',
         });
      });
   }

   const handleViewTracker = (assign_id, origin) => {
      // navigate('/assessmenttracker',{replace: true, state: {assign_id, origin}});
      navigate(`/assessmenttracker?assign_id=${assign_id}&origin=${origin}`);
   }

   const handleAssessmentPreview = (id, q_type, origin) => {
      // navigate('/assessmenttracker',{state: {assign_id, origin}});
      if (q_type.toString() === '1') {
         navigate('/assessment/preview-pdf', { state: { id, origin } });
      }
      else if (q_type.toString() === '2') {
         navigate('/assessment/preview-manual', { state: { id, origin } });
      }
   }

   const renderDrawer = () => {
      return <>
         <Drawer
            title={assTitle}
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            open={assignVisible}
            zIndex={1001}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>
               {
                  user_role.toLowerCase() !== 'registrar'
                     ?
                     <AssessmentAssignTool
                        mode={"drawer"}
                        options={assOptions}
                        parentCallback={handleSetOptions}
                        saveCallback={handleSaveAssignment}
                     />
                     :
                     <OAAssessmentAssignTool
                        mode={"drawer"}
                        options={assOptions}
                        parentCallback={handleSetOptions}
                        saveCallback={handleOASaveAssignment}
                     />
               }

            </SimpleBarReact>
         </Drawer>
      </>
   }

   const getMasterList = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/list?created_by=${id}&at=summative`;

      // if (user_role === 'Admin')
      //    url = `${process.env.REACT_APP_API_SUMMATIVE_ASSESSMENT}/listall`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            setListLoading(false);
            setMasterList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssignedList = (id, temporal) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/listassigned?created_by=${id}&t=${temporal}&at=summative`;

      // if (user_role === 'Admin')
      //    url = `${process.env.REACT_APP_API_SUMMATIVE_ASSESSMENT}/listallcurrent`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            // setCurrentList(data);
            setAssignedList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getRecycleBin = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/listrecyclebin?created_by=${id}&at=summative`;

      // if (user_role === 'Admin')
      //    url = `${process.env.REACT_APP_API_SUMMATIVE_ASSESSMENT}/listrecyclebin`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setDeletedList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getMasterList(user_id);
            setActiveTab("1");
            break;
         case "2":
            getAssignedList(user_id, 'current');
            setActiveTab("2");
            break;
         case "3":
            getAssignedList(user_id, 'upcoming');
            setActiveTab("3");
            break;
         case "4":
            getAssignedList(user_id, 'past');
            setActiveTab("4");
            break;
         case "5":
            getRecycleBin(user_id);
            setActiveTab("5");
            break;
         default:
            throw new Error("Unsupported key");
      }
   }

   const refreshList = (tab, id) => {
      switch (tab) {
         case "1":
            getMasterList(id);
            break;
         case "2":
            getAssignedList(id, 'current');
            break;
         case "3":
            getAssignedList(id, 'upcoming');
            break;
         case "4":
            getAssignedList(id, 'past');
            break;
         case "5":
            getRecycleBin(id);
            break;
         default:
            throw new Error("Unsupported");
      }
   }

   const handleUserChange = (id) => {
      setUser_Id(id);
      refreshList(activeTab, id);
   }

   const displayPaneTable = (columns, data) => {
      return (
         <>
            {listLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading, please wait..." />
               </div>
               :
               (data.length > 0 && !listLoading)
                  ?
                  <Table
                     loading={listLoading}
                     size='small'
                     rowKey={'uid'}
                     columns={columns}
                     dataSource={data}
                     pagination={{ position: ['topCenter'] }}
                     scroll={{
                        x: '1000px',
                        y: 'calc(100vh - 18.5rem)',
                     }}
                     footer={() => 'Total Records: ' + data.length}
                  />
                  :
                  <div className='center-empty-space'>
                     <Empty image="../images/brainee_mascot.png" description="No records found" />
                  </div>
            }
         </>
      )
   }

   const searchList = (str) => {
      var filerData = dataContainer.filter(p => p.title.toLowerCase().includes(str.toLowerCase()) ||
         p.subject_desc.toLowerCase().includes(str.toLowerCase()) ||
         p.created_by_name.toLowerCase().includes(str.toLowerCase()));

      switch (activeTab) {
         case "1":
            setMasterList(filerData);
            break;
         case "2":
            setCurrentList(filerData);
            break;
         case "3":
            setUpcomingList(filerData);
            break;
         case "4":
            setPastList(filerData);
            break;
         case "5":
            setDeletedList(filerData);
            break;
         default:
            throw new Error("Unsupported key");
      }
   }

   const share_to_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },

      {
         title: 'First Name',
         key: 'first_Name',
         ...getColumnSearchProps('first_Name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.first_Name}>
               <Text>
                  {
                     payload.first_Name
                  }
               </Text>
            </Tooltip>
         ),
         width: '50%'
      },
      {
         title: 'Last Name',
         key: 'last_Name',
         ...getColumnSearchProps('last_Name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.last_Name}>
               <Text>
                  {
                     payload.last_Name
                  }
               </Text>
            </Tooltip>
         ),
         width: '50%'
      },
   ].filter(item => !item.hidden);

   const rowSelection = {
      selectedRowKeys: selectedTeachers,
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedTeachers(selectedRowKeys);
      },
   };

   const handleShowSharing = (assessment_id, shared_to) => {
      setAssessmentID(assessment_id);

      setSelectedTeachers(shared_to !== null ? shared_to.split(',').map(Number) : []);
      setShareToVisible(true);
   }

   const handleHideSharing = () => {
      setAssessmentID(null);
      setSelectedTeachers(null);
      setShareToVisible(false);
   }

   const handleShareAssessmentTo = () => {
      setSharing(true);

      var share_to = selectedTeachers.join(",");

      var url = `${process.env.REACT_APP_API_ASSESSMENT}/shareto?id=${assessmentID}&at=summative&st=${share_to}`;
      axios.post(url).then((response) => {
         if (response.data) {
            const data = response.data;

            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assessment can now be viewed by the teacher(s) you shared with.',
               centered: true,
               onOk: refreshList(activeTab, user_id)
            });

            setSharing(false);
            handleHideSharing();
         }
      }, (error) => {
         Modal.error({
            title: 'Summative Assessment',
            content: 'Share assessment failed.',
            centered: true
         });

         setSharing(false);
         handleHideSharing();
         console.log(error.message);
      });
   }

   return (
      <>
         {assOptions && renderDrawer()}
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <Spin size='large' spinning={processing} tip="Processing request...Please wait.">
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                        {
                           user_role.toLowerCase() !== 'registrar'
                              ?
                              <>
                                 <Breadcrumb.Item>
                                    <Link to="/home">Home</Link>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                 <Breadcrumb.Item>Summative Assessment</Breadcrumb.Item>
                              </>
                              :
                              <>
                                 <Breadcrumb.Item>Examinations For Online Applicants</Breadcrumb.Item>
                              </>
                        }
                     </Breadcrumb>
                  </div>

                  {user_role.toLowerCase() === 'admin'
                     ?
                     <div className='search-columns-3'>
                        <Space direction='horizontal' size={'small'}>
                           <span>View as </span>
                           <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.includes(input)}
                              defaultValue={user_full_name} style={{ width: '250px' }} onChange={(value) => handleUserChange(value)} >
                              {
                                 userList.map((item) => {
                                    var fullname = (item.first_Name + " " + item.last_Name).trimEnd();
                                    return (
                                       <Option value={item.id}>{fullname}</Option>
                                    )
                                 })
                              }
                           </Select>
                        </Space>
                        <Space direction='horizontal' size={'small'}>
                           <Input addonBefore={<AntDIcons.SearchOutlined />} placeholder="Search Assessment..." onChange={(e) => searchList(e.target.value)} />
                        </Space>
                        <Tooltip placement="bottom" title='Create new summative assessment'>
                           <Button className='button-shadow' type="primary" size="large" shape='round' style={{ maxWidth: '25ch' }} onClick={() => setQuestionTypeModalVisible(true)}>
                              <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Summative</Text>
                           </Button>
                        </Tooltip>
                     </div>
                     :
                     <div className='search-columns-3'>
                        <Space direction='horizontal' size={'small'}><div></div></Space>
                        <Space direction='horizontal' size={'small'}>
                           <Input addonBefore={<AntDIcons.SearchOutlined />} placeholder="Search Assessment..." onChange={(e) => searchList(e.target.value)} />
                        </Space>

                        <Tooltip placement="bottom" title='Create new summative assessment'>
                           <Button className='button-shadow' type="primary" size="large" shape='round' style={{ maxWidth: '25ch' }} onClick={() => setQuestionTypeModalVisible(true)}>
                              <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Summative</Text>
                           </Button>
                        </Tooltip>
                     </div>
                  }

                  <div className="dashboard-item-inner3">
                     <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                        <TabPane
                           tab={(<Tooltip title="List of all created assessments">
                              <span>Assessment List</span>
                           </Tooltip>
                           )}
                           key="1"
                        >
                           {displayPaneTable(masterListColumns, masterList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Current assigned assessments">
                                 <span>Current</span>
                              </Tooltip>
                           )}
                           key="2"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Upcoming assigned assessments">
                                 <span>Upcoming</span>
                              </Tooltip>
                           )}
                           key="3"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Past assigned assessments">
                                 <span>Past</span>
                              </Tooltip>
                           )}
                           key="4"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="List of all deleted Assessments">
                                 <span><AntDIcons.RestOutlined /> Recycle Bin</span>
                              </Tooltip>
                           )}
                           key="5"
                        >
                           {displayPaneTable(recycle_bin_columns, deleteList)}
                        </TabPane>
                     </Tabs>
                  </div>
               </div>
            </Spin>
         </div>

         <Modal
            title="Select Questionaire Option"
            centered
            destroyOnClose={true}
            open={questionTypeModalVisible}
            onOk={() => handleCreateAssessment()}
            onCancel={() => setQuestionTypeModalVisible(false)}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
            width={"300px"}
         >
            <Segmented
               key={'uid'}
               value={questionType}
               block
               options={[
                  {
                     label: 'Upload',
                     value: 1,
                  },
                  {
                     label: 'Create',
                     value: 2,
                  },
               ]}
               onChange={(val) => setQuestionType(val)}
            />
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
               <Text strong>
                  {
                     questionType === 1
                        ?
                        'Upload a pdf format questionaire'
                        :
                        'Use Brainee assessment tools'
                  }
               </Text>
            </div>
         </Modal>

         <Modal
            title="Share Assessment"
            centered
            destroyOnClose={true}
            open={shareToVisible}
            onOk={() => handleShareAssessmentTo()}
            onCancel={() => handleHideSharing()}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
            width={"450px"}
            okText={"Save"}
         >
            <Spin spinning={sharing} tip={"Updating, please wait..."}>
               <Form
                  layout={"vertical"}
                  colon={false}
                  style={{ width: '100%' }}
               >
                  <Table
                     rowSelection={rowSelection}
                     size='small'
                     columns={share_to_columns}
                     dataSource={teacherList}
                     pagination={false}
                     scroll={{
                        y: 'calc(100vh - 27rem)',
                     }}
                  />
               </Form>
            </Spin>
         </Modal>
      </>
   );
}