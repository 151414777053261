import React, { useState, useEffect, createRef, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Breadcrumb, Row, Col, Carousel, Input } from 'antd';
// import SimpleBarReact from "simplebar-react";
import Countdown, { zeroPad } from 'react-countdown';
import axios from 'axios';
import { QuestionCircleOutlined, EyeOutlined } from '@ant-design/icons';
// import Split from 'react-split';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import platform, { parse } from 'platform';
import uuid from 'react-uuid';

import '../../../node_modules/simplebar/src/simplebar.css';

// import './Responsive.css';
// import './SplitPanel.css';
import './Assessment.css';
import './Answer.css';

// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { Button, Typography, Popconfirm, Modal, Pagination } from 'antd';
// import { auto } from '@popperjs/core';
// import * as AntDIcons from '@ant-design/icons';
// import {isMobile} from 'react-device-detect';
// import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const { Text, Paragraph } = Typography;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function AnswerManual(props) {
   const carouselRef = createRef();
   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const status = user_other_data.Status;

   const assInfo = location.state.assInfo;
   const assignInfo = location.state.assignInfo;
   const ansId = location.state.id;

   const orig_question_sheet = assInfo.Question_sheet;

   const [isSorted, setIsSorted] = useState(false);

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      // localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_my_sequence");
      const sequence_local = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_my_sequence"));
      const tmpAssInfo = orig_question_sheet;

      //-- If sequence is already created then sort by sequence else randomize questions
      if (sequence_local !== null) {
         for (var i = 0; i < sequence_local.length; i++) {
            tmpAssInfo[i].sequence_no = sequence_local[i];
         }

         // tmpAssInfo.sort((a, b) => {
         //    return a.sequence_no - b.sequence_no;
         // });
      } else {
         if (assignInfo.randomize_questions === 1) {
            var sequence_numbers = null;
            sequence_numbers = Utils.generateSequenceNumbers(tmpAssInfo.length);
            localStorage.setItem(user_id + "_" + assInfo.Id + "_my_sequence", JSON.stringify(sequence_numbers));

            //-- Add sequence number to every objects
            for (var ii = 0; ii < sequence_numbers.length; ii++) {
               tmpAssInfo[ii].sequence_no = sequence_numbers[ii];
            }

            //-- ToDo: Randomize question per section
         }
      }

      return tmpAssInfo;
   });
   // const [answerSheetData, setAnswerSheetData] = useState(assInfo.Question_sheet);
   const [answerData, setAnswerData] = useState(() => {
      var ansLocal = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_myanswers"));
      if (ansLocal !== null) {
         return ansLocal;
      } else {
         return initializeAnswerData(assInfo.Question_sheet)
      }
   });
   // const[answerData, setAnswerData] = useState(() => {
   //    return initializeAnswerData(assInfo.Question_sheet);  
   // });

   const [startTime, setStartTime] = useState(() => {
      var timeStarted = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_timestarted"));
      var val = null;

      if (timeStarted !== null) {
         val = timeStarted;
         return val;
      } else {
         val = Date.now();
         localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
         return Date.now();
      }
   });

   const [isSubmitted, setIsSubmitted] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(1);
   const [instruction, setInstruction] = useState(null);
   const [openInstruction, setOpenInstruction] = useState(false);
   const [startAnswering, setStartAnswering] = useState(false);

   const carouselSection = useRef(null);

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";

      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);      
      // localStorage.clear();

      answerSheetData.sort((a, b) => {
         return a.sequence_no - b.sequence_no;
      });

      answerData.sort((a, b) => {
         return a.sequence_no - b.sequence_no;
      });

      setIsSorted(true);

      if (assInfo.Instruction !== '' && assInfo.Instruction !== null) {
         setInstruction(assInfo.Instruction);
         setOpenInstruction(true);
      } else {
         setupTimer();
      }
   }, [assignInfo.id]);

   function initializeAnswerData(data) {
      let ansData = [];
      let tmpdata = {};
      var sequence_numbers = null;

      // //-- Randomize question if enabled
      if (assignInfo.randomize_questions === 1) {
         sequence_numbers = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_my_sequence"));
      }

      data.map(({ id, type, option_labels }, idx) => {
         if (data[idx].type.includes("section")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": data[idx].correct,
            }
         } else if (data[idx].type.includes("multiple")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               // "considered":"0",
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         } else if (data[idx].type === "short_answer") {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "considered": "0",
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         } else if (data[idx].type.includes("matching_type")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               "considered": "0",
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         } else {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         }

         ansData.push(tmpdata);
      });

      return ansData;
   }

   function setDefaultAnswers(type, options_length) {
      let retVal = [];
      var multiple = type.includes("multiple");

      if (multiple) {
         for (var i = 0; i < options_length; i++) {
            retVal.push("0");
         }
      }

      return retVal;
   }

   function handleAnswerRequest(data, idx) {
      var ad = [...answerSheetData];
      var sad = [...answerData];

      // var multiple = ad[idx].type.includes("multiple");
      // if (multiple) {
      //    ad[idx].answer = []; ad[idx].answer.push.apply(ad[idx].answer, data);
      //    sad[idx].answer = []; sad[idx].answer.push.apply(sad[idx].answer, data);
      // } else {
      //    ad[idx].answer = []; ad[idx].answer[0] = data;
      //    sad[idx].answer = []; sad[idx].answer[0] = data;
      // }

      var section = ad[idx].type.includes("section");

      if (section) {
         sad[idx].correct = [];
         sad[idx].correct.push.apply(sad[idx].correct, ad[idx].correct);
      }

      var multiple = ad[idx].type.includes("multiple");
      var matching = ad[idx].type.includes("matching");

      sad[idx].answer = [];
      ad[idx].answer = [];

      if (multiple || matching) {
         sad[idx].answer.push.apply(sad[idx].answer, data);
         ad[idx].answer.push.apply(ad[idx].answer, data);
      } else {
         sad[idx].answer.push(data);
         ad[idx].answer.push(data);
      }

      // ad[idx].answer = [];
      // ad[idx].answer.push.apply(ad[idx].answer, data);
      // sad[idx].answer = [];
      // sad[idx].answer.push.apply(sad[idx].answer, data);

      //-- Save answer to local storage after every answer's filled
      localStorage.removeItem(user_id + "_" + assInfo.Id + "_myanswers");
      localStorage.setItem(user_id + "_" + assInfo.Id + "_myanswers", JSON.stringify(sad));
      // var ansData = localStorage.getItem("myanswers");
      // console.log(JSON.parse(ansData));
      // localStorage.removeItem("myanswers");

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
      // message.info(JSON.stringify(answerData));

      setAnswerSheetData(ad);
      setAnswerData(sad);
   }

   const handleSubmit = () => {
      setIsSubmitted(true);

      try {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         let timestampz = new Date().getTime();

         var answers = answerData.length > 0 ? answerData : JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_myanswers"));

         //-- Set answer data sequence back to original
         if (answers[0].idx !== undefined) {
            answers.sort((a, b) => {
               return a.idx - b.idx;
            });
         }

         var ansInfo = {
            Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
            Assessment_id: assInfo.Id,
            Assignment_id: assignInfo.id,
            Student_id: user_id,
            User_agent: platform.ua,
            Browser: platform.name,
            Browser_version: platform.version,
            OS_platform: platform.os.family,
            Device: platform.product,
            // Answer: JSON.stringify(answerData), 
            Answer: JSON.stringify(answers),
            Submit_status: 1,
            Score: 0,
            Essay_score: 0,
            Expiration: null,
            Status: -1,
         }

         // var ansInfo = {
         //    Id: ansId,
         //    Assessment_id: assInfo.Id,
         //    Answer: JSON.stringify(answerData), 
         //    Submit_status: 1, 
         //    Score: 0,
         //    Essay_score: 0,
         //    Expiration: null,
         //    Status: -1,
         //    Student_id: user_id, 
         //    Assignment_id: assignInfo.id
         // }

         const updateAnsUrl = `${process.env.REACT_APP_API_ASSESSMENT}/updateanswer?at=summative&cache=${uuid()}`;
         axios.post(updateAnsUrl, ansInfo).then(response => {
            if (response.data) {
               const data = response.data;

               localStorage.removeItem(user_id + "_" + assInfo.Id + "_timestarted");
               localStorage.removeItem(user_id + "_" + assInfo.Id + "_myanswers");

               setIsSubmitted(false);

               Modal.success({
                  title: 'Summative Assessment',
                  content: 'Your assessment answers was successfully submitted.',
                  centered: true,
                  onOk: handleNavigate()
               });
            }
         }, (error) => {
            setIsSubmitted(false);

            Modal.error({
               title: 'Summative Assessment',
               content: 'Submission of assessment answers failed.',
               centered: true
            });
         });
      } catch (error) {
         console.log(error);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Submission of assessment answers failed.',
            centered: true
         });
      }
   }

   const handleNavigate = () => {
      if (assignInfo.Allow_result_viewing)
         navigate('/studentassessment/view-ans-manual', { state: { assessment_id: assInfo.Id, assignment_id: assignInfo.id, student_id: user_id } });
      else
         navigate('/studentassessment', { state: { activetab: "1" } });
   }

   const handleTimeOut = () => {
      Modal.info({
         title: 'Summative Assessment',
         content: 'Your time is up!',
         centered: true,
         onOk: () => handleSubmit(),
      });
   }

   const handleAfterChange = (current) => {
      setQuestionIndex(current + 1);
   }

   const contentStyle = {
      marginBottom: 0,
      // padding: 15,
      // height: isMobile ? 'calc(100vh - 140px)' : 'calc(100vh - 110px)',
      height: 'calc(100vh - 110px)',
      color: '#000',
      // background: '#9CDFF0',
      // textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'auto',
      // borderRadius: '7px'
   };

   const handleGoTo = (value) => {
      const re = /^[0-9]+$/;

      if (re.test(value) || value === "") {
         setQuestionIndex(value);
      }
   }

   // const scrollDown = () => {
   //    window.scrollTo({
   //      top: carouselSection.current.offsetTop,
   //      behavior: 'smooth',
   //    });
   // };

   // const handlePrev = () => {
   //    carouselRef.current.prev();
   //    scrollDown();
   // }

   const setupTimer = () => {
      if (startTime === null) {
         var val = Date.now();
         localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
         setStartTime(val);
      } else {
         if (assignInfo.enable_timer) {
            var timer = startTime + (assignInfo.duration * 60 * 1000);
            var now = Date.now();
            if (now > timer) {
               handleTimeOut();
            }
         }
      }

      setOpenInstruction(false);
      setStartAnswering(true);
   }

   const countdown_renderer = ({ hours, minutes, seconds }) => (
      <span>
         {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
   );

   return (
      <>
         {
            isSorted &&
            <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
               <div style={{ width: `100%` }}>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                           {
                              status !== '0'
                                 ?
                                 <>
                                    <Breadcrumb.Item>
                                       <Link to="/home">Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                       <Link to="/studentassessment">Summative Assessments</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Answer</Breadcrumb.Item>
                                 </>
                                 :
                                 <>
                                    <Breadcrumb.Item>Answer</Breadcrumb.Item>
                                 </>
                           }
                        </Breadcrumb>

                        <div style={{ width: '100%', textAlign: 'center' }}>
                           {/* <h5 style={{ textAlign: "center", marginBottom: '0px' }}>{assInfo.Title} {assInfo.instruction !== '' && assInfo.instruction !== null ? <Button className='button-shadow' type='primary' shape='round' onClick={() => setOpenInstruction(true)}><EyeOutlined /> Instruction</Button> : <></>}</h5> */}
                           {assInfo.instruction !== '' && assInfo.instruction !== null ? <Button className='button-shadow' type='primary' shape='round' onClick={() => setOpenInstruction(true)}><EyeOutlined /> Instruction</Button> : <></>}
                           &nbsp;&nbsp;&nbsp;
                           {
                              startAnswering === true
                                 ?
                                 assignInfo.enable_timer === 1
                                    ?
                                    <>
                                       Timer:&nbsp;
                                       <b>
                                          <Countdown
                                             autoStart={startAnswering}
                                             controlled={false}
                                             date={startTime + (assignInfo.duration * 60 * 1000)}
                                             onComplete={() => handleTimeOut()}
                                             renderer={countdown_renderer}
                                          >
                                             <b>Your time is up!</b>
                                          </Countdown>
                                       </b>
                                    </>
                                    :
                                    <b>No Time Limit</b>
                                 :
                                 <></>
                           }
                        </div>
                     </div>

                     <div className='answer-sheet-content2' style={{ height: 'calc(100vh - 125px)', overflowY: 'auto' }} ref={carouselSection}>
                        <Carousel
                           dotPosition='bottom'
                           ref={carouselRef}
                           effect='scrollx'
                           dots={false}
                           draggable={true}
                           style={{ height: 'calc(100vh - 140px)' }}
                           afterChange={handleAfterChange}
                           infinite={false}
                        >
                           {
                              answerSheetData.map(({ id, type, points, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                 if (type === "section") {
                                    qcardIdx = 1;
                                    idxCol.push(sectionIdx);
                                    sectionIdx++;
                                 } else {
                                    idxCol.push(qcardIdx);
                                    qcardIdx++;
                                 }

                                 return (
                                    <span style={contentStyle}>
                                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <BraineeQuestionCard
                                             key={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={answerData[idx].answer}
                                             option_labels={option_labels}
                                             question={question}
                                             image_url={image_url}
                                             question_type={'2'}
                                             mode={'answer'}
                                             answerCallback={handleAnswerRequest}
                                             template={template}
                                             question_image={question_image}
                                             question_audio={question_audio}
                                             question_video={question_video}
                                             subject_name={location.state.subject}
                                             assessment_type={'summative'}
                                          />
                                       </div>
                                    </span>
                                 );
                              })
                           }
                        </Carousel>
                     </div>

                     {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45px' }}> */}
                     <div style={{ position: 'sticky', bottom: '5px', width: '100%', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Row style={{ maxWidth: '100%', minWidth: '400px' }} gutter={12}>
                           <Col span={7}>
                              <Button className='button-shadow' type='primary' block shape='round' onClick={() => { carouselRef.current.prev(); }} disabled={questionIndex <= 1 || isSubmitted ? true : false}>
                                 Prev
                              </Button>
                           </Col>
                           <Col span={10}>
                              {
                                 questionIndex === answerSheetData.length
                                    ?
                                    <Popconfirm
                                       title="Click 'Yes', if you have already completed your answers, 'No', if you want to continue answering."
                                       onConfirm={() => handleSubmit()}
                                       // onCancel={cancel}
                                       okText="Yes"
                                       cancelText="No"
                                       icon={<QuestionCircleOutlined />}
                                    >
                                       <Button className='button-shadow' type='primary' block shape='round' disabled={isSubmitted}>
                                          <Text ellipsis style={{ color: "#fff" }}>Submit Answer</Text>
                                       </Button>
                                    </Popconfirm>
                                    :
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                       <div onBlur={() => carouselRef.current.goTo(questionIndex - 1)}>
                                          <Input
                                             size='default'
                                             style={{ width: '50px', textAlign: "center", borderRadius: "7px" }}
                                             value={questionIndex}
                                             onChange={(e) => handleGoTo(e.target.value)}
                                             onPressEnter={() => carouselRef.current.goTo(questionIndex - 1)}
                                          />
                                       </div>
                                       &nbsp;
                                       <Text
                                          ellipsis
                                          style={{ color: "#70D0D4", fontSize: '18px', cursor: 'pointer' }}
                                       >
                                          {' of ' + answerSheetData.length}
                                       </Text>
                                    </div>
                              }
                           </Col>
                           <Col span={7}>
                              <Button className='button-shadow' type='primary' block shape='round' onClick={() => { carouselRef.current.next(); }} disabled={questionIndex === answerSheetData.length ? true : false}>
                                 Next
                              </Button>
                           </Col>
                        </Row>
                     </div>
                  </div>
               </div>
            </div>
         }

         <Modal
            key="Modal-Instruction"
            title={<>Instruction</>}
            destroyOnClose={true}
            centered
            open={openInstruction}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ shape: 'round', type: 'primary', className: "button-shadow" }}
            onCancel={() => setupTimer()}
            onOk={() => setOpenInstruction(false)}
            okText="Ok"
            cancelText="Close"
            width={"55%"}
            style={{ minWidth: "350px", maxWidth: "95%" }}
         // footer={false}
         >
            {
               instruction !== null &&
               <div
                  dangerouslySetInnerHTML={{ __html: instruction }}
                  style={{ textOverflow: 'ellipsis', overflow: 'auto', backgroundColor: '#FFF', maxHeight: '300px', padding: '10px', borderRadius: '7px' }}
               />
            }
         </Modal>
      </>
   )
}
