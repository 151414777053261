/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ProgressBar from "@ramonak/react-progress-bar";
import { IntroSum } from '../components';

export function MTG1RALSummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [phonological, setPhonological] = useState(null);
   const [phonics, setPhonics] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [book, setBook] = useState(null);
   const [comprehending, setComprehending] = useState(null);
   const [creating, setCreating] = useState(null);
   const [total, setTotal] = useState(null);

   const [pcData, setPCData] = useState(null);
   // const [VN, setVN] = useState(null);

   var aid = props.assessment_id !== undefined ? props.assessment_id : props.assignment_id;

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(aid, props.section_id);
   }, [aid]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = "";

      if (props.version === 1)
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;
      else
         url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticreportdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var preData = data.filter(item => item.diagnostic_type === 'pre');
            var postData = data.filter(item => item.diagnostic_type === 'post');
            // setVN(preData.length);

            if (props.version === 1)
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            else
               url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v2/getdiagnosticsummarypercentdata?aid=${assignment_id}&sids=${section_id}&s=${props.subject_id}`;

            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  setPCData(data2[0]);
                  extractData(preData, postData);

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractData = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _phonological = {};
      var _phonics = {};
      var _vocabulary = {};
      var _book = {};
      var _comprehending = {};
      var _creating = {};

      var _total = {};
      var _proficiency = '';

      //-- Phonological (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.15) / 0.84) > 1)
            _competent++;
         else if (((_sumVal - 3.15) / 0.84) > -0.51)
            _basic++;
         else if (((_sumVal - 3.15) / 0.84) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.15) / 0.84) > 1)
                  _competent++;
               else if (((_sumVal - 3.15) / 0.84) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.15) / 0.84) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.72 ? 'Beginner' : (_postAve >= 2.73 && _postAve <= 3.57 ? 'Basic' : (_postAve >= 3.58 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.72 ? 'Beginner' : (_preAve >= 2.73 && _preAve <= 3.57 ? 'Basic' : (_preAve >= 3.58 && _preAve <= 4 ? 'Competent' : ''));
      }

      _phonological = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Phonological (end)

      //-- Phonics (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.86) / 1.06) > 1)
            _competent++;
         else if (((_sumVal - 2.86) / 1.06) > -0.51)
            _basic++;
         else if (((_sumVal - 2.86) / 1.06) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.86) / 1.06) > 1)
                  _competent++;
               else if (((_sumVal - 2.86) / 1.06) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.86) / 1.06) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.32 ? 'Beginner' : (_postAve >= 2.33 && _postAve <= 3.39 ? 'Basic' : (_postAve >= 3.40 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.32 ? 'Beginner' : (_preAve >= 2.33 && _preAve <= 3.39 ? 'Basic' : (_preAve >= 3.40 && _preAve <= 4 ? 'Competent' : ''));
      }

      _phonics = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Phonics (end)      

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.34) / 1.78) > 1)
            _competent++;
         else if (((_sumVal - 4.34) / 1.78) > -0.51)
            _basic++;
         else if (((_sumVal - 4.34) / 1.78) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.34) / 1.78) > 1)
                  _competent++;
               else if (((_sumVal - 4.34) / 1.78) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.34) / 1.78) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.44 ? 'Beginner' : (_postAve >= 3.45 && _postAve <= 5.23 ? 'Basic' : (_postAve >= 5.24 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.44 ? 'Beginner' : (_preAve >= 3.45 && _preAve <= 5.23 ? 'Basic' : (_preAve >= 5.24 && _preAve <= 6 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      //-- Book (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.46) / 1.09) > 1)
            _competent++;
         else if (((_sumVal - 1.46) / 1.09) > -0.51)
            _basic++;
         else if (((_sumVal - 1.46) / 1.09) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.46) / 1.09) > 1)
                  _competent++;
               else if (((_sumVal - 1.46) / 1.09) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.46) / 1.09) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.49 ? 'Beginner' : (_postAve >= 2.50 && _postAve <= 3.61 ? 'Basic' : (_postAve >= 3.62 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.49 ? 'Beginner' : (_preAve >= 2.50 && _preAve <= 3.61 ? 'Basic' : (_preAve >= 3.62 && _preAve <= 4 ? 'Competent' : ''));
      }

      _book = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Book (end)

      //-- Comprehending (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.46) / 1.09) > 1)
            _competent++;
         else if (((_sumVal - 1.46) / 1.09) > -0.51)
            _basic++;
         else if (((_sumVal - 1.46) / 1.09) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.46) / 1.09) > 1)
                  _competent++;
               else if (((_sumVal - 1.46) / 1.09) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.46) / 1.09) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.04 ? 'Beginner' : (_postAve >= 6.05 && _postAve <= 10.65 ? 'Basic' : (_postAve >= 10.66 && _postAve <= 14 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.04 ? 'Beginner' : (_preAve >= 6.05 && _preAve <= 10.65 ? 'Basic' : (_preAve >= 10.66 && _preAve <= 14 ? 'Competent' : ''));
      }

      _comprehending = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Comprehending (end)

      //-- Creating (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.46) / 1.09) > 1)
            _competent++;
         else if (((_sumVal - 1.46) / 1.09) > -0.51)
            _basic++;
         else if (((_sumVal - 1.46) / 1.09) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s33 + post_data[i].s34 + post_data[i].s35;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.46) / 1.09) > 1)
                  _competent++;
               else if (((_sumVal - 1.46) / 1.09) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.46) / 1.09) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.95 ? 'Beginner' : (_postAve >= 0.96 && _postAve <= 2.46 ? 'Basic' : (_postAve >= 2.47 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.95 ? 'Beginner' : (_preAve >= 0.96 && _preAve <= 2.46 ? 'Basic' : (_preAve >= 2.47 && _preAve <= 3 ? 'Competent' : ''));
      }

      _creating = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Creating (end)

      _total = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _phonological.prem + _phonics.prem + _vocabulary.prem + _book.prem + _comprehending.prem + _creating.prem,
         postm: _phonological.postm + _phonics.postm + _vocabulary.postm + _book.postm + _comprehending.postm + _creating.postm,
         min: _phonological.min + _phonics.min + _vocabulary.min + _book.min + _comprehending.min + _creating.min,
         max: _phonological.max + _phonics.max + _vocabulary.max + _book.max + _comprehending.max + _creating.max,
         sd: _phonological.sd + _phonics.sd + _vocabulary.sd + _book.sd + _comprehending.sd + _creating.sd,
         proficiency: _proficiency,
         beginner: _phonological.beginner + _phonics.beginner + _vocabulary.beginner + _book.beginner + _comprehending.beginner + _creating.beginner,
         basic: _phonological.basic + _phonics.basic + _vocabulary.basic + _book.basic + _comprehending.basic + _creating.basic,
         competent: _phonological.competent + _phonics.competent + _vocabulary.competent + _book.competent + _comprehending.competent + _creating.competent
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 19.01 ? 'Beginner' : (_total.postm >= 19.02 && _total.postm <= 29.94 ? 'Basic' : (_total.postm >= 29.95 && _total.postm <= 35 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 19.01 ? 'Beginner' : (_total.prem >= 19.02 && _total.prem <= 29.94 ? 'Basic' : (_total.prem >= 29.95 && _total.prem <= 35 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setTotal(_total);
      setPhonological(_phonological);
      setPhonics(_phonics);
      setVocabulary(_vocabulary);
      setBook(_book);
      setComprehending(_comprehending);
      setCreating(_creating);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ width: '20.56%', borderStyle: 'solid', borderWidth: '0.75pt', borderBottom: '1pt solid #cccccc', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.78%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ width: '11.4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.prem <= 0 || data.prem === null ? '' : (data.prem).toFixed(2)}</p>
            </td>
            <td style={{ width: '11.68%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm <= 0 || data.postm === null ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ width: '10.8%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.min}</p>
            </td>
            <td style={{ width: '11.16%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.max}</p>
            </td>
            <td style={{ width: '14.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.sd).toFixed(2)}</p>
            </td>
            <td style={{ width: '15.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <Row><Col span={24} style={{ textAlign: "left" }}><p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. {label !== "TOTAL" ? "Frequency of Proficiency Levels on " : ""} {label}</strong></p></Col></Row>
            <Row>
               <Col span={12}>
                  <table cellSpacing={0} cellPadding={0} style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner') === '100.00' ? '100' : (Utils.getPercentage(data, 'beginner') === '0.00' ? '0' : Utils.getPercentage(data, 'beginner'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic') === '100.00' ? '100' : (Utils.getPercentage(data, 'basic') === '0.00' ? '0' : Utils.getPercentage(data, 'basic'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent') === '100.00' ? '100' : (Utils.getPercentage(data, 'competent') === '0.00' ? '0' : Utils.getPercentage(data, 'competent'))}</p>
                        </td>
                     </tr>
                  </table>
               </Col>
               <Col span={12}>
                  <div style={{ marginLeft: "20px", marginRight: "20px", height: "200px" }}>
                     <Bar
                        options={{
                           responsive: true,
                           scales: {
                              y:
                              {
                                 min: 0,
                                 max: 100,
                                 stepSize: 1,
                              },
                              x:
                                 {},
                           }
                        }}

                        data={{
                           labels: ['Beginner', 'Basic', 'Competent'],
                           datasets: [
                              {
                                 label: label,
                                 backgroundColor: "#4F81BD",
                                 borderColor: "#4F81BD",
                                 data: [Utils.getPercentage(data, 'beginner'), Utils.getPercentage(data, 'basic'), Utils.getPercentage(data, 'competent')],
                              },
                           ],
                        }}
                     />
                  </div>
               </Col>
            </Row>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ width: '11.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  <strong>{index}</strong>
               </p>
            </td>
            <td style={{ width: '330.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  {label}
               </p>
            </td>
            <td style={{ width: '43.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {predata !== null ? predata.toFixed(2) + '%' : ''} */}
                  {/* {predata !== null ? (predata === 100 ? predata : (predata === 0 ? '0' : predata.toFixed(2))) : ''} */}
                  {
                     (predata !== null && predata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={predata !== 100 ? predata.toFixed(1) + "%" : predata + "%"}
                        completed={predata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '75.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {postdata !== null ? (postdata === 100 ? postdata : (postdata === 0 ? '0' : postdata.toFixed(2))) : ''} */}
                  {
                     (postdata !== null && postdata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={postdata !== 100 ? postdata.toFixed(1) + "%" : postdata + "%"}
                        completed={postdata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
         </tr>
      );
   }

   const generateContentStandards = (label, beg1, beg2, bas1, bas2, comp1, comp2) => {
      return (
         <tr style={{ height: '14.5pt' }}>
            <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.54%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg2}</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas2}</p>
            </td>
            <td style={{ width: '8.42%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '6.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp2}</p>
            </td>
         </tr>
      );
   }

   const renderLabel = (label) => {
      return (
         <>
            <tr>
               <td colSpan={4} style={{ width: '11.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                  <p style={{ margin: '0pt 5.65pt', textAlign: 'left', fontSize: '11pt' }}>
                     <strong>{label}</strong>
                  </p>
               </td>
            </tr>
         </>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ margin: '20px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#4F81BD"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '10pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}>
                              <strong>&nbsp;</strong>
                           </p>
                           <div style={{ pageBreakBefore: 'always' }}></div>
                           <p style={{ textAlign: 'center', fontSize: '14pt' }}><strong>Summary of Results for {props.subject}</strong></p>
                           <p style={{ textAlign: 'center' }}><strong>PRE TEST</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ textAlign: 'center', width: '25%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Area</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>N</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Pretest Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Post Test Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Min</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '11%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Max</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '14%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Standard Deviation</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '13%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong>
                                    </td>
                                 </tr>
                                 {generateMeanLevelRow('Phonological awareness', phonological, false)}
                                 {generateMeanLevelRow('Phonics', phonics, false)}
                                 {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                 {generateMeanLevelRow('Book and Print knowledge', book, false)}
                                 {generateMeanLevelRow('Comprehending and Analyzing', comprehending, false)}
                                 {generateMeanLevelRow('Creating and composing text', creating, false)}
                                 {generateMeanLevelRow('TOTAL', total, true)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Content Standards</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Beginner</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Basic</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Competent</span></strong></p>
                                    </td>
                                 </tr>
                                 {generateContentStandards('Phonological awareness', 0, 2.72, 2.73, 3.57, 3.58, 4)}
                                 {generateContentStandards('Phonics', 0, 2.32, 2.33, 3.39, 3.4, 4)}
                                 {generateContentStandards('Vocabulary', 0, 3.44, 3.45, 5.23, 5.24, 6)}
                                 {generateContentStandards('Book and Print knowledge', 0, 2.49, 2.5, 3.61, 3.62, 4)}
                                 {generateContentStandards('Comprehending and Analyzing', 0, 6.04, 6.05, 10.65, 10.66, 14)}
                                 {generateContentStandards('Creating and composing text', 0, 0.95, 0.96, 2.46, 2.47, 3)}
                                 {generateContentStandards('TOTAL', 0, 19.01, 19.02, 29.94, 29.95, 35)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           {generateFrequencyRow('Phonological awareness', phonological, 1)}
                           {generateFrequencyRow('Phonics', phonics, 2)}
                           {generateFrequencyRow('Vocabulary', vocabulary, 3)}
                           {generateFrequencyRow('Book and Print knowledge', book, 4)}
                           {generateFrequencyRow('Comprehending and Analyzing', comprehending, 5)}
                           {generateFrequencyRow('Creating and composing text', creating, 6)}
                           {generateFrequencyRow('TOTAL', total, 5)}
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>Percent of Correct</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td colSpan={2} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                    </td>
                                    <td style={{ width: '19.9%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Pre Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '20.6%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Post Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderLabel("Part 1: Language for interaction with others")}
                                 {generatePercentCorrectRow("Use common and socially acceptable expressions: Use simple and appropriate personal greetings.", pcData.pre_s1, pcData.post_s1, 1)}
                                 {generatePercentCorrectRow("Use common and socially acceptable expressions: Use familiar terms of address.", pcData.pre_s2, pcData.post_s2, 2)}
                                 {generatePercentCorrectRow("Use common and socially acceptable expressions: Greet and respond appropriately to greetings", pcData.pre_s3, pcData.post_s3, 3)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Make requests", pcData.pre_s4, pcData.post_s4, 4)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Give or offer information", pcData.pre_s5, pcData.post_s5, 5)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Communicate needs", pcData.pre_s6, pcData.post_s6, 6)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Clarify information", pcData.pre_s7, pcData.post_s7, 7)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Seek help", pcData.pre_s8, pcData.post_s8, 8)}
                                 {generatePercentCorrectRow("Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Take part in or take turns in conversation or discussion", pcData.pre_s9, pcData.post_s9, 9)}
                                 {renderLabel("Part 2: Language for developing and expressing ideas")}
                                 {generatePercentCorrectRow("Express ideas using a variety of symbols (e.g., drawings, emojis, scribbles): (a) oneself and family (b) content-specific topics", pcData.pre_s10, pcData.post_s10, 10)}
                                 {renderLabel("Part 3: Appreciating Language")}
                                 {generatePercentCorrectRow("Use words to represent ideas and events related to oneself and family: words that represent people, animals, objects, locations (naming words)", pcData.pre_s11, pcData.post_s11, 11)}
                                 {generatePercentCorrectRow("Use words to represent ideas and events related to oneself and family: words that represent activities and situations (action words)", pcData.pre_s12, pcData.post_s12, 12)}
                                 {generatePercentCorrectRow("Use words to represent ideas and events related to oneself and family: words that represent qualities or attributes (describing words)", pcData.pre_s13, pcData.post_s13, 13)}
                                 {generatePercentCorrectRow("Use language to express connections between ideas: Express compare and contrast", pcData.pre_s14, pcData.post_s14, 14)}
                                 {generatePercentCorrectRow("Use language to express connections between ideas: Express cause and effect", pcData.pre_s15, pcData.post_s15, 15)}
                                 {generatePercentCorrectRow("Use language to express connections between ideas: Use time words to relate ideas", pcData.pre_s16, pcData.post_s16, 16)}
                                 {generatePercentCorrectRow("Recognize the difference between statements, questions, commands and exclamations.", pcData.pre_s17, pcData.post_s17, 17)}
                                 {generatePercentCorrectRow("Respond to change of tones and cues through facial expressions, gestures and actions", pcData.pre_s18, pcData.post_s18, 18)}
                                 {renderLabel("Part 4: Interacting with text")}
                                 {generatePercentCorrectRow("Recognize icons and symbols in various texts found in familiar contexts (e.g., printed and digital texts, books, magazines, environmental print).", pcData.pre_s19, pcData.post_s19, 19)}
                                 {generatePercentCorrectRow("Engage with or respond to a short spoken texts: Identify a variety of purposes for viewing and listening to texts", pcData.pre_s20, pcData.post_s20, 20)}
                                 {generatePercentCorrectRow("Engage with or respond to a short spoken texts: Discuss what is interesting or entertaining in a text", pcData.pre_s21, pcData.post_s21, 21)}
                                 {generatePercentCorrectRow("Engage with or respond to a short spoken texts: Express personal preferences", pcData.pre_s22, pcData.post_s22, 22)}
                                 {generatePercentCorrectRow("Give reason/s for choosing books/texts for enjoyment and interest.", pcData.pre_s23, pcData.post_s23, 23)}
                                 {generatePercentCorrectRow("Record and report ideas and events using some learnt vocabulary: Note and describe main points", pcData.pre_s24, pcData.post_s24, 24)}
                                 {generatePercentCorrectRow("Record and report ideas and events using some learnt vocabulary: Sequence up to three (3) key events", pcData.pre_s25, pcData.post_s25, 25)}
                                 {generatePercentCorrectRow("Record and report ideas and events using some learnt vocabulary: Relate ideas or events to one’s experience", pcData.pre_s26, pcData.post_s26, 26)}
                                 {renderLabel("Part 5: Creating Text")}
                                 {generatePercentCorrectRow("Use own words in retelling", pcData.pre_s27, pcData.post_s27, 27)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", pcData.pre_s28, pcData.post_s28, 28)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", pcData.pre_s29, pcData.post_s29, 29)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", pcData.pre_s30, pcData.post_s30, 30)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Sequence up to three (3) key events", pcData.pre_s31, pcData.post_s31, 31)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Infer the character’s feelings and traits", pcData.pre_s32, pcData.post_s32, 32)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", pcData.pre_s33, pcData.post_s33, 33)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Predict possible endings", pcData.pre_s34, pcData.post_s34, 34)}
                                 {generatePercentCorrectRow("Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Relate ideas or events to one’s experience", pcData.pre_s35, pcData.post_s35, 35)}

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}